import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, IconButton, Modal, styled, Box, Stack, Typography, Slider, InputLabel } from '@mui/material';
import { UilMultiply } from '@iconscout/react-unicons';
import { Field, Form, Formik } from 'formik';
import { getNozzleDeliveries, getNozzleName } from './Helpers/NozzleLookupTable';
import { Button } from 'features';
import { NozzleColourInput } from '../NozzleColorInput/NozzleColorInput';
import { INozzleConfiguration, NozzleColour } from '../OrchardSprayForm/helpers/sprayCalculation';

interface NozzleSelectorModalProps {
    modalState: boolean;
    closeModal: () => void;
    nozzleConfig?: INozzleConfiguration,
    nozzleFormSubmit: (nozzle: INozzleConfiguration) => void;
}

interface NozzleFormValues {
    nozzleColour: NozzleColour;
    nozzleDelivery: number;
}

const ModalWrapper = styled(Box)(
    ({ theme }) => `
          display: flex;
          flex-direction: column;
          gap: 24px;
          background-color: ${theme.colors.alpha.white[100]};
          width: 514px;
          border-radius: 30px;
          padding: 40px;
          justify-content: center;
          align-items: center;
      `
  );

const CustomSlider = styled(Slider)(() => ({
    color: '#F3F3F3',
    overflow: 'visible',
    '& .MuiSlider-thumb': {
      backgroundColor: '#000000',
      boxShadow: 'none',
    },
    '$ .Mui-active': {
        boxShadow: 'none',
    },
    '& .MuiSlider-rail': {
      width: '100%',
    }
  }));

const CustomLabel = styled(InputLabel)(({ theme }) =>`
    color: ${theme.colors.alpha.grey[4]};`);


const NozzleSelectorModal = ({ modalState, closeModal, nozzleConfig, nozzleFormSubmit }: NozzleSelectorModalProps) => {
    const [sliderValue, setSliderValue] = useState(0);
    const [nozzleScale, setNozzleScale] = useState<number[]>([]);
    const [nozzleDelivery, setNozzleDelivery] = useState(0);
    const [nozzleColour, setNozzleColour] = useState<NozzleColour>(NozzleColour.White);

    const intialValues: NozzleFormValues = {
        nozzleColour: nozzleColour,
        nozzleDelivery: Math.min(...nozzleScale),
    }

    const submitForm = () => {
        if(!nozzleConfig) return;

        const nozzle: INozzleConfiguration = {
            ...nozzleConfig,
            nozzleName: getNozzleName(nozzleColour, sliderValue + 5),
            nozzleColour: NozzleColour[nozzleColour],
            nozzlePressure: sliderValue + 5,
            deliveryLitrePerMin: nozzleDelivery,
            nozzleSide: nozzleConfig.nozzleSide,
        }
        nozzleFormSubmit(nozzle);
        closeModal();
    };

    const calculateValue = (value: number) => {
        return nozzleScale[value];
    }

    const handleChange = (_: Event, value: number | number[]) => {
        if (typeof value === 'number') {
            setSliderValue(value);
            setNozzleDelivery(nozzleScale[value]);
        }
    }

    const handleNozzleChange = (colour: NozzleColour, nozzleDelivery?: number) => {
        const scale = getNozzleDeliveries(colour) ?? [];
        const slider = scale.indexOf(nozzleDelivery ?? Math.min(...scale));
        setNozzleScale([...scale]);
        setSliderValue(slider);
        setNozzleDelivery(scale[slider]);
        setNozzleColour(colour);
    }

    useEffect(() => {
        if(modalState) {
            const nozzleColour = nozzleConfig ? NozzleColour[nozzleConfig.nozzleColour as keyof typeof NozzleColour]: NozzleColour.White;
            handleNozzleChange(nozzleColour, nozzleConfig?.deliveryLitrePerMin);
        }
    }, [modalState])

    return (
        <Modal 
            open={modalState} 
            onClose={closeModal}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}>
            <ModalWrapper>
                <DialogTitle 
                    variant="h6Bold"
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        p: 0,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        Nozzle Selector
                        <IconButton
                            sx={{ alignSelf: 'flex-end' }}
                            aria-label="close"
                            onClick={closeModal}
                        >
                            <UilMultiply />
                        </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        m: 0,
                        p: '0.5px',
                    }}>
                        <Formik 
                            initialValues={intialValues} 
                            onSubmit={submitForm} >
                            <Form>
                                <Stack gap={2}>
                                    <CustomLabel>Select the nozzle you would like to use</CustomLabel>
                                    <Field name='nozzleColour' as={NozzleColourInput} handleNozzleChange={handleNozzleChange} />
                                    <CustomLabel>Choose the amount of L/min for the nozzle</CustomLabel>
                                    <Box
                                        sx={{
                                            paddingLeft: '10px',
                                            paddingRight: '25px',
                                        }}>
                                        <Field 
                                            name='nozzleDelivery'
                                            as={CustomSlider}
                                            value={sliderValue}
                                            min={0}
                                            step={1}
                                            max={20}
                                            onChange={handleChange}
                                            scale={calculateValue}/>
                                    </Box>
                                    <Typography>{nozzleDelivery}</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        endIcon='UilSave'
                                        sx={{
                                            width: '207px',
                                            height: '54px',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Form>
                        </Formik>
                </DialogContent>
            </ModalWrapper>
        </Modal>
    )

}

export default NozzleSelectorModal;