import { Stack, useTheme } from '@mui/material';
import { GridCellParams, GridRowParams } from '@mui/x-data-grid';
import { BasicAction, IAction, IHeader, MobileHeader, MobileSubheader, createHeaders } from 'features';

const actions = (deleteOnClick: (params: GridRowParams) => void) => {
	const actions: IAction[] = [
		BasicAction({
			onClick: deleteOnClick,
			title: 'Delete',
			icon: 'UilTrashAlt',
		}),
	];

	return actions;
};

const desktopHeaders = (
	renderProductDropDown: (params: GridCellParams) => JSX.Element,
	renderDosageInput: (params: GridCellParams) => JSX.Element,
	calculateLiterPerTank: (params: GridCellParams) => number,
	calculateLiterNeeded: (params: GridCellParams) => number
): Array<IHeader> => [
	{
		field: 'product',
		headerName: 'Product',
		icon: 'UilBox',
		flex: 1.4,
		renderCell: renderProductDropDown,
	},
	{
		field: 'dosage',
		headerName: 'Dosage',
		icon: 'UilRuler',
		renderCell: renderDosageInput,
	},
	{
		field: 'literPerTank',
		headerName: 'L per Tank',
		icon: 'UilWaterGlass',
		valueGetter: calculateLiterPerTank,
	},
	{
		field: 'litersNeeded',
		headerName: 'Liters needed',
		icon: 'UilRaindrops',
		valueGetter: calculateLiterNeeded,
	},
];

export const desktopHeaderConfig = (
	renderProductDropDown: (params: GridCellParams) => JSX.Element,
	renderDosageInput: (params: GridCellParams) => JSX.Element,
	deleteOnClick: (params: GridRowParams) => void,
	calculateLiterPerTank: (params: GridCellParams) => number,
	calculateLiterNeeded: (params: GridCellParams) => number
) =>
	createHeaders(
		desktopHeaders(
			renderProductDropDown,
			renderDosageInput,
			calculateLiterPerTank,
			calculateLiterNeeded
		),
		actions(deleteOnClick)
	);

	export const mobileHeaderConfig = (
		calculateLiterPerTank: (params: GridCellParams) => number,
		calculateLiterNeeded: (params: GridCellParams) => number,	
		deleteOnClick: (params: GridRowParams) => void
	) => {
		const mobileHeaders : IHeader[] = [
			{
				field: 'column1',
				headerName: 'Product',
				flex: 1.5,
				valueGetter: (params: GridCellParams) => params.row.productName
			},
			{
				field: 'column2',
				headerName: 'Dosage',
				valueGetter: (params: GridCellParams) => params.row.dosage
			},
			{
				field: 'column3',
				headerName: 'L per Tank',
				valueGetter: (params: GridCellParams) => calculateLiterPerTank(params)
			},
			{
				field: 'column4',
				headerName: 'Liters needed',
				valueGetter: (params: GridCellParams) => calculateLiterNeeded(params)
			}
		]

		return createHeaders(mobileHeaders, actions(deleteOnClick))
	}

	
