import React, { useRef, useState } from 'react';
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Avatar,
	FormControl,
	DialogActions,
	Slide,
	useTheme,
	Box,
	Tooltip,
	Grid,
	styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
	IconButton,
	RadioGroup,
	Icon,
	Button,
	CustomTextInput,
	ImagePopover,
	LoadingButton,
	QuestionFormWrapper,
} from 'features';
import { toast } from 'react-toastify';
import { useAddQuestionMutation } from 'src/services/rtkQuery/helpRequestApi';
import { ref, uploadBytes } from 'firebase/storage';
import { useAppSelector } from 'src/hooks/hooks';
import { selectUser } from 'src/store/user/userSlice';
import { storage } from 'src/services/firebase/firebase';

const validationSchema = Yup.object().shape({
	label: Yup.string().required('Please choose a question type'),
	image: Yup.string().optional(),
	subject: Yup.string().required('Subject is required'),
	message: Yup.string().required('Message is required'),
});

const questionTypes = [
	{ label: 'Technical Query', value: 'Technical' },
	{ label: 'Account Query', value: 'Account' },
	{ label: 'General Query', value: 'General' },
];

const DashedImageBox = styled(Box)(
	({ theme }) => `
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  gap: 12px;
	  padding: 12px;
	  border: 1px dashed ${theme.colors.alpha.grey[3]};
	  border-radius: 15px;
	  `
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface QuestionModalProps {
	modalState: boolean;
	closeModal: () => void;
	openModal: () => void;
	subject?: string;
}

interface FileObj {
	file: File;
	tempUrl: string;
}

export const QuestionModal = ({
	modalState,
	closeModal,
	subject,
}: QuestionModalProps) => {
	const theme = useTheme();
	const user = useAppSelector(selectUser);
	const [addQuestion, result] = useAddQuestionMutation();
	const [uploadedFiles, setUploadedFiles] = useState<FileObj[]>([]);
	const [fileLimit, setFileLimit] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const initialValue = {
		label: '',
		subject: subject ?? '',
		message: '',
	};

	const hiddenFileInput = useRef<HTMLInputElement>(null);

	const handleFileUploadClick = () => {
		hiddenFileInput.current?.click();
	};

	const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.prototype.slice.call(e.target.files);
		const uploaded = [...uploadedFiles];
		const maxCount = 3;
		let limitExceeded = false;

		files.some((file) => {
			// check that the file does not already exist:
			if (uploaded.findIndex((f) => f.file.name === file.name) === -1) {
				uploaded.push({
					file: file,
					tempUrl: URL.createObjectURL(file),
				});
				if (uploaded.length === maxCount) setFileLimit(true);
				if (uploaded.length > maxCount) {
					toast.warning(`You can only add a maximum of ${maxCount} images`);
					setFileLimit(false);
					limitExceeded = true;
					// return true to break out of the loop:
					return true;
				}
			} else {
				toast.warning('Cannot add duplicate file');
			}
		});
		if (!limitExceeded) {
			setUploadedFiles(uploaded);
		}
	};

	const clearSingleFile = (fileName: string) => {
		setUploadedFiles(
			[...uploadedFiles].filter((fileObj) => {
				if (fileObj.file.name === fileName) {
					URL.revokeObjectURL(fileObj.tempUrl);
				}
				return fileObj.file.name !== fileName;
			})
		);
		if (fileLimit) setFileLimit(false);
	};

	const clearAllFiles = () => {
		uploadedFiles.map((fileObj) => {
			URL.revokeObjectURL(fileObj.tempUrl);
		});
		setUploadedFiles([]);
		setFileLimit(false);
	};

	const submitQuestion = async (values: any): Promise<void> => {
		setIsSubmitting(true);
		try {
			const hasImages = uploadedFiles.length > 0;

			// upload question to 4U db:
			const response = await addQuestion({
				type: values.label,
				subject: values.subject,
				message: values.message,
				hasImages,
				files: hasImages ? uploadedFiles.map(fileObj => fileObj.file) : []
			}).unwrap();

			if (!hasImages) {
				toast.success('Help Request has been sent');
			} else {
				const uid = user.firebaseUser?.uid;
				const qid = response.data?.questionId;
				// upload question images to firebase storage:
				const promises = uploadedFiles.map(async (fileObj, index) => {
					const storageRef = ref(
						storage,
						`Users/${uid}/Questions/${qid}/QuestionPic${index + 1}.png`
					);
					await uploadBytes(storageRef, fileObj.file);
				});
				Promise.all(promises).then(() => toast.success('Help Request has been sent'));
			}
		} catch (error: any) {
			toast.error(error);
		} finally {
			setIsSubmitting(false);
			closeModal();
		}
	};

	return (
		<>
			<Dialog
				fullWidth
				maxWidth='md'
				open={modalState}
				TransitionComponent={Transition}
				keepMounted
				scroll='body'
				onClose={closeModal}>
				<Formik
					validateOnMount
					initialValues={initialValue}
					onSubmit={async (values, { setValues, resetForm }) => {
						await submitQuestion(values);
						setValues({ ...initialValue });
						clearAllFiles();
						resetForm();
					}}
					validationSchema={validationSchema}
					enableReinitialize>
					{({ errors, touched, setValues, resetForm }) => {
						const handleCloseModal = () => {
							closeModal();
							setValues({ ...initialValue });
							clearAllFiles();
							resetForm();
						};
						return (<Box sx={{ padding: 2 }}>
							<Form>
								<DialogActions
									sx={{
										padding: 0,
									}}
								>
									<IconButton
										aria-label='close'
										onClick={handleCloseModal}
										icon={'UilMultiply'}
									/>
								</DialogActions>
								<DialogTitle
									sx={{
										marginTop: 0,
										paddingTop: 0
									}}
									variant='h4Bold'
									align='center'>
									Ask a question
								</DialogTitle>
								<DialogContent>
									<Box marginTop={'15px'}>
										<Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
											<Grid item xs={12}>
												<DialogContentText
													variant='body'
													textAlign={'center'}
													sx={{ color: theme.colors.alpha.grey[4], pb:1 }}>
													Please complete this form to log any questions, feedback, or concerns you may have.
												</DialogContentText>
												<DialogContentText
													variant='body'
													textAlign={'center'}
													sx={{ color: theme.colors.alpha.grey[4] }}>
													The InteliGro team is here to assist you and ensure you have a great experience.
												</DialogContentText>
											</Grid>
											<Grid item xs={12}>
												<FormControl sx={{ marginTop: '10px' }} fullWidth error={!!errors.label && touched.label}>
													<Field
														fullWidth
														as={RadioGroup}
														name='label'
														formLabel='Question type'
														row
														options={questionTypes}
														helperText={
															errors.label && touched.label ? (
																<Box alignItems={'center'} display={'flex'} gap={1}>
																	<Icon icon={'UilExclamationCircle'} />
																	{errors.label}
																</Box>
															) : null
														}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<Grid container sx={{
													alignItems: 'center',
													padding: '12px',
													border: '1px dashed grey',
													borderRadius: '15px'
												}}>
													<Grid item xs={0}>
														<input
															type='file'
															style={{ display: 'none' }}
															ref={hiddenFileInput}
															accept='image/png, image/gif, image/jpeg'
															onChange={handleAddFiles}
															disabled={fileLimit}
															multiple
														/>
													</Grid>
													{
														uploadedFiles.length !== 0 ? (
															<>
																{
																	uploadedFiles.map((fileObj, index) => (
																		<Grid item xs={12} md={2} sx={{ paddingBottom: { xs: '15px', md: '0px' } }} key={index}>
																			<ImagePopover
																				maxWidth='120px'
																				maxHeight='120px'
																				borderRadius='15px'
																				fileName={fileObj.file.name}
																				url={fileObj.tempUrl}
																				handleClearFile={clearSingleFile}
																			/>
																		</Grid>
																	))
																}
																<Grid item xs={8} sm={2}>
																	<Button
																		variant='outlinedHover'
																		disabled={fileLimit}
																		size='small'
																		endIcon={'UilEditAlt'}
																		onClick={() => handleFileUploadClick()}>
																		Add Image
																	</Button>
																</Grid>
															</>
														) : (
																<>
																	<Grid item xs={4} sm={1}>
																		<Avatar
																			sx={{
																				width: '72px',
																				height: '72px',
																				backgroundColor: theme.colors.alpha.grey[1],
																				color: theme.palette.common.black,
																			}}>
																			<Icon icon={'UilCamera'} color='inherit' />
																		</Avatar>
																	</Grid>
																	<Grid item xs={8} sm={4}>
																		<Typography sx={{ alignItems: { xs: 'flex-start', sm: 'flex-end', md: 'flex-start' } , justifyContent: 'center' }} variant='body'>
																			Select an image
																		</Typography>
																	</Grid>
																	<Grid item xs={8} sm={6} sx={{  display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
																		<Button
																			size='small'
																			variant='outlinedHover'
																			color='secondary'
																			endIcon={'UilUploadAlt'}
																			onClick={() => handleFileUploadClick()}>
																			Upload Image
																		</Button>
																	</Grid>
																</>
														)
													}
													<Grid item xs={4} sm={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<Tooltip title='Upload up to three 15MB files'>
															<IconButton
																icon={'UilInfoCircle'}
																sx={{ color: theme.colors.alpha.grey[3] }}
															/>
														</Tooltip>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Field
													fullWidth
													name='subject'
													as={CustomTextInput}
													label='Subject'
													placeholder='Enter the subject of your question'
													text={subject}
													error={touched.subject && errors.subject}
													helperText={
														touched.subject && errors.subject ? (
															<Box alignItems={'center'} display={'flex'} gap={1}>
																<Icon icon={'UilExclamationCircle'} />
																{errors.subject}
															</Box>
														) : null
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<Field
													name='message'
													as={CustomTextInput}
													label='Message'
													multiline
													rows='4'
													placeholder='Enter your message'
													error={touched.message && errors.message}
													helperText={
														touched.message && errors.message ? (
															<Box alignItems={'center'} display={'flex'} gap={1}>
																<Icon icon={'UilExclamationCircle'} />
																{errors.message}
															</Box>
														) : null
													}
												/>
											</Grid>
										</Grid>
									</Box>
								</DialogContent>
								<DialogActions
									sx={{
										justifyContent: { xs: 'center', md: 'flex-end' },
									}}>
									<LoadingButton
										variant='contained'
										color='primary'
										type='submit'
										loading={isSubmitting}
										disabled={
											!!(touched.label && errors.label) ||
											!!(touched.message && errors.message) ||
											!!(touched.subject && errors.subject) ||
											isSubmitting
										}>
										Submit your question
									</LoadingButton>
								</DialogActions>
							</Form>
						</Box>);
					}}
				</Formik>
			</Dialog>
		</>
	);
};
