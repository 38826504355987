import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'src/helpers/authHelpers';
import { Entity } from 'src/services/rtkQuery/types/user.types';
import store from 'src/store/store';
import { IUserSlice } from 'src/store/user/userSlice.contracts';

export const updateTenant = createAsyncThunk(
	'user/updateTenant',
	async (
		params: { tenant: Entity | null | undefined },
		{ rejectWithValue }
	) => {
		try {
			const user: IUserSlice = await store.getState().user;

			return {
				...user,
				bcId: params.tenant?.code,
				tenant: params.tenant,
			} as IUserSlice;
		} catch (error: any) {
			toast.error(getErrorMessage(error.code));
			return rejectWithValue(error.message);
		}
	}
);
