import { createSlice } from '@reduxjs/toolkit';
import { ICart } from './cartSlice.contracts';
import { RootState } from '../store';
import { getCartStorage, removeCartStorage, setCartStorage } from './helpers/cartStorage';

const initialState: ICart = {
    products: []
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action) {
            const itemIndex = state.products.findIndex((item) => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                const itemToUpdate = state.products[itemIndex];
                itemToUpdate.quantity += action.payload.quantity;
                state.products[itemIndex] = {...itemToUpdate};
            } else {
                state.products.push({ ...action.payload });
            }
            setCartStorage(state);
        },
        updateItemQuantity(state, action) {
            const itemIndex = state.products.findIndex((item) => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                const itemToUpdate = state.products[itemIndex];
                itemToUpdate.quantity = action.payload.quantity;
                state.products[itemIndex] = {...itemToUpdate};
            }
            setCartStorage(state);
        },
        incrementQuantity(state, action) {
            const itemIndex = state.products.findIndex((item) => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                state.products[itemIndex].quantity++;
            }
            setCartStorage(state);
        },
        decrementQuantity(state, action) {
            const itemIndex = state.products.findIndex((item) => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                if (state.products[itemIndex].quantity === 1) {
                    state.products[itemIndex].quantity = 1
                } else {
                    state.products[itemIndex].quantity--;
                }
            }
            setCartStorage(state);
        },
        removeFromCart(state, action) {
            const removeItem = state.products.filter((item) => item.productId !== action.payload.productId);
            state.products = [...removeItem];
            setCartStorage(state);
        },
        clearCart(state) {
            state.products = [...initialState.products];
            removeCartStorage();
        },
        persistCart(state) {
            const cart = getCartStorage();
            if(cart) {
                const cartSlice: ICart = JSON.parse(cart)
                state.products = [...cartSlice.products];
            }
        }
    }
})

export default cartSlice.reducer;

export const { addToCart, clearCart, persistCart, removeFromCart, updateItemQuantity } = cartSlice.actions;

export const selectCart = (state: RootState): typeof initialState => state.cart;