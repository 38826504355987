import { fetchAndActivate, getAll } from 'firebase/remote-config';
import React, { createContext, useEffect, useState, ReactNode } from 'react';
import { remoteConfig } from 'src/services/firebase/firebase';

export type Flags = Record<string, string | number | boolean> | undefined;

interface FlagsProviderProps {
	defaults: typeof remoteConfig.defaultConfig;
	children: ReactNode;
}

const FlagsContext = createContext<Flags>({});

const FlagsProvider: React.FC<FlagsProviderProps> = ({
	defaults,
	children,
}) => {
	const [flags, setFlags] = useState<Flags>(defaults);

	remoteConfig.defaultConfig = defaults;
	useEffect(() => {
		remoteConfig.defaultConfig = defaults;
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				return getAll(remoteConfig);
			})
			.then((remoteFlags) => {
				const newFlags: Flags = { ...flags };

				for (const [key, config] of Object.entries(remoteFlags)) {
					newFlags[key] = config.asBoolean();
				}

				setFlags(newFlags);
			})
			.catch((error) => console.error(error));
	}, []);

	return (
		<FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
	);
};

export { FlagsContext, FlagsProvider };
