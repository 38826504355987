const pages = {
	myFarm: {
		root: 'my-farm',
		sprayCalcs: {
			name: 'spray-calculations',
			path: '/my-farm/spray-calculations',
		},
		sprayCalcDetails: {
			name: 'spray-calculations-details',
			path: '/my-farm/spray-calculations/details',
		},
		recommendations: {
			name: 'recommendations',
			path: '/my-farm/recommendations',
		},
		technicalData: {
			name: 'technical-data',
			path: '/my-farm/technical-data',
		},
		fieldAssessments: {
			name: 'field-assessments',
			path: '/my-farm/field-assessments',
		},
	},
	products: {
		root: 'products',
		cropProtection: {
			name: 'crop-protection',
			path: '/products/crop-protection',
		},
		plantNutrition: {
			name: 'plant-nutrition',
			path: '/products/plant-nutrition',
		},
		allProducts: {
			name: 'all-products',
			path: '/products/all-products',
		},
	},
	account: {
		root: 'account',
		overview: {
			name: 'account-overview',
			path: '/account/account-overview',
		},
		quoteRequests: {
			name: 'quote-requests',
			path: '/account/quote-requests',
		},
		purchaseOrderRequests: {
			name: 'purchase-order-requests',
			path: '/account/purchase-order-requests',
		},
		quotes: {
			name: 'quotes',
			path: '/account/quotes',
		},
		statements: {
			name: 'statements',
			path: '/account/statements',
		},
		deliveryNotes: {
			name: 'delivery-notes',
			path: '/account/delivery-notes',
		},
		invoices: {
			name: 'invoices',
			path: '/account/invoices',
		},
		creditNotes: {
			name: 'credit-notes',
			path: '/account/credit-notes',
		},
		purchaseOrders: {
			name: 'purchase-orders',
			path: '/account/purchase-orders',
		},
		helpRequests: {
			name: 'help-requests',
			path: '/account/help-requests',
		},
	},
	document: {
		name: 'view-document',
		path: '/document',
	},
	cart: {
		name: 'cart',
		path: '/cart',
	},
	myTeam: {
		name: 'my-team',
		path: '/my-team',
	},
	status: {
		root: 'status',
		systemStatus: {
			name: 'system-status',
			path: '/status/system',
		},
		statusComingSoon: {
			name: 'coming-soon',
			path: '/status/coming-soon',
		},
		statusMaintenance: {
			name: 'maintenance',
			path: '/status/maintenance',
		},
		status404: {
			name: '404',
			path: '/status/404',
		},
		status500: {
			name: '500',
			path: '/status/500',
		},
		unverified: {
			name: 'unverified',
			path: '/status/unverified',
		},
	},
};

export default pages;
