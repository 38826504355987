import { SprayCalculationProduct } from 'src/services/rtkQuery/types/sprayCalculation.types';

export interface IWeedSprayCalcData {
	id?: string;
	farmBlockId?: string;
	title?: string;
	tractorSpeed?: number;
	sprayPlumWidth?: number;
	speedMeterPerMin?: number;
	areaPerUnit?: number;
	nozzleAmount?: number;
	sprayVolume?: number;
	tankSize?: number;
	deliveryPerUnitRequired?: number;
	products?: SprayCalculationProduct[];
}

export const updateWeedSprayCalc = (
	data: Partial<IWeedSprayCalcData>
): IWeedSprayCalcData => {
	const speedPerMin = (data?.tractorSpeed ?? 0) * (1000 / 60);
	const areaSprayedPerMin = (data?.sprayPlumWidth ?? 0) * speedPerMin;
	const deliveryPerNozRequired =
		(data?.sprayVolume ?? 0) /
			(10000 / ((data?.sprayPlumWidth ?? 0) * speedPerMin)) /
			(data?.nozzleAmount ?? 0) ?? 0;

	const newData: IWeedSprayCalcData = {
		speedMeterPerMin: speedPerMin,
		areaPerUnit: areaSprayedPerMin,
		deliveryPerUnitRequired: deliveryPerNozRequired,
	};

	return newData;
};

export const weedFormValues: IWeedSprayCalcData = {
	title: '',
	tractorSpeed: 0,
	sprayPlumWidth: 0,
	speedMeterPerMin: 0,
	areaPerUnit: 0,
	nozzleAmount: 0,
	sprayVolume: 0,
	deliveryPerUnitRequired: 0,
};
