import { ICart } from '../cartSlice.contracts';

const removeCartStorage = () => {
    return localStorage.removeItem('cart');
}

const setCartStorage = (cart: ICart) => {
    removeCartStorage();
    localStorage.setItem('cart', JSON.stringify(cart));
}

const getCartStorage = () => {
    return localStorage.getItem('cart');
}

export { setCartStorage, getCartStorage, removeCartStorage }