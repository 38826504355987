import { IAction, IHeader, MobileLayout, createHeaders } from 'features';
import {
	BasicContainedAction,
	DeleteAction,
} from 'features/src/helpers/datagridActionHelper';
import { getSprayTypeString } from 'src/helpers/typeHelpers';

const actions = (
	deleteOnClick: (e: any) => void,
	onEditClick: (e: any) => void
) => {
	const actions: IAction[] = [
		DeleteAction({
			onClick: deleteOnClick,
			title: 'Delete',
		}),
		BasicContainedAction({
			onClick: onEditClick,
			title: 'Edit',
			icon: 'UilEditAlt',
		}),
	];

	return actions;
};

const headers: Array<IHeader> = [
	{
		field: 'title',
		headerName: 'Title',
		icon: 'UilBook',
		valueGetter: (params) => params.row.sprayCalculationValues.title,
	},
	{
		field: 'farmName',
		headerName: 'Farm',
		icon: 'UilWebGrid',
	},
	{
		field: 'farmBlockName',
		headerName: 'Block',
		icon: 'UilBorderAlt',
	},
	{
		field: 'type',
		headerName: 'Type',
		icon: 'UilNoEntry',
		valueGetter: (params) => getSprayTypeString(params.row.type),
		maxWidth: 200,
	},
	{
		field: 'creationTime',
		headerName: 'Date',
		icon: 'UilCalendarAlt',
		maxWidth: 200,
	},
];

export const headerConfig = (
	deleteOnClick: (e: any) => void,
	onEditClick: (e: any) => void
) => createHeaders(headers, actions(deleteOnClick, onEditClick));

export const mobileHeaderConfig = (
	deleteOnClick: (e: any) => void,
	onEditClick: (e: any) => void
) => {
	const mobileHeaders: Array<IHeader> = [
		{
			field: 'column1',
			flex: 1.3,
			renderCell: (params) => (
				<MobileLayout
					header={params.row.sprayCalculationValues.title}
					subheader={params.row.creationTime}
				/>
			)
		},
		{
			field: 'column2',
			renderCell: (params) => (
				<MobileLayout
					header={params.row.farmBlockName}
					subheader={getSprayTypeString(params.row.type)}
				/>
			)
		},
	];

	return createHeaders(mobileHeaders, actions(deleteOnClick, onEditClick));
};
