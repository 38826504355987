import { defineAbility } from '@casl/ability';
import { Access, UserRoles } from 'src/store/user/userSlice.contracts';

export default function defineAbilityFor(
	userType: UserRoles,
	access: Access[]
) {
	//DEFINE ABILITIES HERE BASED ON NEEDS
	return defineAbility((can, cannot) => {
		if (UserRoles[userType] === UserRoles[UserRoles.Admin]) {
			//user
			can('read', 'users');
			can('update', 'users');
			can('create', 'users');
			can('delete', 'users');
			cannot('read', 'statements');
			can('view', Access[Access.MyAccount]);
			can('view', Access[Access.MyFarm]);
			can('view', Access[Access.Products]);
		} else if (UserRoles[userType] === UserRoles[UserRoles.Member]) {
			access.forEach((x) => {
				const key = Access[x];
				can('view', key);
				can('read', key);
				can('update', key);
				can('create', key);
				can('delete', key);
			});
		} else {
			//PROBABLY GOOD JUST TO DENY ALL IF THERE IS NO USER TYPE
			cannot('read', 'users');
			cannot('update', 'users');
			cannot('create', 'users');
			cannot('delete', 'users');
			cannot('read', 'statements');
		}
	});
}

// -----------------------------------------------------------------------
//EXAMPLES (https://casl.js.org/v6/en/package/casl-react)

//YOU CAN WRAP JSX ELEMENTS WITH THIS AND IT WILL RENDER BASED ON THE ABILITIES AND THE CURRENT USER
//YOU CAN MOST PROBABLY ADD THE USER TYPE TO THE USER SLICE

// <Can I="create" a="user">
//     <>if the above is can, this will show<>
//     <>if the above is cannot, this will show<>
// </Can>

// -----------------------------------------------------------------------
// AS CODE

// import { AbilityContext, Can } from "../../context/canContext";
// const ability = useContext(AbilityContext)

// if(ability.can("delete", "user")){
//     console.log('run this code')
// }
