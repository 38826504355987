import { LibraryFolder } from 'features/src/organisms/Library';

export interface SearchQueryParams {
	skip?: number;
	take?: number;
	search?: string;
}

export interface LibraryDataParams extends SearchQueryParams {
	folder?: LibraryFolder;
	documentType?: LibraryDocumentType;
	tag?: string;
	cropId?: string;
}

export enum LibraryDocumentType {
	Certificate = 0,
	CropInformation = 1,
	CropManual = 2,
	EBook = 3,
	ProductLabel = 4,
	Brochure = 5,
	Article = 6,
	MRLInformation = 7,
	ProductSDS = 8,
	SprayProgramme = 9,
	TechnicalInformation = 10,
	Training = 11,
}

export const ReadableLibraryDocumentType: Record<LibraryDocumentType, string> =
	{
		[LibraryDocumentType.Certificate]: 'Certificate / Analysis',
		[LibraryDocumentType.CropInformation]: 'Crop Information',
		[LibraryDocumentType.CropManual]: 'Crop Manual',
		[LibraryDocumentType.EBook]: 'E-Book',
		[LibraryDocumentType.ProductLabel]: 'Product Label',
		[LibraryDocumentType.Brochure]: 'Brochure',
		[LibraryDocumentType.Article]: 'Article',
		[LibraryDocumentType.MRLInformation]: 'MRL Information',
		[LibraryDocumentType.ProductSDS]: 'Product SDS',
		[LibraryDocumentType.SprayProgramme]: 'Spray Programme',
		[LibraryDocumentType.TechnicalInformation]: 'Technical Information',
		[LibraryDocumentType.Training]: 'Training',
	};

export const stringToDocumentType = (value: any) => {
	return LibraryDocumentType[value as keyof typeof LibraryDocumentType];
};

export const stringToReadableDocumentType = (value: any) => {
	return ReadableLibraryDocumentType[LibraryDocumentType[value as keyof typeof LibraryDocumentType]];
};