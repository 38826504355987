import { FieldAssessment, FieldAssessmentParams } from 'features';
import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';

export const fieldAssessmentApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllFieldAssessments: builder.query<
			ApiResponse<FieldAssessment[]>,
			FieldAssessmentParams
		>({
			query: (args) => {
				return {
					url: 'FieldAssessment',
					params: args,
				};
			},
		}),

		getFieldAssessment: builder.query<ApiResponse<FieldAssessment>, string>({
			query: (id) => {
				return {
					url: `FieldAssessment/${id}`,
				};
			},
		}),
	}),

	overrideExisting: false,
});

export const { useGetAllFieldAssessmentsQuery, useLazyGetFieldAssessmentQuery } =
	fieldAssessmentApi;
