import { Chip, Grid, Stack, styled } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {
	ScreenLayout,
	StyledCard,
} from 'src/components/templates/ScreenLayout/ScreenLayout';
import { useEffect, useState } from 'react';
import NozzleSelectorModal from 'src/components/organisms/NozzleSelectorModal/NozzleSelectorModal';
import {
	SprayCalculationTypes,
	getSprayTypeString,
} from 'src/helpers/typeHelpers';
import { NozzleLegendModal } from 'src/components/organisms/NozzleLegendModal/NozzleLegendModal';
import {
	INozzleConfiguration,
	ISprayCalculation,
	NozzleSide,
	calculateParams,
	defaultNozzleConfig,
	replaceNozzle,
} from './helpers/sprayCalculation';
import { SprayCalculatorScreenActions } from 'src/components/Pages/MyFarm/SprayCalculationsDetail';
import { OrchardSpraySvg } from '../OrchardSpraySvg/OrchardSpraySvg';
import { Button, CustomFormError, CustomTextInput } from 'features';
import { SprayCalculationProducts } from '../SprayCalculationProducts';

const HeaderActionsButton = styled(Button)(
	({ theme }) => `
    border-color: ${theme.colors.secondary.main};
    color: ${theme.colors.secondary.main};
`
);

const CustomChip = styled(Chip)(
	({ theme }) => `
    font-size: 14px;
    background-color: ${theme.colors.secondary.main};
    margin: auto;
`
);

type OrchardSprayFormProps = {
	formSubmitting: boolean;
	blockSize: number;
};

const OrchardSprayForm = ({
	formSubmitting,
	blockSize,
}: OrchardSprayFormProps) => {
	const { values, touched, errors, setValues } =
		useFormikContext<ISprayCalculation>();
	const [nozzleSelectorModalState, setNozzleSelectorModalState] =
		useState(false);
	const [selectedNozzle, setSelectedNozzle] = useState<INozzleConfiguration>();
	const [nozzleLegendModalState, setNozzleLegendModalState] = useState(false);

	useEffect(() => {
		const calculatedValues = calculateParams(values);
		setValues({ ...values, ...calculatedValues });
	}, [
		values.rowSpacing,
		values.plantHeight,
		values.plantDepth,
		values.sprayVolume,
		values.tractorSpeed,
		values.nozzleAmount,
		values.leftSideNozzleConfiguration,
		values.rightSideNozzleConfiguration,
	]);

	useEffect(() => {
		let currentArrLeft = values.leftSideNozzleConfiguration ?? [];
		let currentArrRight = values.rightSideNozzleConfiguration ?? [];

		const value = values.nozzleAmount ? Math.round(values.nozzleAmount) : 0;

		const nozzleAmount = currentArrLeft.length + currentArrRight.length;
		if (value < nozzleAmount) {
			const nozzlesToRemove = Math.floor((nozzleAmount - value) / 2);
			currentArrLeft.splice(0, nozzlesToRemove);
			currentArrLeft = currentArrLeft.map((n) => {
				return { ...n, nozzlePort: n.nozzlePort - nozzlesToRemove };
			});
			currentArrRight.splice(
				currentArrRight.length - nozzlesToRemove,
				nozzlesToRemove
			);
			currentArrRight = currentArrRight.map((n) => {
				return { ...n, nozzlePort: n.nozzlePort - nozzlesToRemove };
			});
		} else {
			const nozzlesToAdd = Math.floor((value - nozzleAmount) / 2);
			const newNozzle = defaultNozzleConfig[0];
			currentArrLeft = currentArrLeft.map((n) => {
				return { ...n, nozzlePort: n.nozzlePort + nozzlesToAdd };
			});
			currentArrRight = currentArrRight.map((n) => {
				return { ...n, nozzlePort: n.nozzlePort + nozzlesToAdd };
			});
			for (let i = 0; i < nozzlesToAdd; i++) {
				currentArrLeft.splice(i, 0, {
					deliveryLitrePerMin: newNozzle?.deliveryLitrePerMin ?? 0,
					nozzleColour: newNozzle.nozzleColour,
					nozzlePressure: newNozzle.nozzlePressure ?? 0,
					nozzlePort: i + 1,
					nozzleName: newNozzle.nozzleName,
					nozzleSide: NozzleSide.Left,
				});
				currentArrRight.splice(currentArrRight.length - i, 0, {
					deliveryLitrePerMin: newNozzle?.deliveryLitrePerMin,
					nozzleColour: newNozzle?.nozzleColour,
					nozzlePressure: newNozzle?.nozzlePressure,
					nozzlePort: i + 1,
					nozzleName: newNozzle?.nozzleName,
					nozzleSide: NozzleSide.Right,
				});
			}
		}

		setValues({
			...values,
			rightSideNozzleConfiguration: currentArrRight,
			leftSideNozzleConfiguration: currentArrLeft,
		});
	}, [values.nozzleAmount]);

	const handleNozzleClick = (nozzle: INozzleConfiguration) => {
		setSelectedNozzle(nozzle);
		setNozzleSelectorModalState(true);
	};

	const updateNozzle = (value: INozzleConfiguration) => {
		let currentArrLeft = values.leftSideNozzleConfiguration ?? [];
		let currentArrRight = values.rightSideNozzleConfiguration ?? [];

		if (value.nozzleSide === NozzleSide.Left) {
			currentArrLeft = replaceNozzle(value, currentArrLeft);
		} else {
			currentArrRight = replaceNozzle(value, currentArrRight);
		}

		setValues({
			...values,
			rightSideNozzleConfiguration: currentArrRight,
			leftSideNozzleConfiguration: currentArrLeft,
		});
	};

	return (
		<>
			<ScreenLayout
				title={`Spray Calculations${values.title ? ` - ${values.title}` : ''}`}
				icon="UilRaindrops"
				screenActionsEnd={
					<SprayCalculatorScreenActions loading={formSubmitting} />
				}
				titleChip={
					<CustomChip
						label={getSprayTypeString(SprayCalculationTypes.ORCHARD)}
						size="small"
					/>
				}
			>
				<Stack gap={2}>
					<StyledCard
						title="Orchard/Block Details"
						icon="UilBorderAlt"
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }} container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12}>
								<Field
									name="title"
									as={CustomTextInput}
									label="Title"
									placeholder="Enter a title for the spray"
									value={values.title}
								/>
								{touched.title && errors.title ? (
									<CustomFormError errorMessage={errors.title} />
								) : null}
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="rowSpacing"
									type="number"
									as={CustomTextInput}
									label="Row Spacing (m)"
									placeholder="Enter the row spacing"
									value={values.rowSpacing}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="plantHeight"
									type="number"
									as={CustomTextInput}
									label="Plant Height (m)"
									placeholder="Enter the plant height"
									value={values.plantHeight}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="plantDepth"
									type="number"
									as={CustomTextInput}
									label="Plant Depth (m)"
									placeholder="Enter the plant depth"
									value={values.plantDepth}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="treeRowVolume"
									as={CustomTextInput}
									label="Tree Row Volume (L/ha)"
									placeholder="Enter the tree row volume"
									value={values.treeRowVolume?.toFixed(2)}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</StyledCard>

					<StyledCard
						title="Spray Details"
						icon="UilBorderOut"
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }} container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="sprayVolume"
									type="number"
									as={CustomTextInput}
									label="Desired Spray Volume (L/ha)"
									placeholder="Enter the desired spray volume"
									value={values.sprayVolume}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tankSize"
									type="number"
									as={CustomTextInput}
									label="Tank Size (L)"
									placeholder="Enter the tank size"
									value={values.tankSize}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<CustomTextInput
									label="Hectare per Tank"
									disabled={true}
									value={(values.tankSize ?? 0) / (values.sprayVolume ?? 0)}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="gearSelection"
									as={CustomTextInput}
									label="Gear Selection"
									placeholder="Enter the gear selection"
									value={values.gearSelection}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tractorSpeed"
									type="number"
									as={CustomTextInput}
									label="Tractor Speed (km/h)"
									placeholder="Enter the tractor speed (km/H)"
									value={values.tractorSpeed}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="totalDelivery"
									type="number"
									as={CustomTextInput}
									label="Spray Pressure (Bar) 8 to 12 Bar"
									value={values.totalDelivery?.toFixed(2)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="nozzleAmount"
									type="number"
									step="1"
									as={CustomTextInput}
									label="Number of Nozzles"
									placeholder="Enter the number of nozzles"
									value={values.nozzleAmount}
								/>
								{touched.nozzleAmount && errors.nozzleAmount ? (
									<CustomFormError errorMessage={errors.nozzleAmount} />
								) : null}
							</Grid>
						</Grid>
					</StyledCard>

					<SprayCalculationProducts blockSize={blockSize} />

					<StyledCard
						title="Delivery"
						icon="UilRaindrops"
						cardActions={
							<HeaderActionsButton
								sx={{
									width: '187px',
									height: '37px',
								}}
								variant="outlined"
								size="small"
								onClick={() => setNozzleLegendModalState(true)}
							>
								View Nozzle Legend
							</HeaderActionsButton>
						}
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }} container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
						<Grid item xs={12} md={6}>
								<Field
									name="leftDelivery"
									component={CustomTextInput}
									label="Delivery Left (L/min)"
									value={values.leftDelivery?.toFixed(3)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									name="rightDelivery"
									component={CustomTextInput}
									label="Delivery Right (L/min)"
									value={values.rightDelivery?.toFixed(3)}
									disabled={true}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sx={{ display: 'flex', justifyContent: 'center' }}
							>
								<OrchardSpraySvg
									nozzleClick={handleNozzleClick}
									nozzlesLeft={values.leftSideNozzleConfiguration ?? []}
									nozzlesRight={values.rightSideNozzleConfiguration ?? []}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<Field
									name="totalDelivery"
									component={CustomTextInput}
									label="Total Delivery (L/ha)"
									value={values.totalDelivery?.toFixed(3)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									name="deliveryPerUnit"
									component={CustomTextInput}
									label="Delivery per ha"
									value={values.deliveryPerUnit?.toFixed(3)}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</StyledCard>
				</Stack>
			</ScreenLayout>
			<NozzleSelectorModal
				modalState={nozzleSelectorModalState}
				closeModal={() => setNozzleSelectorModalState(false)}
				nozzleConfig={selectedNozzle}
				nozzleFormSubmit={(nozzle) => {
					updateNozzle(nozzle);
				}}
			/>
			<NozzleLegendModal
				modalState={nozzleLegendModalState}
				closeModal={() => setNozzleLegendModalState(false)}
			/>
		</>
	);
};

export default OrchardSprayForm;
