
import { InfoOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material'

export interface VersionNumberProps {
    version: string
    color?: string
    onClick?: () => void
}

export function VersionNumber({ version, color, onClick }: VersionNumberProps) {

    version = version === "" ? "000" : version;

    return (
        <Stack direction={'row'} alignItems={'center'} gap={0.5} my={1}>
            <InfoOutlined 
                onClick={onClick} 
                fontSize='small'
                sx={{
                    ...color && {color: color}
                }}
            />
            <Typography 
                fontSize={8}
                sx={{
                    ...color && {color: color}
                }}
            >
                    V {version}
            </Typography>
        </Stack>
    )
}
