import React, { useContext } from 'react';
import {
	Drawer as MuiDrawer,
	List,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	useTheme,
	ListItemButton,
	Collapse,
	Theme,
	CSSObject,
	styled,
	Stack,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { DrawerWrapper, ListDropdownBar } from './Sidebar.styles';
import { AgentInfo, Icon, IconButton, VersionNumber } from 'features';
import { NavLink, useNavigate } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { FlagsContext } from 'src/contexts/FlagsContext';
import { UilAngleRight } from '@iconscout/react-unicons';
import store from 'src/store/store';
import { useAppSelector } from 'src/hooks/hooks';
import { selectUser } from 'src/store/user/userSlice';
import PoweredByIG from '../../../assets/img/powered-by-ig.png';
import Logo from '../../../assets/img/logo.png';
import LogoVertical from '../../../assets/img/logo-vertical.png';
import TenantSelect from 'src/components/organisms/TenantSelect/TenantSelect';
import { AbilityContext } from 'src/contexts/CanContext';

const openedMixin = (theme: Theme): CSSObject => ({
	width: theme.sidebar.width,
	backgroundColor: theme.colors.primary.main,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundColor: theme.colors.primary.main,
	overflowX: 'hidden',
	width: `${theme.spacing(7)}`,
	[theme.breakpoints.up('sm')]: {
		width: `${theme.spacing(8)}`,
	},
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
	width: theme.sidebar.width,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export const Sidebar = () => {
	const theme = useTheme();

	const isMobileScreen = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	const sidebarContext = useContext(SidebarContext);
	const { open: drawerOpen, toggleSidebar } = sidebarContext;

	const { agent } = store.getState().user;
	const userSlice = useAppSelector(selectUser);

	const featureFlags = useContext(FlagsContext);

	const navigate = useNavigate();

	const ability = useContext(AbilityContext);

	const version = process.env.REACT_APP_RELEASE_NAME?.slice(8) ?? '000';

	return (
		<div style={{ display: 'flex' }}>
			<Drawer
				variant={isMobileScreen ? 'temporary' : 'permanent'}
				open={drawerOpen}
			>
				<DrawerWrapper
					sx={{
						padding: drawerOpen ? '30px 25px' : 0,
					}}
				>
					<List
						component={'nav'}
						disablePadding
						style={{ width: '100%' }}
						subheader={
							<DrawerHeader
								drawerOpen={drawerOpen}
								toggleSidebar={sidebarContext.toggleSidebar}
							/>
						}
					>
						{!!userSlice.entities.length && drawerOpen && <TenantSelect />}
						<Stack sx={{ alignItems: drawerOpen ? '' : 'center' }}>
							{sidebarContext.titles &&
								sidebarContext.titles.map(
									(item, index) =>
										ability.can(item.casl?.action, item.casl?.access) && (
											<React.Fragment key={`title-${index}`}>
												{!(
													item.title === 'My Farm' &&
													!!featureFlags?.myFarmDisabled
												) && (
													<>
														{drawerOpen ? (
															<Box key={index} mt={'20px'}>
																<ListItemButton
																	disableTouchRipple
																	onClick={() => {
																		if (item.children) {
																			sidebarContext.openTitleCollapse(
																				item.title
																			);
																		} else {
																			if (isMobileScreen) toggleSidebar();
																			navigate(item.path);
																		}
																	}}
																	sx={{
																		padding: '0px 6px',
																		gap: '8px',
																		color: theme.palette.common.white,
																		'&:hover': {
																			backgroundColor: 'transparent',
																		},
																	}}
																>
																	<ListItemIcon>
																		<Icon icon={item.icon} />
																	</ListItemIcon>
																	<ListItemText
																		primaryTypographyProps={{
																			variant: 'body',
																			color: theme.colors.alpha.white[100],
																		}}
																		primary={item.title}
																	/>
																	{item.children && (
																		<Icon
																			icon={
																				item.open
																					? 'UilAngleUp'
																					: 'UilAngleDown'
																			}
																			sx={{ color: theme.palette.common.white }}
																		/>
																	)}
																</ListItemButton>
																{item.children && (
																	<Collapse in={item.open} timeout="auto">
																		<List
																			component="div"
																			disablePadding
																			sx={{
																				color: theme.palette.common.white,
																				pt: '21px',
																			}}
																		>
																			{item.children?.map((child, index) => (
																				<React.Fragment
																					key={`menu-item-${index}`}
																				>
																					{!(
																						(child.title === 'Quote Requests' &&
																							!!featureFlags?.quoteRequestDisabled) ||
																						(child.title ===
																							'Recommendations & Quotes' &&
																							!!featureFlags?.recommendationsDisabled) ||
																						(child.title === 'Technical Data' &&
																							!!featureFlags?.technicalDataDisabled) ||
																						(child.title === 'Assessments' &&
																							!!featureFlags?.assessmentsDisabled)
																					) && (
																						<Box
																							key={index}
																							display="flex"
																							flexDirection={'row'}
																							pl={2}
																						>
																							<ListDropdownBar
																								flexItem
																								orientation="vertical"
																							/>
																							<NavLink
																								to={child.path}
																								onClick={() => {
																									if (isMobileScreen)
																										toggleSidebar();
																								}}
																								style={({ isActive }) => ({
																									background: isActive
																										? theme.sidebar
																												.menuItemColor
																										: '',
																									textDecoration: 'none',
																									borderRadius: '15px',
																									margin: '0px 0px 5px 0px',
																									'& .MuiListItemButtonRoot': {
																										marginBottom: isActive
																											? '10px'
																											: '',
																									},
																								})}
																							>
																								{({ isActive }) => (
																									<ListItemButton
																										sx={{
																											gap: '8px',

																											width: '100%',
																											paddingRight: isActive
																												? '5px'
																												: 'px',
																										}}
																										disableTouchRipple
																									>
																										<ListItemIcon>
																											<Icon icon={child.icon} />
																										</ListItemIcon>
																										<ListItemText
																											primaryTypographyProps={{
																												variant: 'body',
																												color:
																													theme.colors.alpha
																														.white[100],
																											}}
																											primary={child.title}
																										/>
																										{isActive && (
																											<UilAngleRight
																												style={{
																													color:
																														theme.colors.alpha
																															.white[100],
																												}}
																											/>
																										)}
																									</ListItemButton>
																								)}
																							</NavLink>
																						</Box>
																					)}
																				</React.Fragment>
																			))}
																		</List>
																	</Collapse>
																)}
															</Box>
														) : (
															<Tooltip title={item.title} placement="right">
																<Box>
																	<IconButton
																		icon={item.icon}
																		size="small"
																		sx={{
																			backgroundColor: 'transparent',
																			'& .MuiSvgIcon-root': {
																				color: theme.colors.alpha.white[100],
																			},
																			'&.MuiButtonBase-root:hover': {
																				backgroundColor: 'transparent',
																			},
																			mb: 1,
																		}}
																		onClick={() => {
																			if (item.children) {
																				sidebarContext.toggleSidebar();
																				sidebarContext.openTitleCollapse(
																					item.title
																				);
																			} else {
																				navigate(item.path);
																			}
																		}}
																	/>
																</Box>
															</Tooltip>
														)}
													</>
												)}
											</React.Fragment>
										)
								)}
						</Stack>
					</List>

					{drawerOpen && (
						<Box
							display={'flex'}
							alignItems={'center'}
							pt={'10px'}
							flexDirection={'column'}
						>
							<AgentInfo
								name={agent?.name ?? ''}
								email={agent?.email ?? ''}
								phone={agent?.phone ?? ''}
							/>
							<img
								src={PoweredByIG}
								alt="Powered by InteliGro"
								width={'60%'}
								style={{
									marginTop: 30,
								}}
							/>
							<VersionNumber
								version={version}
								color={theme.colors.alpha.white[100]}
							/>
						</Box>
					)}
				</DrawerWrapper>
			</Drawer>
		</div>
	);
};

const DrawerHeader = ({
	drawerOpen,
	toggleSidebar,
}: {
	drawerOpen: boolean;
	toggleSidebar: () => void;
}) => {
	const theme = useTheme();
	return (
		<ListSubheader
			component="div"
			id="logo-sign"
			sx={{
				bgcolor: theme.colors.primary.main,
				padding: 0,
				display: 'flex',
				flexDirection: drawerOpen ? 'row' : 'column-reverse',
				alignItems: drawerOpen ? 'flex-start' : 'center',
				pb: drawerOpen ? 0 : 2,
				height: 'auto',
			}}
		>
			<img
				src={drawerOpen ? Logo : LogoVertical}
				alt="Logo"
				style={{
					height: drawerOpen ? '105px' : '10%',
					width: drawerOpen ? 'auto' : '80%',
				}}
			/>
			<IconButton
				icon={drawerOpen ? 'UilAngleDoubleLeft' : 'UilAngleDoubleRight'}
				size="large"
				sx={{
					backgroundColor: 'transparent',
					mb: drawerOpen ? 0 : 1,
					'& .MuiSvgIcon-root': {
						color: theme.colors.alpha.white[100],
					},
					'&.MuiButtonBase-root:hover': {
						backgroundColor: 'transparent',
					},
				}}
				onClick={() => toggleSidebar()}
			/>
		</ListSubheader>
	);
};
