import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseApi } from 'src/services/rtkQuery/baseApi';
import { getAuthStorage } from 'src/services/firebase/setAuthStorage';
import store from 'src/store/store';
import {
	Access,
	IUserSlice,
	UserRoles,
} from 'src/store/user/userSlice.contracts';

export const persistAuth = createAsyncThunk(
	'user/persistAuth',
	async (params: { userAuth: Partial<IUserSlice> }, { rejectWithValue }) => {
		try {
			if (!params.userAuth.name) {
				let user: IUserSlice = {
					name: null,
					firebaseUser: null,
					bcId: null,
					agent: null,
					preferences: null,
					status: 'idle',
					error: null,
					accessToken: null,
					refreshToken: null,
					userRole: null,
					entities: [],
					access: [],
					tenant: null,
					...params.userAuth,
				};

				const userStorage = getAuthStorage();

				if (!userStorage) {
					if (user.accessToken) {
						const currentUser = await store.dispatch(
							baseApi.endpoints.getCurrentUser.initiate(user?.accessToken)
						);
						const { data } = currentUser;
						user = {
							...user,
							name: data?.data?.name ?? '',
							bcId: data?.data?.bcUniqueId as string,
							agent: {
								bcId: data?.data?.agentBcId ?? '',
								name: data?.data?.agentName ?? '',
								email: data?.data?.agentEmail ?? '',
								phone: data?.data?.agentPhone ?? '',
							},
							preferences: {
								crops: data?.data?.cropPreferences ?? [],
							},
							entities: data?.data?.userActiveEntities ?? [],
							access: data?.data?.access ?? [],
							tenant: data?.data?.userActiveEntities
								? data.data?.userActiveEntities.find(
										(x) => x.code === data?.data?.bcUniqueId
								  ) ?? null
								: null,
							userRole: data?.data?.userRole ?? UserRoles.Member,
							status: 'authenticated',
						};
					}
				} else {
					const userSlice: IUserSlice = JSON.parse(userStorage);
					user = {
						...user,
						name: userSlice?.name ?? '',
						bcId: userSlice?.bcId as string,
						agent: {
							bcId: userSlice?.agent?.bcId ?? '',
							name: userSlice?.agent?.name ?? '',
							email: userSlice?.agent?.email ?? '',
							phone: userSlice?.agent?.phone ?? '',
						},
						preferences: {
							crops: userSlice?.preferences?.crops ?? [],
						},
						accessToken:
							(await params.userAuth.firebaseUser?.getIdToken()) ??
							userSlice.accessToken,
						refreshToken:
							params.userAuth.firebaseUser?.refreshToken ??
							userSlice.refreshToken,
						entities: userSlice.entities ?? [],
						access: userSlice.access ?? [],
						userRole: userSlice.userRole,
						tenant: userSlice.tenant,
						status: 'authenticated',
					};
				}

				return user;
			}

			return params.userAuth as IUserSlice;
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);
