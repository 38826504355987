import { Stack, Typography, TypographyProps } from '@mui/material';

export const MobileHeader: React.FC<TypographyProps> = (props) => {
	return <Typography
				variant="bodySmall"
				noWrap
				overflow="hidden"
				textOverflow="ellipsis"
				{...props}
			/>;
};

export const MobileSubheader: React.FC<TypographyProps> = (props) => {
	return <Typography
				variant="bodySmaller"
				noWrap
				textOverflow="ellipsis"
				color="#727272"
				{...props}
			/>;
};

export interface MobileLayoutProps {
	header?: string;
	subheader?: string;
}

export const MobileLayout = ({ header, subheader }: MobileLayoutProps) => {
	return (
		<Stack
			justifyContent={header && subheader ? 'flex-start' : 'center'}
			gap="5px"
		>
			{header && <MobileHeader>{header}</MobileHeader>}
			{subheader && <MobileSubheader>{subheader}</MobileSubheader>}
		</Stack>
	);
};
