import { Box, Chip, Grid, Stack, styled } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {
	ScreenLayout,
	StyledCard,
} from 'src/components/templates/ScreenLayout/ScreenLayout';
import * as Yup from 'yup';
import { useEffect } from 'react';
import {
	SprayCalculationTypes,
	getSprayTypeString,
} from 'src/helpers/typeHelpers';
import {
	IWeedSprayCalcData,
	updateWeedSprayCalc,
} from './helpers/sprayCalculation';
import { SprayCalculatorScreenActions } from 'src/components/Pages/MyFarm/SprayCalculationsDetail';
import { CustomFormError, CustomTextInput } from 'features';
import { SprayCalculationProducts } from '../SprayCalculationProducts';

const CustomChip = styled(Chip)(
	({ theme }) => `
    font-size: 14px;
    background-color: ${theme.colors.secondary.main};
    margin: auto;
`
);

type WeedSprayFormProps = {
	formSubmitting: boolean;
	blockSize: number;
};

const WeedSprayForm = ({ formSubmitting, blockSize }: WeedSprayFormProps) => {
	const { values, touched, errors, setValues } =
		useFormikContext<IWeedSprayCalcData>();

	useEffect(() => {
		const calculatedValues = updateWeedSprayCalc(values);
		setValues({ ...values, ...calculatedValues });
	}, [
		values.tractorSpeed,
		values.sprayVolume,
		values.sprayPlumWidth,
		values.nozzleAmount,
	]);

	const determineHectarePerTank = () => {
		const value = (values.tankSize ?? 0) / (values.sprayVolume ?? 0);
		return isNaN(value) ? 0 : value;
	};

	return (
		<>
			<ScreenLayout
				title={`Spray Calculations${values.title ? ` - ${values.title}` : ''}`}
				icon="UilRaindrops"
				screenActionsEnd={
					<SprayCalculatorScreenActions loading={formSubmitting} />
				}
				titleChip={
					<CustomChip
						label={getSprayTypeString(SprayCalculationTypes.WEED)}
						size="small"
					/>
				}
			>
				<Stack gap={2}>
					<StyledCard
						title="Weed Spray Details"
						icon="UilBorderAlt"
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }} container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12}>
								<Field
									name="title"
									as={CustomTextInput}
									label="Title"
									placeholder="Enter a title for the spray"
									fullwidth
									value={values.title}
								/>
								{touched.title && errors.title ? (
									<CustomFormError errorMessage={errors.title} />
								) : null}
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="sprayVolume"
									type="number"
									as={CustomTextInput}
									label="Desired Spray Volume (L/ha)"
									placeholder="Enter the desired spray volume"
									fullwidth
									value={values.sprayVolume}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tankSize"
									type="number"
									as={CustomTextInput}
									label="Tank size (L)"
									placeholder="Enter the tank size"
									fullwidth
									value={values.tankSize}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<CustomTextInput
									label="Hectare per Tank"
									disabled={true}
									value={determineHectarePerTank()}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tractorSpeed"
									type="number"
									as={CustomTextInput}
									label="Tractor Speed (km/h)"
									placeholder="Enter the tractor speed (km/H)"
									fullwidth
									value={values.tractorSpeed}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="sprayPlumWidth"
									type="number"
									as={CustomTextInput}
									label="Spray Plum Width (m)"
									placeholder="Enter the spray plum width"
									value={values.sprayPlumWidth}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="nozzleAmount"
									type="number"
									InputProps={{
										step: '1',
										notched: false
									}}
									as={CustomTextInput}
									label="Number of Nozzles"
									placeholder="Enter the number of nozzles"
									fullwidth
									value={values.nozzleAmount}
								/>
								{touched.nozzleAmount && errors.nozzleAmount ? (
									<CustomFormError errorMessage={errors.nozzleAmount} />
								) : null}
							</Grid>
						</Grid>
					</StyledCard>

					<SprayCalculationProducts blockSize={blockSize} />

					<StyledCard
						title="Delivery"
						icon="UilRaindrops"
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }} container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="speedMeterPerMin"
									as={CustomTextInput}
									label="Speed (m/min)"
									placeholder="N/A"
									value={values.speedMeterPerMin?.toFixed(2)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="areaPerMin"
									component={CustomTextInput}
									label="Area Sprayed (per min)"
									placeholder="N/A"
									value={values.areaPerUnit?.toFixed(2)}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="deliveryPerUnitRequired"
									component={CustomTextInput}
									label="Delivery Per Nozzle Required"
									placeholder="N/A"
									value={values.deliveryPerUnitRequired?.toFixed(3)}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</StyledCard>
				</Stack>
			</ScreenLayout>
		</>
	);
};

export default WeedSprayForm;
