import { SprayCalculationProduct } from 'src/services/rtkQuery/types/sprayCalculation.types';

export interface INozzleConfiguration {
	deliveryLitrePerMin: number;
	nozzleColour: string;
	nozzlePressure: number;
	nozzlePort: number;
	nozzleSide: NozzleSide;
	nozzleName: string;
	id?: string;
}

export interface ISprayCalculation {
	id?: string;
	farmBlockId?: string;
	title?: string;

	rowSpacing?: number;
	plantHeight?: number;
	plantDepth?: number;
	treeRowVolume?: number;

	sprayVolume?: number;
	tankSize?: number;
	gearSelection?: string;
	tractorSpeed?: number;
	sprayPressure?: number;
	nozzleAmount?: number;
	deliveryPerUnitRequired?: number;
	nozzlePressure?: number;
	nozzleColourNeeded?: string;

	products?: SprayCalculationProduct[];

	leftSideNozzleConfiguration?: Array<INozzleConfiguration>;
	rightSideNozzleConfiguration?: Array<INozzleConfiguration>;

	leftDelivery?: number;
	rightDelivery?: number;
	totalDelivery?: number;

	deliveryPerUnit?: number;
}

export const calculateParams = (
	input: Partial<ISprayCalculation>
): ISprayCalculation => {
	const treeRowVolume =
		input.rowSpacing && input.plantHeight && input.plantDepth
			? input.plantDepth * input.plantHeight * (937 / input.rowSpacing)
			: 0;

	const deliveryPerUnitRequired =
		input.rowSpacing &&
		input.sprayVolume &&
		input.tractorSpeed &&
		input.nozzleAmount
			? (input.tractorSpeed * input.rowSpacing * input.sprayVolume) /
			  (600 * input.nozzleAmount)
			: 0;

	const leftDelivery = input.leftSideNozzleConfiguration?.length
		? input.leftSideNozzleConfiguration
				.map((x) => x.deliveryLitrePerMin)
				.reduce((accumulator, currentValue) => accumulator + currentValue)
		: 0;

	const rightDelivery = input.rightSideNozzleConfiguration?.length
		? input.rightSideNozzleConfiguration
				.map((x) => x.deliveryLitrePerMin)
				.reduce((accumulator, currentValue) => accumulator + currentValue)
		: 0;

	const totalDelivery =
		input.leftDelivery && input.rightDelivery
			? input.leftDelivery + input.rightDelivery
			: 0;

	const deliveryPerUnit =
		input.totalDelivery &&
		(input?.rowSpacing ?? 0) > 0 &&
		(input?.tractorSpeed ?? 0) > 0
			? (input.totalDelivery * 600) /
			  ((input?.rowSpacing ?? 0) * (input?.tractorSpeed ?? 0))
			: 0;

	return {
		treeRowVolume,
		deliveryPerUnitRequired,
		leftDelivery,
		rightDelivery,
		totalDelivery,
		deliveryPerUnit,
	};
};

export const replaceNozzle = (
	nozzle: INozzleConfiguration,
	nozzlesArr: INozzleConfiguration[]
) => {
	return nozzlesArr.map((n) => {
		if (n.nozzlePort === nozzle.nozzlePort) {
			return nozzle;
		}
		return n;
	});
};

export enum NozzleColour {
	White,
	Lilac,
	Brown,
	Yellow,
	Orange,
	Red,
	Grey,
	Green,
	Black,
	Blue,
}

export const mapColour = (colour: NozzleColour) => {
	switch (colour) {
		case NozzleColour.White:
			return '#FFFFFF';
		case NozzleColour.Lilac:
			return '#6A2B92';
		case NozzleColour.Brown:
			return '#7C3415';
		case NozzleColour.Yellow:
			return '#FEFE3D';
		case NozzleColour.Orange:
			return '#FFB72F';
		case NozzleColour.Red:
			return '#FF0016';
		case NozzleColour.Grey:
			return '#9B9B9B';
		case NozzleColour.Green:
			return '#00A74E';
		case NozzleColour.Black:
			return '#000000';
		case NozzleColour.Blue:
			return '#0066B4';
		default:
			return '#FFFFFF';
	}
};

export enum NozzleSide {
	Left = 'Left',
	Right = 'Right',
}

export const defaultNozzleConfig: Array<INozzleConfiguration> = [
	{
		nozzleName: 'White@Bar5',
		nozzleColour: NozzleColour[NozzleColour.White],
		nozzlePressure: 5,
		nozzlePort: 1,
		nozzleSide: NozzleSide.Left,
		deliveryLitrePerMin: 0.32,
	},
	{
		nozzleName: 'Lilac@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Lilac],
		nozzlePressure: 5,
		nozzlePort: 2,
		nozzleSide: NozzleSide.Left,
		deliveryLitrePerMin: 0.36,
	},
	{
		nozzleName: 'Brown@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Brown],
		nozzlePressure: 5,
		nozzlePort: 3,
		nozzleSide: NozzleSide.Left,
		deliveryLitrePerMin: 0.48,
	},
	{
		nozzleName: 'Yellow@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Yellow],
		nozzlePressure: 5,
		nozzlePort: 4,
		nozzleSide: NozzleSide.Left,
		deliveryLitrePerMin: 0.73,
	},
	{
		nozzleName: 'Orange@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Orange],
		nozzlePressure: 5,
		nozzlePort: 5,
		nozzleSide: NozzleSide.Left,
		deliveryLitrePerMin: 0.99,
	},
	{
		nozzleName: 'Red@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Red],
		nozzlePressure: 5,
		nozzlePort: 5,
		nozzleSide: NozzleSide.Right,
		deliveryLitrePerMin: 1.38,
	},
	{
		nozzleName: 'Grey@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Grey],
		nozzlePressure: 5,
		nozzlePort: 4,
		nozzleSide: NozzleSide.Right,
		deliveryLitrePerMin: 1.5,
	},
	{
		nozzleName: 'Green@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Green],
		nozzlePressure: 5,
		nozzlePort: 3,
		nozzleSide: NozzleSide.Right,
		deliveryLitrePerMin: 1.78,
	},
	{
		nozzleName: 'Black@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Black],
		nozzlePressure: 5,
		nozzlePort: 2,
		nozzleSide: NozzleSide.Right,
		deliveryLitrePerMin: 2,
	},
	{
		nozzleName: 'Blue@Bar5',
		nozzleColour: NozzleColour[NozzleColour.Blue],
		nozzlePressure: 5,
		nozzlePort: 1,
		nozzleSide: NozzleSide.Right,
		deliveryLitrePerMin: 2.45,
	},
];

export const orchardFormValues: ISprayCalculation = {
	title: '',
	rowSpacing: 0,
	plantHeight: 0,
	plantDepth: 0,
	treeRowVolume: 0,
	sprayVolume: 0,
	tankSize: 0,
	gearSelection: 'B2@1700RPM (NON econo)',
	tractorSpeed: 0,
	sprayPressure: 0,
	nozzleAmount: 10,
	deliveryPerUnitRequired: 0,
	nozzlePressure: 0,
	nozzleColourNeeded: '',
	leftSideNozzleConfiguration: defaultNozzleConfig.filter(
		(n) => n.nozzleSide === NozzleSide.Left
	),
	rightSideNozzleConfiguration: defaultNozzleConfig.filter(
		(n) => n.nozzleSide === NozzleSide.Right
	),
	leftDelivery: 0,
	rightDelivery: 0,
	totalDelivery: 0,
	deliveryPerUnit: 0,
};
