import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import {
	DeliveryNotesQueryParams,
	InvoicesQueryParams,
	QueryParams,
	StatementQueryParams,
} from './types/queryParams.types';
import { Quote } from './types/quote.types';
import { PurchaseOrder } from './types/purchaseOrder.types';
import { Invoice } from './types/invoice.types';
import { CreditNote } from './types/creditNote.types';
import { DeliveryNote } from './types/deliveryNote.types';
import { Overview } from './types/overview.types';

export const myAccountApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getOverviewData: builder.query<ApiResponse<Overview>, void>({
			query() {
				return {
					url: 'Overview',
				};
			},
		}),

		getQuotes: builder.query<ApiResponse<Quote[]>, QueryParams>({
			query: (args) => {
				return {
					url: 'Quote',
					params: args,
				};
			},
		}),

		getPurchaseOrders: builder.query<ApiResponse<PurchaseOrder[]>, QueryParams>(
			{
				query: (args) => {
					return {
						url: 'PurchaseOrder',
						params: args,
					};
				},
			}
		),

		getInvoices: builder.query<ApiResponse<Invoice[]>, InvoicesQueryParams>({
			query: (args) => {
				return {
					url: 'Invoice',
					params: args,
				};
			},
		}),

		getCreditNotes: builder.query<ApiResponse<CreditNote[]>, QueryParams>({
			query: (args) => {
				return {
					url: 'CreditNote',
					params: args,
				};
			},
		}),

		getDeliveryNotes: builder.query<
			ApiResponse<DeliveryNote[]>,
			DeliveryNotesQueryParams
		>({
			query: (args) => {
				return {
					url: 'DeliveryNote',
					params: args,
				};
			},
		}),

		getQuotePdf: builder.mutation<Blob, QueryParams>({
			query: (args) => {
				return {
					url: `Quote/${args.id}/pdf`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),

		getPurchaseOrderPdf: builder.mutation<Blob, QueryParams>({
			query: (args) => {
				return {
					url: `PurchaseOrder/${args.id}/pdf`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),

		getInvoicePdf: builder.mutation<Blob, QueryParams>({
			query: (args) => {
				return {
					url: `Invoice/${args.id}/pdf`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),

		getCreditNotePdf: builder.mutation<Blob, QueryParams>({
			query: (args) => {
				return {
					url: `CreditNote/${args.id}/pdf`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),

		getDeliveryNotePdf: builder.mutation<Blob, QueryParams>({
			query: (args) => {
				return {
					url: `DeliveryNote/${args.id}/pdf`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),

		getStatementPdf: builder.mutation<Blob, StatementQueryParams>({
			query: (args) => {
				return {
					url: 'Statement/pdf',
					method: 'GET',
					params: args,
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformErrorResponse: (response: { status: string | number }) => {
				return response.status;
			},
		}),
	}),

	overrideExisting: false,
});

export const {
	useGetOverviewDataQuery,
	useGetQuotesQuery,
	useGetPurchaseOrdersQuery,
	useGetInvoicesQuery,
	useGetCreditNotesQuery,
	useGetDeliveryNotesQuery,
	useGetQuotePdfMutation,
	useGetPurchaseOrderPdfMutation,
	useGetInvoicePdfMutation,
	useGetCreditNotePdfMutation,
	useGetDeliveryNotePdfMutation,
	useGetStatementPdfMutation,
} = myAccountApi;
