import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  Dialog,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { cartTableHeader, mobileHeaders } from './helpers/cartTableConfig';
import { Button, DataGrid } from 'features';
import { UilMultiply, UilShoppingCart } from '@iconscout/react-unicons';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { persistCart, selectCart } from 'src/store/cart/cartSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pages from 'src/router/routes';

type CartComponentPropTypes = {
  cartOpen: boolean;
  closeCartEvent: () => void;
};

function CartModal(props: CartComponentPropTypes) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const {
    cartOpen,
    closeCartEvent
  } = props;

  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const cart = useAppSelector(selectCart);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(persistCart());
  }, [])

  return (
    <Dialog
      open={cartOpen}
      onClose={closeCartEvent}
      fullWidth
      maxWidth='md'
    >
      <Box sx={{ p: 3 }}>
        <DialogTitle
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            p: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            mb:2
          }}>
            <Stack
              direction={'row'}
              alignItems={'center'}
            >
              <UilShoppingCart />
              <Typography
                variant="h5Bold"
                sx={{ ml: 1 }}>
                Added to cart
              </Typography>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={closeCartEvent}
            >
              <UilMultiply />
            </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            m: 0,
            p: '0.5px',
            width: '100%',
          }}>
          <DataGrid
            columns={isMobile ? mobileHeaders : cartTableHeader}
            rows={cart.products}
            getRowId={(row: any) => row.productId}
            rowCount={cart.products.length}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            columnHeaderHeight={56}
            sx={{
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': {
                display: 'flex'
              }
            }}
            />
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            endIcon='UilArrowRight'
            onClick={() => {
              navigate(pages.cart.path)
              closeCartEvent();
            }}>
            Go to cart
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default CartModal;
