import { NozzleColour } from '../../OrchardSprayForm/helpers/sprayCalculation';

type NozzleLookupTableObject = {
    nozzleColour: NozzleColour;
    deliveries: number[];
}

export const NozzleLookupTable: NozzleLookupTableObject[] = [
    {nozzleColour: NozzleColour.White, deliveries: [0.27, 0.29, 0.32, 0.34, 0.36, 0.38, 0.39, 0.41, 0.43, 0.44, 0.46, 0.47, 0.48, 0.5, 0.51, 0.52, 0.54, 0.55, 0.56, 0.57, 0.58]},
    {nozzleColour: NozzleColour.Lilac, deliveries: [0.36, 0.39, 0.42, 0.45, 0.48, 0.5, 0.52, 0.55, 0.57, 0.59, 0.61, 0.63, 0.64, 0.66, 0.68, 0.7, 0.71, 0.73, 0.74, 0.76, 0.77]},
    {nozzleColour: NozzleColour.Brown, deliveries: [0.48, 0.52, 0.56, 0.6, 0.64, 0.67, 0.7, 0.73, 0.76, 0.79, 0.81, 0.84, 0.86, 0.89, 0.91, 0.93, 0.95, 0.98, 1, 1.02, 1.04]},
    {nozzleColour: NozzleColour.Yellow, deliveries: [0.73, 0.8, 0.86, 0.92, 0.97, 1.03, 1.07, 1.12, 1.17, 1.21, 1.25, 1.29, 1.33, 1.37, 1.4, 1.44, 1.48, 1.51, 1.54, 1.58, 1.61]},
    {nozzleColour: NozzleColour.Orange, deliveries: [0.99, 1.08, 1.17, 1.24, 1.32, 1.39, 1.45, 1.51, 1.57, 1.63, 1.69, 1.74, 1.79, 1.84, 1.89, 1.94, 1.99, 2.03, 2.07, 2.12, 2.16]},
    {nozzleColour: NozzleColour.Red, deliveries: [1.38, 1.51, 1.62, 1.73, 1.83, 1.92, 2.01, 2.09, 2.17, 2.25, 2.33, 2.4, 2.47, 2.54, 2.6, 2.67, 2.73, 2.79, 2.85, 2.91, 2.97]},
    {nozzleColour: NozzleColour.Grey, deliveries: [1.5, 1.63, 1.76, 1.87, 1.98, 2.08, 2.17, 2.26, 2.35, 2.43, 2.51, 2.59, 2.67, 2.74, 2.81, 2.88, 2.95, 3.01, 3.07, 3.14, 3.2]},
    {nozzleColour: NozzleColour.Green, deliveries: [1.78, 1.94, 2.09, 2.22, 2.35, 2.47, 2.58, 2.69, 2.79, 2.89, 2.99, 3.08, 3.17, 3.25, 3.34, 3.42, 3.5, 3.57, 3.65, 3.72, 3.8]},
    {nozzleColour: NozzleColour.Black, deliveries: [2, 2.18, 2.35, 2.5, 2.64, 2.78, 2.9, 3.03, 3.14, 3.26, 3.36, 3.47, 3.57, 3.67, 3.76, 3.85, 3.94, 4.03, 4.12, 4.2, 4.28]},
    {nozzleColour: NozzleColour.Blue, deliveries: [2.45, 2.67, 2.87, 3.06, 3.24, 3.4, 3.56, 3.71, 3.85, 3.99, 4.12, 4.25, 4.37, 4.49, 4.61, 4.72, 4.84, 4.94, 5.05, 5.15, 5.25]},
]

export const getNozzleDeliveries = (colour?: NozzleColour) => {
    const nozzleColourDeliveries = NozzleLookupTable.find((n) => n.nozzleColour === colour);
    return nozzleColourDeliveries?.deliveries;
}

export const getNozzleDelivery = (colour: NozzleColour, nozzlePressure: number) => 
{
    const deliveries = NozzleLookupTable.find((n) => n.nozzleColour == colour);
    return deliveries?.deliveries[nozzlePressure - 5];
}

export const getNozzleName = (colour: NozzleColour, pressure: number) => `${NozzleColour[colour]}@Bar${pressure}`