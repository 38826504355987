import { IHeader, createHeaders } from 'features';

const cartTableHeaderConfiguration: Array<IHeader> = [
  {
    field: 'productDescription',
    headerName: 'Product',
    icon: 'UilBox'
  },
  {
    field: 'productId',
    headerName: 'Code',
    icon: 'UilUser'
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    icon: 'UilMoneyStack',
    headerAlign: 'center',
    align: 'center'
  }
];

export const cartTableHeader = createHeaders(cartTableHeaderConfiguration, []);

const mobileHeaderConfiguration: Array<IHeader> = [
  {
    field: 'productDescription',
    headerName: 'Product',
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    headerAlign: 'center',
    flex: 0.3,
    align: 'center'
  }
];

export const mobileHeaders = createHeaders(mobileHeaderConfiguration, []);
