import { User } from '@firebase/auth';
import { Entity } from 'src/services/rtkQuery/types/user.types';

export enum UserRoles {
	Admin = 0,
	Member = 1,
}

export enum Access {
	MyAccount = 0,
	MyFarm = 1,
	Products = 2,
}
export enum UserTitle {
	None = 0,
	Owner = 1,
	FarmManager = 2,
	TechnicalManager = 3,
	Storeman = 4,
	SprayOperator = 5,
	AdminClerk = 6,
	AccountsClerk = 7,
}

export interface IAgent {
	bcId: string;
	name: string;
	email: string;
	phone: string;
}

export interface IUserSlice {
	name: string | null;
	firebaseUser: User | null;
	bcId: string | null;
	agent: IAgent | null;
	preferences: { crops: string[] } | null;
	status: 'authenticated' | 'error' | 'idle' | 'loading' | 'registered';
	accessToken: string | null;
	refreshToken: string | null;
	userRole: UserRoles | null;
	entities: Entity[];
	tenant: Entity | null;
	access: Access[];
	error: string | null | undefined;
}
