import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, SxProps, TextField, debounce } from '@mui/material';
import { CustomInputLabel } from 'features';

type GoogleAutoCompleteProps = {
	googleMapsApiKey: string;
	locLatLong?: string;
	onLocationClickEvent: (latLng: string, locationDescription: string) => void;
	onClearEvent: () => void;
	setLoading?: (loading: boolean) => void;
	mapStyles?: SxProps;
};

interface PlaceType {
	description: string;
	place_id?: string;
	latLng?: string;
}

interface Location {
	geoCoderLocation?: google.maps.GeocoderResult;
	placeValue?: PlaceType;
}

const defaultLatLngObject = { lat: -28.4792625, lng: 24.6727135 };

export const GoogleAutoComplete = (props: GoogleAutoCompleteProps) => {
	const { googleMapsApiKey } = props;
	let map: google.maps.Map;
	const mapRef = useRef<HTMLDivElement>(null);
	const autocompleteService = { current: null };
	const [options, setOptions] = useState<readonly PlaceType[]>([]);
	const [inputValue, setInputValue] = useState('za');
	const [location, setLocation] = useState<Location>({
		placeValue: {
			description: '',
			// eslint-disable-next-line camelcase
			place_id: '',
			latLng: '',
		},
	});

	const loader = new Loader({
		apiKey: googleMapsApiKey ?? '',
		version: 'weekly',
		libraries: ['places'],
	});

	const convertStringToLatLng = (latLng: string): google.maps.LatLng => {
		const latlngString = latLng?.split(',', 2);
		return new google.maps.LatLng({
			lat: parseFloat(latlngString[0]),
			lng: parseFloat(latlngString[1]),
		});
	};

	const convertLatLngToString = (latLng: google.maps.LatLng): string =>
		`${latLng.lat()},${latLng.lng()}`;

	const setLocationWithGeocoder = ({
		latLng,
		placeId,
	}: {
		latLng?: google.maps.LatLng;
		placeId?: string;
	}) => {
		const geocoder = new google.maps.Geocoder();
		const geoCoderRequest: google.maps.GeocoderRequest = latLng
			? { location: latLng }
			: { placeId: placeId };

		geocoder.geocode(geoCoderRequest).then(async (response) => {
			if (response.results[0]) {
				setLocation({
					geoCoderLocation: response.results[0],
					placeValue: {
						description: response.results[0].formatted_address,
						latLng: convertLatLngToString(
							response.results[0].geometry.location
						),
					},
				});
				await addMarker(
					latLng ?? response.results[0].geometry.location,
					response.results[0].formatted_address
				);
			}
		});
	};

	const createMap = async (latLng?: google.maps.LatLng) => {
		if (mapRef.current) {
			const { Map } = (await google.maps.importLibrary(
				'maps'
			)) as google.maps.MapsLibrary;
			map = new Map(mapRef.current, {
				center: latLng
					? { lat: latLng.lat(), lng: latLng.lng() }
					: defaultLatLngObject,
				zoom: 8,
				streetViewControl: false,
				fullscreenControl: false,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				},
				// This map type displays a transparent layer of major streets on satellite images
				mapTypeId: google.maps.MapTypeId.HYBRID
			});

			//Check for clicks
			map.addListener('click', (event: google.maps.MapMouseEvent) => {
				if (event.latLng) {
					setLocationWithGeocoder({ latLng: event.latLng });
				}
			});
		}
	};

	let marker: google.maps.Marker;
	const addMarker = async (
		position: google.maps.LatLng,
		positionDescription: string
	) => {
		if (!map) await createMap(position);

		if (!marker) {
			marker = new google.maps.Marker({
				position,
				map,
			});

			marker.setMap(map);
		} else {
			marker.setPosition(position);
		}

		props.onLocationClickEvent(
			convertLatLngToString(position),
			positionDescription
		);
	};

	const fetch = useMemo(
		() =>
			debounce(
				(
					request: {
						input: string;
						componentRestrictions: { country: string };
					},
					callback: (results?: any[]) => void
				) => {
					if (autocompleteService.current) {
						(autocompleteService.current as any).getPlacePredictions(
							request,
							callback
						);
					}
				},
				300
			),
		[]
	);

	useEffect(() => {
		loader.load().then(async () => {
			if (!map) await createMap();

			if (props.locLatLong || location?.geoCoderLocation) {
				const latLngObj: google.maps.LatLng | undefined = props.locLatLong
					? convertStringToLatLng(props.locLatLong)
					: location?.geoCoderLocation?.geometry.location;
				map.setCenter(latLngObj ?? new google.maps.LatLng(defaultLatLngObject));
				setLocationWithGeocoder({
					latLng: latLngObj ?? new google.maps.LatLng(defaultLatLngObject),
				});
			}

			if (!autocompleteService.current && (window as any).google) {
				const service = new (
					window as any
				).google.maps.places.AutocompleteService();
				autocompleteService.current = service;
			}
			if (!autocompleteService.current) {
				return undefined;
			}

			if (props?.setLoading) {
				props.setLoading(false);
			}
		});
	}, []);

	useEffect(() => {
		if (autocompleteService) {
			fetch(
				{
					input: inputValue,
					componentRestrictions: { country: 'za' },
				},
				(results?: any[]) => {
					let newOptions: PlaceType[] = [];

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					setOptions(newOptions);
				}
			);
		}
	}, [inputValue, fetch]);

	const onOptionChange = (
		_: SyntheticEvent<Element, Event>,
		value: PlaceType | null
	) => {
		if (value?.place_id) {
			setLocationWithGeocoder({ placeId: value?.place_id });
		} else {
			setLocation({
				placeValue: {
					description: '',
					// eslint-disable-next-line camelcase
					place_id: '',
					latLng: '',
				},
			});

			props.onClearEvent();
		}
	};

	return (
		<>
			<Box display="flex">
				<CustomInputLabel
					htmlFor={'locationSearch'}
					title={'Search Location'}
					mb={0}
				/>
			</Box>

			<Autocomplete
				sx={{ width: '100%' }}
				getOptionLabel={(option: any) =>
					typeof option === 'string' ? option : option.description
				}
				options={options}
				autoComplete
				includeInputInList
				filterSelectedOptions
				value={location?.placeValue}
				noOptionsText="No locations"
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				onChange={onOptionChange}
				renderInput={(params: any) => (
					<TextField {...params} fullWidth placeholder="Select location" />
				)}
				isOptionEqualToValue={(option: PlaceType, value: PlaceType) =>
					option.place_id === value.place_id
				}
			/>
			<Box
				ref={mapRef}
				sx={{
					width: '100%',
					height: {
						md: '240px',
						sm: '150px',
					},
					marginTop: '10px',
					borderRadius: '15px',
					...props.mapStyles,
				}}
			/>
		</>
	);
};
