import {
	Avatar,
	Box,
	Divider,
	ListItemIcon,
	Menu,
	MenuItem,
	MenuItemProps as MuiMenuItemsProps,
	useTheme,
	Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { StyledUserIconButton } from './Header.styles';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Icon, IconProps } from 'features/src/atoms/Icon/Icon';
import { ProfileModal } from 'src/components/organisms/ProfileModal/ProfileModal';
import { useAppSelector } from 'src/hooks/hooks';
import { selectUser } from 'src/store/user/userSlice';
import { toast } from 'react-toastify';
import { useAuth } from 'src/features/authentication';

interface MenuItemsProps extends MuiMenuItemsProps {
	icon?: IconProps['icon'];
}

type UserContainerProps = {
	menuItems: MenuItemsProps[];
};

export const UserContainer = ({ menuItems }: UserContainerProps) => {
	const theme = useTheme();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [profileModal, setProfileModal] = useState<boolean>(false);

	const { logout } = useAuth();

	const user = useAppSelector(selectUser);

	useEffect(() => {
		if (user.preferences?.crops.length === 0)
			toast.warning(
				// eslint-disable-next-line quotes
				"Please remember to update your crop preferences under 'Edit Profile'"
			);
	}, []);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const sidebar = useContext(SidebarContext);

	return (
		<Box sx={{ flexGrow: 0 }}>
			<StyledUserIconButton
				disableRipple
				first
				onClick={handleOpenUserMenu}
				icon='UilAngleDown'>
				<Avatar alt={user.name ?? ''} src={user.firebaseUser?.photoURL ?? ''} />
			</StyledUserIconButton>
			<Menu
				sx={{
					mt: '45px',
					'& .MuiPaper-root': {
						'&.MuiMenu-paper': {
							width: '341px',
							borderRadius: '15px',
						},
					},
				}}
				MenuListProps={{
					sx: {
						padding: 0,

						'& .MuiMenuItem-root': {
							padding: '15px',
							borderRadius: '15px',
							color: 'black !important',
							'&:hover': {
								backgroundColor: '#F2FFED !important',
							},
							'&:active': {
								backgroundColor: theme.palette.primary.main + ' !important',
								color: '#fff !important',
							},
							'& .MuiListItemIcon-root': {
								color: 'inherit',
							},
						},
					},
				}}
				id='menu-appbar'
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '12px',
						padding: '12px 4px 8px 12px',
					}}>
					<Avatar
						alt={user.name ?? ''}
						src={user.firebaseUser?.photoURL ?? ''}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
						}}>
						<Typography variant='bodyBold'>{user.name ?? ''}</Typography>
						<Typography variant='bodySmall'>
							{user.firebaseUser?.email ?? ''}
						</Typography>
					</Box>
				</Box>
				<Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
				{menuItems.map((setting) => (
					<MenuItem
						sx={{
							justifyContent: 'space-between',
						}}
						key={setting.key}
						onClick={async () => {
							if (setting.children === 'Logout') {
								await logout();
							} else if (setting.children === 'Edit Profile') {
								setProfileModal(true);
							}
							handleCloseUserMenu();
							sidebar.resetNavItems();
						}}>
						<Typography variant='bodyBold' sx={{ mr: 2 }} color='inherit'>
							{setting.children}
						</Typography>
						<ListItemIcon>
							{setting.icon && <Icon icon={setting.icon} />}
						</ListItemIcon>
					</MenuItem>
				))}
			</Menu>
			<ProfileModal
				user={user}
				handleClose={() => {
					setProfileModal(false);
				}}
				open={profileModal}
			/>
		</Box>
	);
};
