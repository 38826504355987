import { IAction } from '../organisms/DataGrid/dataGridHelpers';
import { useTheme } from '@mui/material/styles';

export interface ActionParameters {
	onClick: (e: any) => void;
	title?: string | null;
	icon?: string | null;
	disabled?: (params: any) => boolean;
	showInMenu?: boolean;
}

export const AskAQuestionAction = ({ onClick }: ActionParameters): IAction => {
	return {
		label: 'Ask a question',
		showInMenu: true,
		icon: 'UilQuestionCircle',
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Contained),
	};
};

export const ViewMoreAction = ({ onClick }: ActionParameters): IAction => {
	return {
		label: 'View more detail',
		showInMenu: true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Outlined),
	};
};

export const DeleteAction = ({ onClick, title }: ActionParameters): IAction => {
	return {
		label: title ?? 'Delete',
		icon: 'UilTrashAlt',
		showInMenu: true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Danger),
	};
};

export const ViewPdfAction = ({ onClick }: ActionParameters): IAction => {
	return {
		label: 'View PDF',
		icon: 'UilEye',
		showInMenu: true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Outlined),
	};
};

export const DownloadPdfAction = ({ onClick }: ActionParameters): IAction => {
	return {
		label: 'Download PDF',
		icon: 'UilDownloadAlt',
		showInMenu: true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Outlined),
	};
};

export const BasicOutlinedAction = ({
	onClick,
	title,
	icon,
	disabled,
	showInMenu,
}: ActionParameters): IAction => {
	return {
		label: title ?? '',
		icon: icon ?? '',
		showInMenu: showInMenu ?? true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Outlined),
		disabled: disabled,
	};
};

export const BasicContainedAction = ({
	onClick,
	title,
	icon,
	disabled,
	showInMenu,
}: ActionParameters): IAction => {
	return {
		label: title ?? '',
		icon: icon ?? '',
		showInMenu: showInMenu ?? true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Contained),
		disabled: disabled,
	};
};

export const BasicContainedSecondaryAction = ({
	onClick,
	title,
	icon,
	disabled,
	showInMenu,
}: ActionParameters): IAction => {
	return {
		label: title ?? '',
		icon: icon ?? '',
		showInMenu: showInMenu ?? true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.ContainerSecondary),
		disabled: disabled,
	};
};

export const BasicDangerAction = ({
	onClick,
	title,
	icon,
	disabled,
	showInMenu,
}: ActionParameters): IAction => {
	return {
		label: title ?? '',
		icon: icon ?? '',
		showInMenu: showInMenu ?? true,
		onClick: onClick,
		styles: getActionStyle(ActionButtonType.Danger),
		disabled: disabled,
	};
};

export const BasicAction = ({
	onClick,
	title,
	icon,
	disabled,
	showInMenu,
}: ActionParameters): IAction => {
	return {
		label: title ?? '',
		icon: icon ?? '',
		showInMenu: showInMenu ?? true,
		onClick: onClick,
		disabled: disabled,
		styles: getActionStyle(ActionButtonType.Basic),
	};
};

export enum ActionButtonType {
	Contained,
	ContainerSecondary,
	Outlined,
	Danger,
	Basic,
}

export const getActionStyle = (buttonType: ActionButtonType) => {
	const theme = useTheme();

	switch (buttonType) {
		case ActionButtonType.Contained:
			return {
				backgroundColor: theme.colors.primary.main,
				alignItems: 'center',
				justifyContent: 'center',
				color: theme.colors.alpha.white[100],
				'& .MuiListItemIcon-root': {
					color: theme.colors.alpha.white[100],
					minWidth: '0px',
					padding: '0px 0px 0px 4px',
				},
				'&:hover': {
					backgroundColor: theme.colors.primary.dark,
					color: theme.colors.alpha.white[100],
				},
			};
		case ActionButtonType.Outlined:
			return {
				border: `2px solid ${theme.colors.alpha.black[100]}`,
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',

				'&:hover': {
					backgroundColor: theme.colors.alpha.black[100],
					color: theme.colors.alpha.white[100],
					'& .MuiListItemIcon-root': {
						color: theme.colors.alpha.white[100],
						minWidth: '0px',
					},
				},
				'& .MuiListItemIcon-root': {
					minWidth: '0px',
					padding: '0px 0px 0px 4px',
				},
			};
		case ActionButtonType.Danger:
			return {
				backgroundColor: theme.colors.error.main,
				color: theme.colors.alpha.white[100],
				'&:hover': {
					backgroundColor: theme.colors.error.light,
					color: theme.colors.alpha.white[100],
				},
				'&:active': {
					backgroundColor: theme.colors.error.light,
					color: theme.colors.alpha.white[100],
				},
				'& .MuiListItemIcon-root': {
					color: theme.colors.alpha.white[100],
					minWidth: '0px',
				},
			};
		case ActionButtonType.ContainerSecondary:
			return {
				backgroundColor: theme.colors.secondary.main,
				color: theme.colors.alpha.white[100],
				alignContent: 'center',

				'&:hover': {
					backgroundColor: theme.colors.secondary.dark,
					color: theme.colors.alpha.white[100],
				},
				'& .MuiListItemIcon-root': {
					minWidth: '0px',
				},
			};
		case ActionButtonType.Basic:
			return {
				'&.MuiMenuItem-root': {
					width: '100%',
					display: 'flex',
					p: 0,
					flexDirection: 'row',
					justifyContent: 'flex-start',
				},
			};
		default:
			return {};
	}
};
