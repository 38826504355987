import {
	Box,
	InputAdornment,
	useTheme,
	IconButton,
	Typography,
} from '@mui/material';
import { CustomInputLabel } from '../InputLabel/CustomInputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { globalStyles } from '../../constants/globalStyles';
import { CustomTextInput } from '../CustomTextInput/CustomTextInput';

type LabelledTextInputPropTypes = {
	width?: string | number;
	id: string;
	title?: string;
	value: number | string | Date;
	type?: string;
	placeholder?: string;
	alignSelf?: string;
	step?: number;
	minVal?: number;
	maxVal?: number;
	disabled?: boolean;
	lines?: number;
	padding?: string;
	children?: JSX.Element | JSX.Element[];
	onChangeEvent?: (event: any) => void;
	required?: boolean;
	clearText?: () => void;
	inputWidth?: number | string;
	wrapLabel?: string;
};

export const LabelledTextInput = (props: LabelledTextInputPropTypes) => {
	const theme = useTheme();

	const {
		id,
		width,
		title,
		value,
		type = 'text',
		placeholder = '',
		alignSelf = 'none',
		step = 0.1,
		minVal = 0,
		maxVal = 99999999999,
		disabled = false,
		padding = '',
		lines,
		children = [],
		onChangeEvent,
		required = true,
		clearText,
		inputWidth,
		wrapLabel,
	} = props;

	const selectFieldEnable = type === 'select' ? true : false;
	const searchFieldEnable = type === 'search' ? true : false;

	return (
		<Box key={id + 'wrp'} sx={{width: width}}>
			{title && (
				<Box
					display='flex'
					alignItems={alignSelf}
					justifyContent={alignSelf}
					style={{
						padding: padding,
					}}>
					<CustomInputLabel
						htmlFor={id}
						title={title}
						mb={0}
						wrap={wrapLabel}
					/>
				</Box>
			)}
			<CustomTextInput
				multiline={lines ? true : false}
				rows={lines && lines}
				key={id}
				required={required}
				size='small'
				id={id}
				placeholder={placeholder}
				type={searchFieldEnable ? 'text' : type}
				style={{
					...globalStyles.textField,
					alignSelf: alignSelf,
					padding: padding,
				}}
				value={value}
				inputProps={{ step: step, min: minVal, max: maxVal }}
				disabled={disabled}
				onChange={onChangeEvent}
				select={selectFieldEnable}
				SelectProps={{
					label: placeholder,
					MenuProps: { style: { backdropFilter: 'blur(0px)' } },
					IconComponent: KeyboardArrowDownIcon,
					displayEmpty: true,
					renderValue:
						value !== ''
							? undefined
							: () => (
									<Typography color={theme.colors.alpha.grey[3]}>
										{placeholder}
									</Typography>
							  ),
				}}
				sx={{
					'& .MuiInputBase-root': {
						height: lines ? lines * 25 : 46,
					},
				}}
				InputProps={{
					startAdornment: searchFieldEnable && (
						<InputAdornment position='start'>
							<SearchOutlinedIcon />
						</InputAdornment>
					),
					endAdornment: searchFieldEnable && (
						<InputAdornment position='end' sx={{ mr: -2 }}>
							<IconButton
								sx={{ borderRadius: 10 }}
								onClick={(_) => {
									if (clearText) {
										clearText();
									}
								}}>
								<CloseIcon />
							</IconButton>
						</InputAdornment>
					),
					style: { width: inputWidth },
				}}>
				{children}
			</CustomTextInput>
		</Box>
	);
};
