import { toast } from 'react-toastify';
import {
  getBlob,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
  FirebaseStorage
} from 'firebase/storage';
import { blobToFile } from 'features';

/**
 * Uploads a single file to Firebase Storage and returns the url to that file.
 *
 * @param storage - a Firebase Storage instance
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @param file - The file to upload
 * @param fileName? - optional name of the file to upload
 * @returns the url where the file is stored
 *
 */
export const uploadFileToFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string, 
    file: File, 
    fileName?: string
    ): Promise<string> => {
  try {
    const name = fileName ? fileName : file.name;
    const storageRef = ref(storage, pathReference + '/' + name);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;

  } catch (error: any) {
    toast.error(error);
    return '';
  }
};

/**
 * Uploads multiple files to Firebase Storage and returns a list of urls to those files.
 *
 * @param storage - a Firebase Storage instance
 * @param files - The files to upload
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @returns a list of urls where the files are stored
 *
 */
export const uploadFilesToFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string,
    files: File[]
    ): Promise<string[]> => {
  try {
    const urls: string[] = [];
    const promises = files.map(async (file) => {
      const storageRef = ref(storage, pathReference + '/' + file.name);
      await uploadBytes(storageRef, file);
      urls.push(await getDownloadURL(storageRef));
    });

    await Promise.all(promises);
    return urls;

  } catch (error: any) {
    toast.error(error);
    return [];
  }
};

/**
 * Downloads a single file from Firebase Storage and returns as a File object
 *
 * @param storage - a Firebase Storage instance
 * @param pathReference - The Firebase Storage file location, e.g. 'Users/{id}/ProfilePic.png'
 * @returns a File
 *
 */
export const getFileFromFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string
    ): Promise<File | null> => {
  try {
    const storageRef = ref(storage, pathReference);
    const blob = await getBlob(storageRef);
    return blobToFile(blob, storageRef.name);
  } catch (error: any) {
    toast.error(error);
    return null;
  }
};

/**
 * Downloads multiple Files from Firebase Storage and returns a list of Files.
 *
 * @param storage - a Firebase Storage instance
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @returns a list of Files
 *
 */
export const getFilesFromFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string
    ): Promise<File[]> => {
  try {
    const storageRef = ref(storage, pathReference);

    // get list of file references in specified folder
    const response = await listAll(storageRef);

    const files: File[] = [];
    if (response !== undefined && response.items.length > 0) {
      const promises = response.items.map(async itemRef => {
        const blob = await getBlob(itemRef);
        files.push(blobToFile(blob, itemRef.name));
      })
      await Promise.all(promises);
    }
    return files;

  } catch (error: any) {
    toast.error(error);
    return [];
  }
};

/**
 * Gets the URL of a file in the specified Firebase Storage location
 *
 * @param storage - a Firebase Storage instance
 * @param pathReference - The Firebase Storage file location, e.g. 'Users/{id}/ProfilePic.png'
 * @returns a list of urls
 *
 */
export const getUrlFromFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string
    ): Promise<string | null> => {
  try {
    const storageRef = ref(storage, pathReference);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error: any) {
    toast.error(error);
    return null;
  }
};

/**
 * Gets the URLs of all files in the specified Firebase Storage folder
 *
 * @param storage - a Firebase Storage instance
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/abc/Questions/123'
 * @returns a list of urls
 *
 */
export const getUrlsFromFirebase = async (
    storage: FirebaseStorage, 
    pathReference: string
    ): Promise<string[]> => {
  try {
    const storageRef = ref(storage, pathReference);

    // get list of files references in specified folder
    const response = await listAll(storageRef);

    const urls: string[] = [];
    if (response !== undefined && response.items.length > 0) {
      const promises = response.items.map(async itemRef => {
        urls.push(await getDownloadURL(itemRef));
      })
      await Promise.all(promises);
    }
    return urls;

  } catch (error: any) {
    toast.error(error);
    return [];
  }
};