import {
	Box,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Grid,
	OutlinedInput,
	Chip,
	MenuItem,
	InputLabel,
	useTheme,
	Select,
	ListItemText,
	SelectChangeEvent,
	FormControl,
	FormLabel,
	FormHelperText,
	Dialog,
	Button,
	Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import {
	Access,
	Entity,
	EntityRole,
	InviteUserRequestBody,
	TeamMember,
	UpdateUserBody,
	UserRoles,
	UserTitle,
} from 'features';
import { getEnumKeys, getReadableEnum } from 'features';
import { CustomTextInput } from 'features';
import { LoadingButton } from '@mui/lab';
import { CustomBackdrop } from 'features';
import { Checkbox as CheckboxWithLabel } from 'features';
import { Icon } from 'features';

const validationSchema = Yup.object().shape({
	name: Yup.string(),
	email: Yup.string()
		.max(255)
		.required('Please enter an email address')
		.email('Please enter a single, valid email address'),
	phone: Yup.string(),
});

type AddOrEditTeamMemberModalProps = {
	modalState: boolean;
	closeModal: () => void;
	userId: string | null | undefined;
	user?: TeamMember;
	entities: Entity[];
	isLoading: boolean;
	checkUserByEmail: (
		email: string
	) => Promise<{ exist: boolean; user: TeamMember } | undefined>;
	inviteUser: (user: InviteUserRequestBody) => Promise<void>;
	updateUser: (user: UpdateUserBody) => Promise<void>;
};

interface UserFormValues {
	id?: string;
	name: string;
	email: string;
	cellPhone: string;
	entities: string[];
	role: UserRoles;
	title?: UserTitle;
	permittedAccess?: Access[];
}

export const AddOrEditTeamMemberModal = ({
	modalState,
	closeModal,
	userId,
	user,
	entities,
	isLoading,
	checkUserByEmail,
	inviteUser,
	updateUser,
}: AddOrEditTeamMemberModalProps) => {
	const [disableFormEdit, setDisableFormEdit] = useState(false);

	const [formLoading, setFormLoading] = useState(false);

	const initForm: UserFormValues = {
		name: '',
		email: '',
		entities: [],
		cellPhone: '',
		role: UserRoles.Member,
		permittedAccess: [],
	};

	const theme = useTheme();

	const closeAndClearModal = () => {
		closeModal();
		formik.resetForm({ values: initForm });
		setDisableFormEdit(false);
	};

	const submitUser = async (values: UserFormValues) => {
		try {
			const entites = values.entities.map((e): EntityRole => {
				return {
					entityId: e,
					access: values.permittedAccess?.map((x) => x as Access) ?? [],
					role: values.role,
					title: (values.title as UserTitle) ?? UserTitle.None,
				};
			});
			setFormLoading(true);

			if (userId) {
				await updateUser({
					userId,
					name: values.name,
					phoneNumber: values.cellPhone,
					entityList: entites,
				});
				toast.success('Successfully updated user');
			} else {
				await inviteUser({
					email: values.email,
					name: values.name,
					redirectUrl: `${process.env.REACT_APP_INTELIGRO_SSO_URL}auth/create-password-welcome`,
					phoneNumber: values.cellPhone,
					entityList: entites,
				});
				toast.success('Successfully invited user');
			}
			setFormLoading(false);
			closeAndClearModal();
		} catch {
			toast.error('An error occured while inviting the user');
		} finally {
			setFormLoading(false);
		}
	};

	const formik = useFormik<UserFormValues>({
		initialValues:
			userId !== null
				? {
						id: userId ?? user?.firebaseUid,
						name: user?.name ?? '',
						email: user?.emailAddress ?? '',
						cellPhone: user?.phoneNumber ?? '',
						entities: user?.userActiveEntities.map((e) => e.id) ?? [],
						permittedAccess: user?.access ?? [],
						role: user?.userRole ?? UserRoles.Member,
						title: user?.title ?? UserTitle.None,
				  }
				: initForm,
		enableReinitialize: true,
		onSubmit: submitUser,
	});

	const [emailAddressToCheck] = useDebounce(formik.values.email, 700);

	const { values, touched, errors, setFieldValue, dirty } = formik;

	// This is to set the data if the user already exists
	useEffect(() => {
		const checkEmail = async () => {
			const userCheckResponse = await checkUserByEmail(emailAddressToCheck);

			if (userCheckResponse?.exist && userId === null) {
				toast.error(
					'User already exist on platform. You can however add the user to your entities'
				);

				setDisableFormEdit(true);
				const { user } = userCheckResponse;
				formik.setValues({
					id: user?.firebaseUid,
					name: user?.name ?? '',
					email: user?.emailAddress ?? '',
					cellPhone: '',
					entities: [],
					permittedAccess: [],
					role: UserRoles.Member,
				});
			} else {
				setDisableFormEdit(false);
				formik.setValues({
					name: values?.name ?? '',
					email: values?.email ?? '',
					cellPhone: '',
					entities: values.entities,
					permittedAccess: values.permittedAccess,
					role: values.role,
				});
			}
		};

		if (emailAddressToCheck !== '' && dirty) checkEmail();
	}, [emailAddressToCheck, dirty]);

	const userTitle = getEnumKeys(UserTitle).filter(
		(x) => !['0', '1'].includes(x)
	);
	const accessKeys = getEnumKeys(Access);

	return (
		<Dialog
			fullWidth
			maxWidth={'md'}
			open={modalState}
			onClose={closeAndClearModal}
		>
			<>
				<CustomBackdrop loading={isLoading} />
				<Box sx={{ padding: 2 }}>
					<form onSubmit={formik.handleSubmit}>
						<DialogTitle>
							<Typography
								variant="h5Bold"
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Icon icon="UilPlusSquare" sx={{ mr: 1 }} />
								{formik.values.id ? 'Update Team Member' : 'Add Team Member'}
							</Typography>
						</DialogTitle>
						<DialogContent>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={2}
								alignItems={'flex-start'}
							>
								{/* Name */}
								<Grid item xs={12} md={6}>
									<CustomTextInput
										name="name"
										label="Team Member Name"
										placeholder={'Enter first and last name'}
										value={values.name}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setFieldValue('name', e.target.value)
										}
										helperText={
											<ErrorText errors={errors.name} touched={touched.name} />
										}
										disabled={disableFormEdit}
									/>
								</Grid>

								{/* Entities */}
								<Grid item xs={12} md={6}>
									<InputLabel id="entites-label">Entities</InputLabel>
									<Select
										name={'entities'}
										multiple
										value={values.entities}
										onChange={(e: SelectChangeEvent<string[]>) =>
											setFieldValue('entities', e.target.value)
										}
										input={<OutlinedInput />}
										fullWidth
										renderValue={(selected: string[]) => {
											return selected
												.map((x) => entities.find((e) => e.id === x)?.name)
												.join(', ');
										}}
									>
										{entities
											.filter((x) => {
												return (
													(UserRoles[
														x.role as unknown as keyof typeof UserRoles
													] === UserRoles.Admin ||
														x.role == UserRoles.Admin) &&
													x.isDirect
												);
											})
											.map((entity) => (
												<MenuItem key={`entity-${entity.id}`} value={entity.id}>
													<Checkbox
														checked={values.entities.some(
															(x) => x === entity.id
														)}
													/>
													<ListItemText
														primary={entity.name}
														primaryTypographyProps={{ fontWeight: 'bold' }}
														secondary={
															entity.groupCode ? ` (${entity.groupCode})` : ''
														}
														secondaryTypographyProps={{ fontSize: 'small' }}
													/>
												</MenuItem>
											))}
									</Select>
								</Grid>

								{/* Email Address */}
								<Grid item xs={12} md={6}>
									<CustomTextInput
										name="email"
										label="Email Address"
										placeholder={'Enter email address'}
										disabled={userId !== null}
										value={values.email}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setFieldValue('email', e.target.value)
										}
										helperText={
											<ErrorText
												errors={errors.email}
												touched={touched.email}
											/>
										}
									/>
								</Grid>

								{/* Cellphone */}
								<Grid item xs={12} md={6}>
									<CustomTextInput
										name="cellPhone"
										label="Cellphone"
										placeholder={'Enter cellphone number'}
										value={values.cellPhone}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setFieldValue('cellPhone', e.target.value)
										}
										helperText={
											<ErrorText
												errors={errors.cellPhone}
												touched={touched.cellPhone}
											/>
										}
										disabled={disableFormEdit}
									/>
								</Grid>

								{/* Title */}
								<Grid item xs={12} md={6}>
									<InputLabel id="title-label">Title</InputLabel>
									<Select
										name={'title'}
										value={values.title as UserTitle}
										onChange={(e: SelectChangeEvent<UserTitle>) =>
											setFieldValue('title', Number(e.target.value))
										}
										input={<OutlinedInput />}
										fullWidth
									>
										{userTitle.map((title, i) => (
											<MenuItem key={`title-${i}`} value={title}>
												{getReadableEnum(UserTitle, title)}
											</MenuItem>
										))}
									</Select>
								</Grid>

								{/* Permitted Access */}
								<Grid item xs={12} md={6}>
									<FormControl fullWidth>
										<FormLabel
											id="access-label"
											sx={{
												fontWeight: 'bold',
												color: theme.colors.alpha.black[100],
												pb: 0.5,
											}}
										>
											Permitted Access
										</FormLabel>
										<Select
											name={'permittedAccess'}
											multiple
											value={(values.permittedAccess as Access[]) ?? []}
											onChange={(e: SelectChangeEvent<Access[]>) =>
												setFieldValue(
													'permittedAccess',
													(e.target.value as Access[]).map(Number)
												)
											}
											input={<OutlinedInput />}
											sx={{ mb: 1 }}
											fullWidth
											renderValue={(value: Access[]) =>
												value
													.map((x) => getReadableEnum(Access, x.toString()))
													.join(', ')
											}
										>
											{accessKeys.map((access, i) => (
												<MenuItem key={`access-${i}`} value={Number(access)}>
													<Checkbox
														checked={values.permittedAccess?.includes(
															Number(access)
														)}
													/>
													<ListItemText
														primary={getReadableEnum(Access, access)}
													/>
												</MenuItem>
											))}
										</Select>
										{values.permittedAccess?.includes(Access.Products) && (
											<FormHelperText>
												‘My Products’ give users access to place orders
											</FormHelperText>
										)}
									</FormControl>
									{values.permittedAccess?.map((x) => (
										<Chip
											key={`access-chip-${x}`}
											label={getReadableEnum(Access, x.toString())}
											variant="outlined"
											sx={{
												'& .MuiChip-label': {
													color: theme.colors.success.dark,
													p: 0,
												},
												'& .MuiChip-deleteIcon': {
													color: theme.colors.success.dark,
												},
												'& .MuiChip-deleteIcon:hover': {
													color: theme.colors.success.main,
												},
												borderColor: theme.colors.success.dark,
												flexDirection: 'row-reverse',
												px: 1,
												mr: 1,
												mb: 1,
											}}
											onDelete={() =>
												setFieldValue(
													'permittedAccess',
													values.permittedAccess?.filter((v) => v !== x)
												)
											}
										/>
									))}
								</Grid>

								{/* Admin Rights */}
								<Grid item xs={12}>
									<InputLabel id="admin-rights-label" sx={{ p: 0 }}>
										Admin Rights
									</InputLabel>
									<CheckboxWithLabel
										checked={values.role === UserRoles.Admin}
										onChange={(_, checked) =>
											setFieldValue(
												'role',
												checked ? UserRoles.Admin : UserRoles.Member
											)
										}
										label={'Member can add other members'}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions
							sx={{
								justifyContent: { xs: 'center', md: 'flex-end' },
							}}
						>
							<Button
								variant="outlined"
								onClick={closeAndClearModal}
								sx={{ width: '183px' }}
								disabled={formLoading}
							>
								Close
							</Button>
							<LoadingButton
								loading={formLoading}
								type="submit"
								variant="contained"
								color="primary"
								sx={{ width: '183px' }}
							>
								Save User
							</LoadingButton>
						</DialogActions>
					</form>
				</Box>
			</>
		</Dialog>
	);
};

const ErrorText = ({
	touched,
	errors,
}: {
	touched?: boolean;
	errors?: string;
}) => {
	return touched && errors ? (
		<Box alignItems={'center'} display={'flex'} gap={1}>
			<Icon icon={'UilExclamationCircle'} />
			{errors}
		</Box>
	) : null;
};
