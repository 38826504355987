import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'src/helpers/authHelpers';
import { baseApi } from 'src/services/rtkQuery/baseApi';
import store from 'src/store/store';
import { IUserSlice, UserRoles } from 'src/store/user/userSlice.contracts';

export const refreshCurrentUser = createAsyncThunk(
	'user/refreshCurrentUser',
	async (params: {}, { rejectWithValue }) => {
		try {
			let user: IUserSlice = await store.getState().user;

			if (user.accessToken) {
				const currentUser = await store.dispatch(
					baseApi.endpoints.getCurrentUser.initiate(user?.accessToken, {
						forceRefetch: true,
					})
				);
				const { data } = currentUser;
				user = {
					...user,
					name: data?.data?.name ?? '',
					bcId: data?.data?.bcUniqueId as string,
					agent: {
						bcId: data?.data?.agentBcId ?? '',
						name: data?.data?.agentName ?? '',
						email: data?.data?.agentEmail ?? '',
						phone: data?.data?.agentPhone ?? '',
					},
					preferences: {
						crops: data?.data?.cropPreferences ?? [],
					},
					entities: data?.data?.userActiveEntities ?? [],
					access: data?.data?.access ?? [],
					tenant: data?.data?.userActiveEntities
						? data.data?.userActiveEntities.find(
								(x) => x.code === data?.data?.bcUniqueId
						  ) ?? null
						: null,
					userRole: data?.data?.userRole ?? UserRoles.Member,
					status: 'authenticated',
				};
			}

			return {
				...user,
			} as IUserSlice;
		} catch (error: any) {
			toast.error(getErrorMessage(error.code));
			return rejectWithValue(error.message);
		}
	}
);
