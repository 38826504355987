//Error codes can be found here: https://firebase.google.com/docs/auth/admin/errors
export const getErrorMessage = (errorCode: string, data?: any) => {
    switch (errorCode) {
        case 'auth/email-already-exists': return `User with email ${data} already exists`;
        case 'auth/email-already-in-use': return `User with email ${data} already exists`;
        case 'auth/wrong-password': return 'User name or password is incorrect';
        case 'auth/invalid-password': return 'Provided password is invalid';
        case 'auth/invalid-email': return `Provided email ${data} is not valid`;
        case 'auth/user-not-found': return `User with email ${data} does not exist`;
        case 'auth/too-many-requests': return 'We have blocked all requests from this device due to unusual activity. Try again later';
        case 'auth/invalid-continue-uri':
        case 'auth/unauthorized-continue-uri': return 'We have encountered a server error';
        case 'email-not-verified': return 'Email is not verified';
        case 'auth/user-disabled': return 'Your account has been disabled';
        default: return 'Something went wrong while trying to log in';
    }
}

export enum NetworkStatusEnums {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'authenticated',
    FAILED = 'error',
    REGISTERED = 'registered',
}