import {
  DialogActions,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import React, { useReducer } from 'react';
import {
  Button, NoNotificationsWrapper, NotificationPopoverWrapper
} from 'features';
import noNotifications from 'src/assets/img/noNotification.svg';

type Message = {
  id: number;
  message: string;
  isRead: boolean;
  createdAt: string;
};

type Action =
  | { type: 'MESSAGE_READ'; payload: number }
  | { type: 'MARK_ALL_AS_READ' }
  | { type: 'CLEAR_ALL_MESSAGES' };

type State = {
  messages: Message[];
};

interface NotificationModalProps {
  handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void;

  messages: Message[];
  anchorEl: HTMLElement | null;
}
const messagesReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MESSAGE_READ':
      return {
        ...state,
        messages: state.messages.map((message) =>
          message.id === action.payload ? { ...message, isRead: true } : message
        )
      };

    case 'MARK_ALL_AS_READ':
      return {
        ...state,
        messages: state.messages.map((message) => ({
          ...message,
          isRead: true
        }))
      };

    case 'CLEAR_ALL_MESSAGES':
      return {
        ...state,
        messages: []
      };

    default:
      return state;
  }
};

export const NotificationModal = ({
  handleClose,
  anchorEl,
  messages
}: NotificationModalProps) => {
  const theme = useTheme();
  const [state, dispatch] = useReducer(messagesReducer, {
    messages
  });
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleAllMessagesRead = () => {
    dispatch({ type: 'MARK_ALL_AS_READ' });
  };
  const handleClearAllMessages = () => {
    dispatch({ type: 'CLEAR_ALL_MESSAGES' });
  };

  const handleReadMessage = (id: number) => {
    dispatch({ type: 'MESSAGE_READ', payload: id });
  };

  return (
    <NotificationPopoverWrapper
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <DialogTitle
        variant="bodyBold"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        Notifications
        <Button
          aria-label="read all"
          size="small"
          variant="outlinedHover"
          color="secondary"
          endIcon={'UilCheckCircle'}
          onClick={handleAllMessagesRead}
        >
          Mark all as read
        </Button>
      </DialogTitle>

      <List sx={{ padding: '0px 16px' }}>
        {state.messages.map((message) => (
          <ListItem disablePadding key={message.id}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                width: '2px',
                borderRadius: '10px',
                left: '2px',
                mr: '2px',
                backgroundColor: message.isRead
                  ? 'transparent'
                  : theme.colors.primary.main
              }}
            />
            <ListItemText
              onClick={() => handleReadMessage(message.id)}
              sx={{
                padding: '8px 16px 16px 16px',
                backgroundColor: message.isRead
                  ? theme.colors.alpha.white[100]
                  : theme.colors.alpha.grey[1]
              }}
              primaryTypographyProps={{
                color: theme.colors.alpha.black[100],
                variant: 'bodySmall',
                pb: '16px'
              }}
              primary={message.message}
              secondaryTypographyProps={{
                variant: 'bodySmall',
                color: theme.colors.alpha.grey[3]
              }}
              secondary={message.createdAt}
            />
          </ListItem>
        ))}
        {state.messages.length > 0 && <Divider flexItem />}
        {state.messages.length === 0 && (
          <NoNotificationsWrapper>
            <img src={noNotifications} alt="empty" />
            <Typography variant="bodySmall">
              No notification for now fellow farmer
            </Typography>
          </NoNotificationsWrapper>
        )}
      </List>
      <DialogActions
        sx={{
          padding: '5px 16px 16px 16px',
          justifyContent: 'flex-start'
        }}
      >
        <Button
          size="small"
          variant="outlinedHover"
          color="secondary"
          aria-label="clear"
          disabled={state.messages.length === 0}
          onClick={handleClearAllMessages}
        >
          Clear all
        </Button>
      </DialogActions>
    </NotificationPopoverWrapper>
  );
};
