import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeAuthStorage } from 'src/services/firebase/setAuthStorage';
import { removeCartStorage } from 'src/store/cart/helpers/cartStorage';

export const resetUser = createAsyncThunk(
	'user/resetUser',
	async (_, { rejectWithValue }) => {
		try {
			removeAuthStorage();
			removeCartStorage();
			return true;
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);
