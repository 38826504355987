import { styled, Stack } from '@mui/material';
import { Button } from 'features';
import { NozzleColour, mapColour } from '../OrchardSprayForm/helpers/sprayCalculation';

const NozzleButton = styled(Button)(() => ({
    border: '7px solid black',
    borderRadius: '32px',
    width: '35px',
    height: '35px',
    minWidth: '0',
    padding: '0',
}));

interface NozzleColourInputProps {
    handleNozzleChange: (colour: NozzleColour) => void;
    selectedColour: NozzleColour;
}

const NozzleColourInput = ({handleNozzleChange, selectedColour}: NozzleColourInputProps) => {
    const nozzleColours = Object.values(NozzleColour).filter((c) => typeof c === 'number');
    return (
    <Stack direction='row' gap={1}>
        {nozzleColours.map((colour) => (
            <NozzleButton
                key={colour}
                size='small'
                sx={{
                    backgroundColor: `${mapColour(colour as NozzleColour)}`,
                    boxShadow: selectedColour == colour as NozzleColour ? '5px 10px #888888': 'none',
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: `${colour}`,
                    }
                }}
                onClick={() => handleNozzleChange(colour as NozzleColour)}/>)
        )}
    </Stack>)
}

export { NozzleColourInput };