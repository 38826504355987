import { Card, CardMedia, Popover, Stack } from '@mui/material';
import { useState } from 'react';
import { IconButton } from 'features';

interface ImagePopoverProps {
	url: string;
	borderRadius: string;
	maxWidth: string;
	maxHeight: string;
	fileName?: string;
	handleClearFile?: (fileName: string) => void;
	formDisabled?: boolean;
}

export const ImagePopover = ({
	url,
	borderRadius,
	maxWidth,
	maxHeight,
	fileName,
	handleClearFile: handleClearFile,
	formDisabled,
}: ImagePopoverProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<Stack direction='row' justifyContent={'center'} alignItems={'center'}>
			<Popover
				id={'mouse-over-popover'}
				sx={{
					pointerEvents: 'none',
					width: '80%',
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				disableRestoreFocus>
				<img src={url} style={{ width: '100%' }} />
			</Popover>
			<Card
				sx={{
					width: '100%',
					maxWidth: { maxWidth },
					maxHeight: { maxHeight },
					borderRadius: { borderRadius },
					position: 'relative',
				}}>
				{handleClearFile && fileName && (
					<IconButton
						icon='UilMultiply'
						size='small'
						aria-label='Clear image'
						disabled={formDisabled ?? false}
						onClick={() => handleClearFile(fileName)}
						sx={{
							position: 'absolute',
							right: 0,
						}}
					/>
				)}
				<CardMedia
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						width: '100%',
						height: 'auto',
						zIndex: '1',
					}}
					component='img'
					aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
					aria-haspopup='true'
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					src={url}></CardMedia>
			</Card>
		</Stack>
	);
};
