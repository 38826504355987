import { SearchQueryParams } from './queryParams.types';

export interface BcCandidate {
	firebaseUid: string;
	customers: Entity[];
	emailAddress: string;
	status: string;
	invited: boolean;
	role: string;
	isActive: boolean;
}

export enum UserRoles {
	Admin = 0,
	Member = 1,
}

export enum UserTitle {
	None = 0,
	Owner = 1,
	FarmManager = 2,
	TechnicalManager = 3,
	Storeman = 4,
	SprayOperator = 5,
	AdminClerk = 6,
	AccountsClerk = 7,
}

export enum Access {
	MyAccount = 0,
	MyFarm = 1,
	Products = 2,
}

export interface TeamMember {
	firebaseUid: string;
	name: string;
	emailAddress: string;
	userRole: UserRoles;
	cropPreferences: string[];
	userActiveEntities: Entity[];
	phoneNumber: string;
	title: UserTitle;
	access: Access[];
}

export interface Entity {
	id: string;
	code: string;
	groupCode: string;
	name: string;
	role: UserRoles;
	title: UserTitle;
	isDirect: boolean;
	agent?: string;
	region?: string;
}

export type InviteBcUser = {
	name: string;
	emailAddress: string;
	redirectUrl: string;
};

export type Customer = {
	no: string;
	name: string;
};

export interface UpdateUserBody {
	userId: string;
	name: string;
	phoneNumber: string;
	entityList: EntityRole[];
}

export interface EntityRole {
	entityId: string;
	role: UserRoles;
	access: Access[] | number[];
	title: UserTitle | number;
}

export interface UpdateUserAdminRights {
	firebaseId: string;
	admin: boolean;
}

export interface InviteUserRequestBody {
	name: string;
	email: string;
	redirectUrl: string;
	phoneNumber: string;
	entityList: EntityRole[];
}

export interface UsersQueryParams extends SearchQueryParams {
	showDeactivatedUsers: boolean;
	userId: string;
}
