import { IUserSlice } from 'src/store/user/userSlice.contracts';

const setAuthStorage = (userSlice: IUserSlice) => {
	localStorage.setItem(
		process.env.REACT_APP_APP_NAME ?? 'Intelekt-4U',
		JSON.stringify({
			status: userSlice.status,
			accessToken: userSlice.accessToken,
			refreshToken: userSlice.refreshToken,
			name: userSlice.name,
			bcId: userSlice.bcId,
			agent: userSlice.agent,
			preferences: userSlice.preferences,
			userRole: userSlice.userRole,
			entities: userSlice.entities,
			tenant: userSlice.tenant,
			access: userSlice.access,
			error: userSlice.error,
		})
	);
};

const getAuthStorage = () => {
	return localStorage.getItem(process.env.REACT_APP_APP_NAME ?? 'Intelekt-4U');
};

const removeAuthStorage = () => {
	return localStorage.removeItem(
		process.env.REACT_APP_APP_NAME ?? 'Intelekt-4U'
	);
};

export { setAuthStorage, getAuthStorage, removeAuthStorage };
