import {
	Typography,
	InputLabel,
	useTheme,
	SxProps,
	Theme,
} from '@mui/material';

type CustomSxProps = {
	mb?: number;
	textWrap?: string;
	accentColor?: string; // Add any additional properties needed for sx
	// Add other properties as needed
};

type customInputLabelPropTypes = {
	title: string;
	htmlFor: string;
	mb?: number;
	wrap?: string;
	required?: boolean;
};

export const CustomInputLabel = (props: customInputLabelPropTypes) => {
	const { title, htmlFor, required=false, mb = -0.6, wrap } = props;
	const theme = useTheme();

	let sx: SxProps<Theme> = {
		mb: mb,
		textWrap: 'wrap'
	};

	// if (wrap) sx = { ...sx, textWrap: wrap.toString() };
	// else sx = null; // Assign null if wrap is not provided

	return (
		<>
			<InputLabel htmlFor={htmlFor} required={required} sx={sx}>
				<Typography
					sx={{
						color: theme.colors.alpha.black[100],
						fontWeight: 700,
					}}>
					{title}
				</Typography>
			</InputLabel>
		</>
	);
};
