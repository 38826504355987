import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { Product } from './types/product.types';
import { ProductQueryParams } from './types/queryParams.types';

const myProductsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query<ApiResponse<Product[]>, ProductQueryParams>({
			query: (args) => {
				return {
					url: 'Product',
					params: args,
				};
			},
		}),

		getAllProducts: builder.query<ApiResponse<Product[]>, {}>({
			query: (args) => {
				return {
					url: 'Product/All',
					params: args,
				};
			},
		}),
	}),

	overrideExisting: false,
});

export const { useGetProductsQuery, useGetAllProductsQuery } = myProductsApi;
