import { CssBaseline } from '@mui/material';
import router from 'src/router/router';
import { ToastContainer, toast } from 'react-toastify';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
	useRoutes,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { SuspenseLoader, ThemeProviderWrapper, initMonitoring } from 'features';
import { useGetBcOnlineStatusQuery } from './services/rtkQuery/baseApi';
import { wrapUseRoutes } from '@sentry/react';
import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { useAuth } from './features/authentication';
import defineAbilityFor from './config/ability';
import { AbilityContext } from './contexts/CanContext';
import store from './store/store';
import { useAppSelector } from './hooks/hooks';
import { selectUser } from './store/user/userSlice';
import { UserRoles } from './store/user/userSlice.contracts';
import { SidebarProvider } from './contexts/SidebarContext';
import { sentryConfig } from './config/sentry';

initMonitoring(sentryConfig, 'njmz33pg1l');

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
	const content = useSentryRoutes(router);

	const {
		login,
		loading: userLoading,
		user: firebaseUser,
		getUser,
		logout,
	} = useAuth();

	const [loading, setLoading] = useState(userLoading);

	const { userRole, access } = store.getState().user;
	const ability = defineAbilityFor(userRole ?? UserRoles.Member, access);

	const userSlice = useAppSelector(selectUser);
	useEffect(() => {
		if (userSlice.userRole) {
			const { rules } = defineAbilityFor(userSlice.userRole, userSlice.access);
			ability.update(rules);
		}
	}, [userSlice]);

	useEffect(() => {
		const extractTokenAndSignIn = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const idToken = urlParams.get('idToken');

			if (idToken) {
				try {
					localStorage.setItem('idToken', decodeURIComponent(idToken));

					await login(decodeURIComponent(idToken));

					setLoading(false);
				} catch (error) {
					console.error('Error signing in with custom token:', error);
					toast.error('An error occured while trying to sign in');
				}
			} else {
				try {
					await getUser();
				} catch {
					setLoading(true);
					await logout();
				}
				setLoading(false);
			}
		};

		if (loading && !firebaseUser) extractTokenAndSignIn();
	}, []);

	useGetBcOnlineStatusQuery(null, {
		pollingInterval: 600000,
	});

	return loading && !firebaseUser ? (
		<ThemeProviderWrapper>
			<Suspense fallback={<SuspenseLoader />}>
				<SuspenseLoader />
			</Suspense>
		</ThemeProviderWrapper>
	) : (
		<AbilityContext.Provider value={ability}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
				<ThemeProviderWrapper>
					<CssBaseline />
					<ToastContainer
						hideProgressBar={true}
						theme={'light'}
						position="bottom-left"
					/>
					<SidebarProvider>{content}</SidebarProvider>
				</ThemeProviderWrapper>
			</LocalizationProvider>
		</AbilityContext.Provider>
	);
}

export default App;
