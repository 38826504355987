import { Box, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { LabelledTextInput } from 'features';

export enum SortTypes {
    DateNewestFirst = 'DateNewestFirst',
    DateOldestFirst = 'DateOldestFirst',
    Reference = 'Reference',
}

interface SearchSortBarProps {
    searchTitle: string;
    searchValue: string;
    sortValue: SortTypes;
    setSearchValue: (value: string) => void;
    setSortValue: (value: SortTypes) => void;
}

export const SearchSortBar = ({ searchTitle, searchValue, sortValue, setSearchValue, setSortValue }: SearchSortBarProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            width: '100%',
        }}>
            <LabelledTextInput
                title={searchTitle}
                width={isMobile ? '100%' : '68%'}
                placeholder="Search by reference ID etc."
                onChangeEvent={(e) => setSearchValue(e.target.value)}
                id='search'
                value={searchValue}
            />
            <LabelledTextInput
                title="Sort by"
                type="select"
                width={isMobile ? '100%' : '30%'}
                id="sort"
                placeholder="Date"
                value={sortValue}
                onChangeEvent={(event) => setSortValue(event.target.value)}
            >
                <MenuItem value={SortTypes.DateNewestFirst} key={'dateNewestFirst'}>
                    Date: Newest First
                </MenuItem>
                <MenuItem value={SortTypes.DateOldestFirst} key={'dateOldestFirst'}>
                    Date: Oldest First
                </MenuItem>
                <MenuItem value={SortTypes.Reference} key={'reference'}>
                    Reference
                </MenuItem>
            </LabelledTextInput>
        </Box>
    );
}
