import {
	Avatar,
	Box,
	Button,
	Chip,
	DialogActions,
	DialogTitle,
	Divider,
	Typography,
	styled,
	Dialog,
	DialogContent,
	Autocomplete,
	InputAdornment,
	Grid
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Icon } from 'features/src/atoms/Icon/Icon';
import { IconButton } from 'features/src/atoms/IconButton/IconButton';
import { CustomTextInput } from 'features/src/molecules/CustomTextInput/CustomTextInput';
import { useGetAllCropsQuery } from 'src/services/rtkQuery/cropApi';
import { IUserSlice } from 'src/store/user/userSlice.contracts';
import { CropCategory, CropName } from 'src/services/rtkQuery/types/crop.types';
import { useUpdateCropPreferencesMutation } from 'src/services/rtkQuery/userApi';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import store from 'src/store/store';
import { LoadingButton } from 'features';
import { toast } from 'react-toastify';
import { useAuth } from 'src/features/authentication';
import { storage } from 'src/services/firebase/firebase';
import { updateUserCrops } from 'src/features/authentication/utils/updateUserCrops';

export const PopoverWrapper = styled(Box)(({ theme }) => ({
	width: '500px',
	padding: '0px',
	display: 'flex',
	flexDirection: 'column',
}));

const arraysAreIdentical = (arr1: any[], arr2: any[]): boolean => {
	if (arr1.length !== arr2.length) return false;
	return arr1.every((element, index) => element === arr2[index]);
};

interface ProfileModalProps {
	handleClose: () => void;
	user: IUserSlice;
	open: boolean;
}

export const ProfileModal = ({
	handleClose,
	user,
	open,
}: ProfileModalProps) => {
	const [selectedCropCategories, setSelectedCropCategories] = useState<
		CropCategory[]
	>([]);
	const [availableCropCategories, setAvailableCropCategories] = useState<
		CropCategory[]
	>([]);
	const [avatarImage, setAvatarImage] = useState<string>(
		user.firebaseUser?.photoURL ?? ''
	);
	const [file, setFile] = useState<File | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [updateUser] = useUpdateCropPreferencesMutation();
	const userCropIds = user.preferences?.crops as string[];
	const { data, isLoading, isSuccess, isError } = useGetAllCropsQuery();

	let masterCrops: CropCategory[];
	if (isSuccess) {
		masterCrops = data.data ?? [];
	} else if (isLoading || isError) {
		masterCrops = [];
	}

	/**
	 * Compares the user's preferred crop ids with the master crop list,
	 * and returns a list of unique user crop categories.
	 */
	const getSelectedCropCategories = () => {
		const categories: CropCategory[] = [];
		userCropIds?.forEach((id) => {
			const item = masterCrops.find((m) => m.id === id);
			if (item) categories.push(item);
		});
		return categories;
	};

	useEffect(() => {
		if (data) {
			setAvailableCropCategories(masterCrops);
			if (userCropIds !== undefined && userCropIds.length > 0) {
				setSelectedCropCategories(getSelectedCropCategories());
			}
		}
	}, [data, userCropIds]);

	const handleCancel = () => {
		setSelectedCropCategories(getSelectedCropCategories());
		setAvatarImage(user.firebaseUser?.photoURL ?? '');
		setFile(null);
		handleClose();
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const selectedFile = e.target.files?.[0];
		if (selectedFile) {
			setFile(selectedFile);
			const imageUrl = URL.createObjectURL(selectedFile);
			setAvatarImage(imageUrl);
		}
	};

	const { user: currentUser } = useAuth();

	const handleSave = async () => {
		setIsSubmitting(true);
		const cropIds = selectedCropCategories.map((c) => c.id);
		try {
			if (!arraysAreIdentical(cropIds, userCropIds as string[])) {
				// update user on BE:
				await updateUser({ cropPreferences: cropIds });
			}
			if (file) {
				const storageRef = ref(
					storage,
					`Users/${user.firebaseUser?.uid}/ProfilePic.png`
				);
				// upload image to firebase storage:
				await uploadBytes(storageRef, file);
				const url = await getDownloadURL(storageRef);
				if (currentUser) {
					// update profileURL on firebase user:
					await updateProfile(currentUser, { photoURL: url });
				}
			}
			// update user in redux global state
			await store.dispatch(updateUserCrops({ crops: cropIds }));
			toast.success('Profile saved');
		} catch (error: any) {
			toast.error(error);
		} finally {
			setIsSubmitting(false);
			handleClose();
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			onClose={handleCancel}
			open={open}
		>
			<DialogTitle
				display="flex"
				flexDirection="row"
				alignItems="center"
				gap="12px"
				sx={{
					justifyContent: 'space-between',
					padding: '26px 26px 14px 26px',
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap="12px"
				>
					<Icon icon="UilEditAlt" />
					<Typography fontWeight={700} fontSize="24px">
						Edit Profile
					</Typography>
				</Box>
				<IconButton
					icon="UilMultiply"
					onClick={handleCancel}
					sx={{ alignSelf: 'flex-end' }}
				/>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
					<Grid item xs={12}>
						<Divider sx={{ height: '2px'}}/>
					</Grid>
					<Grid item xs={12}>
						<Typography fontWeight={700} fontSize="20px">
							My Profile
						</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '6px',
								paddingBottom: '20px',
							}}
						>
							<Typography fontWeight={700} fontSize="14px">
								Name:
							</Typography>
							<Typography fontWeight={400} fontSize="16px">
								{user.name}
							</Typography>
						</Box>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
						>
							<Typography fontWeight={700} fontSize="14px">
								Email Address:
							</Typography>
							<Typography fontWeight={400} fontSize="16px">
								{user.firebaseUser?.email}
							</Typography>
						</Box>
					</Grid>
					<Grid item sx={{ mt: { xs: '10px', md: '0px' } }} xs={12} sm={4}>
						<Box
							sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}
						>
							<Avatar
								sx={{ width: '130px', height: '130px', right: -40 }}
								alt="Avatar"
								src={avatarImage ?? user.firebaseUser?.photoURL}
							/>
							<input
								accept="image/*"
								style={{ display: 'none' }}
								id="avatar-input"
								type="file"
								onChange={handleFileChange}
							/>
							<IconButton
								color="inherit"
								icon="UilEditAlt"
								onClick={() => document.getElementById('avatar-input')?.click()}
								sx={{
									bottom: -10,
									right: 0,
									position: 'relative',
									bgcolor: (theme) => theme.palette.primary.main,
									color: 'white',
									'&:hover': {
										color: 'white',
										bgcolor: (theme) => theme.palette.primary.dark,
									},
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Divider
							sx={{ height: '2px', marginTop: '10px' }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography fontWeight={700} fontSize="20px" paddingBottom="20px">
							My Crop Preferences
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography fontWeight={700} fontSize="16px" paddingBottom="14px">
							Choose Crop Name
						</Typography>
						<Autocomplete
							disablePortal
							clearOnBlur
							selectOnFocus
							color="primary"
							getOptionLabel={(option) => option.name ?? ''}
							noOptionsText="No crops found"
							options={availableCropCategories ?? []}
							sx={{
								'& .MuiAutocomplete-tag': {
									backgroundColor: (theme) => theme.palette.primary.main,
									color: 'white',
									' & .MuiChip-deleteIcon': {
										color: 'white',
									},
								},
							}}
							onChange={(event, newValue, reason) => {
								event.preventDefault();
								if (newValue) {
									if (
										typeof selectedCropCategories !== 'undefined' &&
										selectedCropCategories.some(
											(crop) => crop.id === newValue?.id
										)
									) {
										return;
									} else {
										typeof selectedCropCategories !== 'undefined' &&
											setSelectedCropCategories(
												selectedCropCategories.concat(newValue)
											);
									}
								}
							}}
							onClose={(event, reason) => {
								event.preventDefault();
							}}
							renderInput={(params) => {
								return (
									<CustomTextInput
										{...params}
										placeholder="Search for other categories"
										InputProps={{
											...params.InputProps,
											notched: false,
											startAdornment: (
												<InputAdornment position="start">
													<Icon icon="UilSearch" />
												</InputAdornment>
											),
										}}
									/>
								);
							}}
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '12px',
								paddingTop: '16px',
								flexWrap: 'wrap',
							}}
						>
							{typeof selectedCropCategories === 'undefined' ||
								typeof selectedCropCategories?.[0] === 'undefined'
								? null
								: selectedCropCategories.map((crop) => {
									return (
										<Chip
											key={crop.id}
											label={crop.name}
											onDelete={() => {
												setSelectedCropCategories(
													selectedCropCategories.filter((c) => c.id !== crop.id)
												);
												const deletedCategoryCropNames: CropName[] | undefined =
													masterCrops.length > 0
														? masterCrops.find((c) => c.id === crop.id)
															?.cropNames
														: [];
											}}
											sx={{
												backgroundColor: (theme) => theme.palette.primary.main,
												color: 'white',
												'& .MuiChip-deleteIcon': {
													color: 'white',
													'&:hover': {
														color: 'white',
													},
												},
											}}
										/>
									);
								})}
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					variant="outlined"
					color="secondary"
					fullWidth
					onClick={handleCancel}
					sx={{ width: '183px' }}
				>
					Cancel
				</Button>
				<LoadingButton
					loading={isSubmitting}
					variant="contained"
					endIcon="UilSave"
					fullWidth
					onClick={handleSave}
					sx={{ width: '183px' }}
				>
					Save Profile
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
