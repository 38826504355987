import {
	Autocomplete,
	AutocompleteProps,
	Box,
	Checkbox,
	Chip,
	createFilterOptions,
	Divider,
	FilterOptionsState,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Paper,
	Stack,
	TextField,
	useTheme,
} from '@mui/material';
import { CustomInputLabel } from '../InputLabel/CustomInputLabel';
import { CustomTextInput } from '../CustomTextInput/CustomTextInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

interface CustomAutoCompleteProps<T> {
	label?: string;
	noOptionsText?: string;
	data: T[];
	value?: T | T[];
	getOptionLabel: (option: T) => string;
	getTagTextLabel?: (option: T) => string;
	onChange: (
		event: React.SyntheticEvent<Element, Event>,
		value: T | T[] | null
	) => void;
	onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
	searchPlaceholder?: string;
	size?: 'small' | 'medium';
	disabled?: boolean;
	multiple?: boolean;
	selectAllOption?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	errorMessage?: string; 
}

export const CustomAutoComplete = <T extends unknown>({
	label,
	noOptionsText,
	data,
	value,
	getOptionLabel,
	getTagTextLabel,
	onChange,
	onOpen,
	searchPlaceholder,
	size,
	disabled = false,
	multiple,
	selectAllOption = false,
	fullWidth = false,
	error = false,
	errorMessage,
}: CustomAutoCompleteProps<T>) => {
	const [selectAll, setSelectAll] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const filter = createFilterOptions<T>();

	const theme = useTheme();

	const handleToggleSelectAll = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setSelectAll((state) => !state);
		const filteredOptions = filter(data, { inputValue, getOptionLabel });
		if (checked) onChange(event, [...(value as T[]), ...filteredOptions]);
		else
			onChange(event, [
				...(value as T[]).filter(
					(x) =>
						!filteredOptions.some(
							(f) => JSON.stringify(f) === JSON.stringify(x)
						)
				),
			]);
	};

	useEffect(() => {
		const filtered = filter(data, { inputValue, getOptionLabel });
		const isAllSelected =
			!!value &&
			!!(value as T[]).length &&
			filtered.every((x) =>
				(value as T[]).some((v) => JSON.stringify(x) === JSON.stringify(v))
			);

		setSelectAll(isAllSelected);
	}, [inputValue, value]);

	const multipleProps: Partial<
		AutocompleteProps<T, boolean, false, false, typeof Chip>
	> =
		multiple ?? false
			? {
				multiple: true,
				renderOption: (props, option, { selected, index }) => (
					<li {...props} key={`option-${index}`}>
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
							checkedIcon={<CheckBoxIcon fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						{getOptionLabel(option)}
					</li>
				),
				renderTags: (value: readonly T[], getTagProps) =>
					value.map((option: T, index: number) => {
						const label = getTagTextLabel
							? getTagTextLabel(option)
							: getOptionLabel(option);
						return (
							<Chip
								variant="outlined"
								label={label}
								{...getTagProps({ index })}
								key={`option-${index}`}
							/>
						);
					}),
				defaultValue: value,
				renderInput: (params) => (
					<TextField
						{...params}
						label={label}
						placeholder={searchPlaceholder}
					/>
				),
				disableCloseOnSelect: true,
				sx: {
					'& .MuiFormControl-root, .MuiTextField-root, .MuiInputBase-root, div:first-of-type:not(.MuiChip-root)':
					{
						height: '100%',
					},
					'& .MuiInputBase-root.MuiInputBase-root': {
						paddingLeft: 2,
					},
					'& .MuiAutocomplete-tag': {
						backgroundColor: (theme) => theme.palette.primary.main,
						color: 'white',
						'& .MuiChip-deleteIcon': {
							color: 'white',
						},
					},
				},
			}
			: {};

	const selectAllProps: Partial<
		AutocompleteProps<T, boolean, false, false, typeof Chip>
	> = selectAllOption
			? {
				value,
				PaperComponent: (paperProps) => {
					const { children, ...restPaperProps } = paperProps;

					return (
						<Paper {...restPaperProps}>
							<Box onMouseDown={(e) => e.preventDefault()} pl={2}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selectAll}
									onChange={handleToggleSelectAll}
								/>
								Select All
							</Box>
							<Divider />
							{children}
						</Paper>
					);
				},
				onInput: (e: React.ChangeEvent<HTMLInputElement>) =>
					setInputValue(e.target.value),
				filterOptions: (options: T[]) =>
					filter(options, { inputValue, getOptionLabel }),
				onClose: () => setInputValue(''),
				sx: {
					...multipleProps.sx,
					'& .MuiAutocomplete-endAdornment': {
						display: 'flex',
					},
				},
			}
			: {};

	return (
		<Stack
			direction={'column'}
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			{label && (
				<FormLabel sx={{ fontWeight: 'bold', color: theme.colors.alpha.black[100], mb: 0.5 }}>
					{label}
				</FormLabel>
			)}
			<Autocomplete
				id="autoComplete"
				disablePortal
				disabled={disabled}
				clearOnBlur
				selectOnFocus
				color="primary"
				getOptionLabel={getOptionLabel}
				noOptionsText={noOptionsText ?? 'No records found'}
				options={data ?? []}
				value={value}
				multiple={multiple ?? false}
				size={size ?? 'medium'}
				sx={{
					height: '100%',
				}}
				onChange={onChange}
				onClose={(event) => {
					event.preventDefault();
				}}
				aria-errormessage=''
				onOpen={onOpen}
				fullWidth={fullWidth}
				renderInput={(params) => {
					return (
						<CustomTextInput
							{...params}
							placeholder={searchPlaceholder}
							error={error}
							helperText={error && errorMessage}
							InputProps={{
								...params.InputProps,
								notched: false,
								startAdornment: (
									<InputAdornment position="start">
										<SearchOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					);
				}}
				{...multipleProps}
				{...selectAllProps}
			/>
		</Stack>
	);
};
