enum SprayCalculationTypes {
    'BOOMARABLE'=0,
    'ORCHARD'=1,
    'WEED'=2,
}

const getSprayTypeString = (sprayType?: SprayCalculationTypes | null, shortType= false) => {
    switch (sprayType) {
        case SprayCalculationTypes.BOOMARABLE: {
            return (shortType ? 'Boom/Arable' : 'Boom/Arable Spray')
        }
        case SprayCalculationTypes.ORCHARD: {
            return (shortType ? 'Orchard' : 'Orchard Spray')
        }
        case SprayCalculationTypes.WEED: {
            return (shortType ? 'Weed' : 'Weed Spray')
        }
        default: {
            return ('-')
        }
    }
}

export {
    SprayCalculationTypes,
    getSprayTypeString
};