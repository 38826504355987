import {
	useTheme,
	ListItemButton,
	ListItemText,
	Typography,
	lighten,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { refreshCurrentUser } from 'src/features/authentication/utils/refreshCurrentUser';
import { updateTenant } from 'src/features/authentication/utils/updateTenant';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { fieldAssessmentApi } from 'src/services/rtkQuery/fieldAssessmentApi';
import { myAccountApi } from 'src/services/rtkQuery/myAccountApi';
import { myAccountRequestApi } from 'src/services/rtkQuery/myAccountRequestApi';
import { myFarmApi } from 'src/services/rtkQuery/myFarmApi';
import { recommendationsApi } from 'src/services/rtkQuery/recommendationsApi';
import { userApi } from 'src/services/rtkQuery/userApi';
import { selectUser } from 'src/store/user/userSlice';

export default function TenantSelect() {
	const userSlice = useAppSelector(selectUser);
	const dispatch = useAppDispatch();

	const theme = useTheme();

	const handleChange = async (event: SelectChangeEvent) => {
		await dispatch(
			updateTenant({
				tenant: userSlice.entities.find(
					(x) => x.name === (event.target.value as string)
				),
			})
		);

		//Invalidate cache to force refetch with new tenant id
		await dispatch(myAccountApi.util.resetApiState());
		await dispatch(fieldAssessmentApi.util.resetApiState());
		await dispatch(myAccountRequestApi.util.resetApiState());
		await dispatch(myFarmApi.util.resetApiState());
		await dispatch(recommendationsApi.util.resetApiState());
		await dispatch(userApi.util.resetApiState());
	};

	interface TenantDisplayProps {
		tenant: string;
	}

	const TenantDisplay = ({ tenant }: TenantDisplayProps) => {
		const theme = useTheme();
		return (
			<Box>
				<Typography
					variant="body1"
					fontWeight={700}
					color={theme.colors.alpha.white[100]}
				>
					{tenant}
				</Typography>
			</Box>
		);
	};

	return (
		<Box
			sx={{
				width: '100%',
				margin: 'auto',
				mt: 2,
			}}
		>
			<FormControl fullWidth>
				<Select
					sx={{
						'& .MuiSvgIcon-root': {
							color: theme.colors.alpha.white[100],
						},
					}}
					value={userSlice?.tenant?.name ?? ''}
					onChange={handleChange}
					renderValue={(p: string) => <TenantDisplay tenant={p} />}
					color="secondary"
					size="small"
					MenuProps={{
						PaperProps: {
							sx: {
								'& .MuiMenu-list': {
									padding: '2px',
									gap: '2px',
									display: 'flex',
									flexDirection: 'column',
									'& .MuiDivider-root': {
										marginTop: '0px',
										marginBottom: '0px',
									},
								},
								'& .MuiMenuItem-root': {
									padding: '0px',
									borderRadius: (theme) => theme.general.borderRadius,
								},
								'& .MuiMenuItem-root.Mui-selected': {
									backgroundColor: 'transparent',
								},
								'& .MuiMenuItem-root:hover': {
									backgroundColor: 'transparent',
								},
								'& .MuiMenuItem-root.Mui-selected:hover': {
									backgroundColor: 'transparent',
								},
							},
						},
					}}
				>
					{userSlice.entities.map((item) => (
						<MenuItem
							key={`cutomer-${item.code}`}
							value={item.name ?? ''}
							disableRipple
						>
							<ListItemButton selected={userSlice?.tenant?.code === item.code}>
								<ListItemText
									color={theme.colors.alpha.white[100]}
									primary={`${item.name} (${item.code})`}
								/>
							</ListItemButton>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
}
