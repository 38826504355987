import { FC, useState, createContext } from 'react';
import {
	SidebarTitleProps,
	SidebarTitles,
} from 'src/components/templates/Sidebar/SidebarTitles';
import { useMediaQuery, useTheme } from '@mui/material';

type SidebarContext = {
	open: boolean;
	toggleSidebar: () => void;
	closeSidebar: () => void;
	titles: SidebarTitleProps[];
	openTitleCollapse: (title: string) => void;
	resetNavItems: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
	{} as SidebarContext
);

interface ISidebarProvider {
	children: any;
}

export const SidebarProvider: FC<ISidebarProvider> = ({ children }: any) => {
	const theme = useTheme();
	const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [open, setOpen] = useState(isDesktopScreen);

	const [titles, setTitles] = useState<SidebarTitleProps[]>(SidebarTitles);

	const toggleSidebar = () => {
		setOpen((state) => !state);
	};
	
	const closeSidebar = () => {
		setOpen(false);
	};

	const openTitleCollapse = (title: string) => {
		const updatedTitles = titles?.map((t) => {
			if (t.title === title) {
				return {
					...t,
					open: !t.open,
				};
			}
			return {
				...t,
				open: false
			};
		});

		setTitles(updatedTitles);
	};

	// Reset nav to original state
	const resetNavItems = () => setTitles(SidebarTitles);

	return (
		<SidebarContext.Provider
			value={{
				open,
				toggleSidebar,
				closeSidebar,
				titles,
				openTitleCollapse,
				resetNavItems,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
