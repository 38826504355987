import {
  RadioGroup as MuiRadioGroup,
  Radio as MuiRadio,
  RadioGroupProps as MuiRadioGroupProps,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  useTheme,
  styled,
  FormHelperText
} from '@mui/material';

type iOptions = {
  label: string;
  value: string;
};

export interface RadioGroupProps extends MuiRadioGroupProps {
  options?: iOptions[];
  disabled?: boolean;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  formLabel?: string;
  helperText?: string;
}

export const RadioGroup = ({
  options,
  disabled,
  labelPlacement,
  formLabel,
  helperText,
  ...rest
}: RadioGroupProps) => {
  const theme = useTheme();
  return (
    <>
      <FormLabel
        sx={{
          ...theme.typography.bodyBold,
          color: theme.colors.alpha.black[100]
        }}
        component="legend"
      >
        {formLabel}
      </FormLabel>
      <MuiRadioGroup {...rest}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<MuiRadio />}
            label={option.label}
            disabled={disabled}
            labelPlacement={labelPlacement}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};
