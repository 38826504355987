import { FC, ReactNode, useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { Sidebar } from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { ScreenWrapper } from '../Sidebar/Sidebar.styles';
import { Outlet } from 'react-router-dom';
import { SidebarContext, SidebarProvider } from 'src/contexts/SidebarContext';

interface SidebarLayoutProps {
	children?: ReactNode;
}

const BaseLayout: FC<SidebarLayoutProps> = ({ children }) => {
	const theme = useTheme();
	const sidebarContext = useContext(SidebarContext);

	const drawerWidth = sidebarContext.open
		? theme.sidebar.width
		: `calc(${theme.spacing(7)} + 1px)`;

	return (
		<ScreenWrapper>
			<Header />
			<Sidebar />
			<Box
				sx={{
					position: 'relative',
					zIndex: 5,
					flex: 1,
					pt: `${theme.header.height}`,
					width: { xs: '100%', md: `calc(100% - ${drawerWidth})`, },
					ml: { xs: 0, md: `${drawerWidth}`},
					transition: theme.transitions.create('margin-left', {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.enteringScreen,
					}),
				}}
				display="block"
			>
				{children || <Outlet />}
			</Box>
		</ScreenWrapper>
	);
};

export default BaseLayout;
