export const getReadableEnum = <T>(type: T, value: string) =>
	`${type[value as keyof T]}`.replace(/([A-Z])/g, ' $1').trim();

export type EnumDictionary<T extends string | symbol | number, U> = {
	[K in T]: U;
};

export const getEnumKeys = <
	T extends string | number,
	TEnumValue extends string | number
>(enumVariable: { [key in T]: TEnumValue }) => {
	return (Object.keys(enumVariable) as Array<T>).filter(
		(x) => !isNaN(Number(x))
	);
};
