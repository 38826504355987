import { DialogTitle, DialogContent, IconButton, Modal, styled, Box, Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import { UilMultiply } from '@iconscout/react-unicons';
import { NozzleLookupTable } from '../NozzleSelectorModal/Helpers/NozzleLookupTable';
import { NozzleColour, mapColour } from '../OrchardSprayForm/helpers/sprayCalculation';

const ModalWrapper = styled(Box)(
    ({ theme }) => `
          display: flex;
          flex-direction: column;
          gap: 24px;
          background-color: ${theme.colors.alpha.white[100]};
          width: 1028px;
          border-radius: 30px;
          padding: 40px;
          justify-content: center;
          align-items: center;
      `
  );

const BorderedTableCell = styled(TableCell)`
    border: 1px solid black;
    color: black;
    font-size: 12px;
    padding: 0 5px;
`;

const mapNozzleLookupTable = () =>  NozzleLookupTable.map((delivery) => {
        return [delivery.nozzleColour, ...delivery.deliveries]
    });

type NozzleLegendModalProps = {
    modalState: boolean;
    closeModal: () => void;
}

const NozzleLegendModal = ({modalState, closeModal}: NozzleLegendModalProps) => {
    const rows = mapNozzleLookupTable();
    return (
        <Modal 
            open={modalState} 
            onClose={closeModal}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
        >
            <ModalWrapper>
                <DialogTitle 
                    variant="h6Bold"
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        p: 0,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        Nozzle Selector
                        <IconButton
                            sx={{ alignSelf: 'flex-end' }}
                            aria-label="close"
                            onClick={closeModal}
                        >
                            <UilMultiply />
                        </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        m: 0,
                        p: '0.5px',
                        width: '100%',
                    }}>
                    <Table size="small" >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: 'white',
                                }}>
                                <BorderedTableCell>Delivery(L/min)</BorderedTableCell>
                                <BorderedTableCell 
                                    colSpan={21}
                                    align='center'>
                                        Litre per minute
                                </BorderedTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length && rows.map((row) => {
                                return (
                                    <TableRow 
                                        key={row[0]}
                                        sx={{
                                            height: '15px'
                                        }}>
                                        {row.length && row.map((e, i) => (
                                            i === 0 ? 
                                            <BorderedTableCell 
                                                key={e}
                                                sx={{
                                                    backgroundColor: mapColour(e),
                                                    color: e === NozzleColour.Black ? 'white': '',
                                                }}>
                                                    {NozzleColour[e].toString()}
                                            </BorderedTableCell>:
                                            <BorderedTableCell 
                                                key={e}
                                                sx={{
                                                    width: '5%',
                                                    padding: 0.5,
                                                    textAlign: 'right',
                                                }}>
                                                {e}
                                            </BorderedTableCell>))
                                        }
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
            </ModalWrapper>        
        </Modal>
)}

export { NozzleLegendModal }