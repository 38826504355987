import { UilMinus, UilPlus } from '@iconscout/react-unicons';
import { InputBase, Paper, useTheme } from '@mui/material'
import { useState } from 'react';
import { Button } from 'features';
import '../../../theme/base'

export interface CustomNumberInputProps {
    intialValue: number | null;
    max?: number;
    handleStateChange: (value: number | null) => void;
    size?: 	'small' | 'medium' | 'large';
}

const CustomNumberInput = ({ intialValue, max, handleStateChange, size }: CustomNumberInputProps) => {
    const [value, setValue] = useState(intialValue);
    const theme = useTheme();

    const handleValueIncrement = () => {
        let valueToUpdate = value ?? 0;
        if (max && valueToUpdate < max) {
            valueToUpdate++;
        } else if (max && valueToUpdate === max) {
            valueToUpdate = max;
        }
        else {
            valueToUpdate++;
        }

        setValue(valueToUpdate);
        handleStateChange(valueToUpdate);
    }

    const handleValueDecrement = () => {
        let valueToUpdate = value ?? 0;
        if (valueToUpdate > 0) {
            valueToUpdate--;
        } 
        else {
            valueToUpdate = 0;
        }

        setValue(valueToUpdate);
        handleStateChange(valueToUpdate);
    }

    const handleInputChange = (e: any) => {
        const inputValue = e.target.value;
        if (!isNaN(inputValue)) {
            let valueToUpdate = Number(e.target.value);
            if (max && valueToUpdate >= max) {
                valueToUpdate = max;
            }
            setValue(valueToUpdate);
            handleStateChange(valueToUpdate);
        } else if (inputValue === '') {
            setValue(null);
            handleStateChange(null);
        }
    }

    return (
        <Paper
            component="form"
            sx={{ 
                display: 'flex',
                alignItems: 'center',
                mt: 2,
                borderRadius: '15px',
                border: `1px solid ${theme.colors.primary.main}` }}
            >
            <Button 
                variant='contained'
                onClick={handleValueDecrement}
                sx={{
                    borderRadius: '10px 0px 0px 10px'
                }}
                size={size ?? 'medium'}>
                <UilMinus />
            </Button>
            <InputBase
                sx={{ flex: 1, }}
                value={value ?? ''}
                onChange={handleInputChange}
                inputProps={{
                    sx:{
                        textAlign: 'center',
                    }
                }}
            />
            <Button 
                variant='contained'
                onClick={handleValueIncrement}
                sx={{
                    borderRadius: '0px 10px 10px 0px'
                }}
                size={size ?? 'medium'}>
                <UilPlus />
            </Button>
        </Paper>
    )
}

export { CustomNumberInput };