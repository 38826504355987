import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IUserSlice, UserRoles } from './userSlice.contracts';
import { resetUser } from 'src/features/authentication/utils/resetUser';
import { persistAuth } from 'src/features/authentication/utils/persistAuth';
import { setAuthStorage } from 'src/services/firebase/setAuthStorage';
import { updateUserCrops } from 'src/features/authentication/utils/updateUserCrops';
import { updateTenant } from 'src/features/authentication/utils/updateTenant';
import { refreshCurrentUser } from 'src/features/authentication/utils/refreshCurrentUser';

const initialState: IUserSlice = {
	name: null,
	firebaseUser: null,
	bcId: null,
	agent: null,
	preferences: null,
	status: 'idle',
	accessToken: null,
	refreshToken: null,
	userRole: UserRoles.Member,
	entities: [],
	access: [],
	tenant: null,
	error: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		/**
		 * Dispatch resetUser to reset the redux store. Handy when clearing user sessions when logging out.
		 */
		resetUser(state) {
			state = initialState;
		},
	},
	extraReducers(builder) {
		builder
			//########################### Persist Auth ###########################
			.addCase(persistAuth.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(persistAuth.fulfilled, (state, action) => {
				state.status = action.payload.status;
				state.accessToken = action.payload.accessToken;
				state.refreshToken = action.payload.refreshToken;
				state.name = action.payload.name;
				state.firebaseUser = action.payload.firebaseUser;
				state.bcId = action.payload.bcId;
				state.agent = action.payload.agent;
				state.preferences = action.payload.preferences;
				state.userRole = action.payload.userRole;
				state.entities = action.payload.entities;
				state.tenant = action.payload.tenant;
				state.access = action.payload.access;
				state.error = action.payload.error;
				setAuthStorage(state);
			})
			.addCase(persistAuth.rejected, (state, action) => {
				state.status = 'error';
				state.name = null;
				state.firebaseUser = null;
				state.bcId = null;
				state.agent = null;
				state.preferences = null;
				state.accessToken = null;
				state.refreshToken = null;
				state.userRole = null;
				state.entities = [];
				state.access = [];
				state.tenant = null;
				state.error = action.error.message;
				setAuthStorage(state);
			})
			//########################### Reset User ###########################
			.addCase(resetUser.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(resetUser.fulfilled, (state, action) => {
				state.name = null;
				state.firebaseUser = null;
				state.bcId = null;
				state.agent = null;
				state.preferences = null;
				state.status = 'idle';
				state.accessToken = null;
				state.refreshToken = null;
				state.userRole = null;
				state.entities = [];
				state.access = [];
				state.tenant = null;
				state.error = null;
				setAuthStorage(state);
			})
			.addCase(resetUser.rejected, (state, action) => {
				state.status = 'error';
				state.name = null;
				state.firebaseUser = null;
				state.bcId = null;
				state.agent = null;
				state.preferences = null;
				state.accessToken = null;
				state.refreshToken = null;
				state.userRole = null;
				state.entities = [];
				state.access = [];
				state.tenant = null;
				state.error = action.error.message;
				setAuthStorage(state);
			})
			//########################### Refresh Current User ###########################
			.addCase(refreshCurrentUser.fulfilled, (state, action) => {
				state.status = action.payload.status;
				state.accessToken = action.payload.accessToken;
				state.refreshToken = action.payload.refreshToken;
				state.name = action.payload.name;
				state.firebaseUser = action.payload.firebaseUser;
				state.bcId = action.payload.bcId;
				state.agent = action.payload.agent;
				state.preferences = action.payload.preferences;
				state.userRole = action.payload.userRole;
				state.entities = action.payload.entities;
				state.tenant = action.payload.tenant;
				state.access = action.payload.access;
				state.error = action.payload.error;
				setAuthStorage(state);
			})
			.addCase(refreshCurrentUser.rejected, (state, action) => {
				state.status = 'error';
				state.name = null;
				state.firebaseUser = null;
				state.bcId = null;
				state.agent = null;
				state.preferences = null;
				state.accessToken = null;
				state.refreshToken = null;
				state.userRole = null;
				state.entities = [];
				state.access = [];
				state.tenant = null;
				state.error = action.error.message;
				setAuthStorage(state);
			})
			//########################### Update User Crops ###########################
			.addCase(updateUserCrops.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(updateUserCrops.fulfilled, (state, action) => {
				state.status = action.payload.status;
				state.preferences = action.payload.preferences;
				setAuthStorage(state);
			})
			//########################### Update User Tenant ###########################
			.addCase(updateTenant.fulfilled, (state, action) => {
				state.tenant = action.payload.tenant;
				setAuthStorage(state);
			})
			.addCase(updateTenant.rejected, (state, action) => {
				state.tenant = null;
				setAuthStorage(state);
			});
	},
});

export default userSlice.reducer;

export const selectUser = (state: RootState): typeof initialState => state.user;
