import {
	RecommendationExtended,
	RecommendationQuoteExtended,
	ApplicationInstruction,
} from 'features';
import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { QueryParams } from './types/queryParams.types';
import { Recommendation } from './types/recommendation.types';

export const recommendationsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getRecommendations: builder.query<
			ApiResponse<Recommendation[]>,
			QueryParams
		>({
			query: (args) => {
				return {
					url: 'Recommendation',
					params: args,
				};
			},
			providesTags: ['Recommendations'],
		}),

		getRecommendationExtendedById: builder.query<
			ApiResponse<RecommendationExtended>,
			string
		>({
			query: (args) => {
				return {
					url: `Recommendation/${args}/Extended`,
				};
			},
			providesTags: (result) => [
				{ type: 'Recommendation', id: result?.data?.recommendation.id },
			],
		}),

		getApplicationInstructions: builder.query<
			ApiResponse<ApplicationInstruction>,
			string
		>({
			query: (id) => {
				return {
					url: `Recommendation/${id}/ApplicationInstruction`,
				};
			},
			providesTags: (result) => [
				{
					type: 'ApplicationInstructions',
					id: result?.data?.recommendation.id,
				},
			],
		}),

		getRecommendationQuoteExtendedById: builder.query<
			ApiResponse<RecommendationQuoteExtended>,
			string
		>({
			query: (args) => {
				return {
					url: `Recommendation/${args}/RecommendationQuoteExtended`,
				};
			},
			providesTags: (result) => [
				{ type: 'RecommendationQuote', id: result?.data?.recommendation.id },
			],
		}),
	}),

	overrideExisting: false,
});

export const {
	useGetRecommendationsQuery,
	useLazyGetRecommendationExtendedByIdQuery,
	useLazyGetApplicationInstructionsQuery,
	useLazyGetRecommendationQuoteExtendedByIdQuery,
} = recommendationsApi;
