import {
	Action,
	Dispatch,
	Middleware,
	MiddlewareAPI,
	isAnyOf,
	isRejectedWithValue,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { baseApi } from 'src/services/rtkQuery/baseApi';

export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) =>
	(next: Dispatch<any>) =>
	<A extends Action>(action: A) => {
		if (isRejectedWithValue(action)) {
			if (showToast(action)) {
				toast.error(
					'An error has occurred and your request could not be processed, please click "Ask a question" to contact the support team if the issue persists'
				);
			}
		}

		return next(action);
	};

const showToast = <A extends Action>(action: A): boolean =>
	!baseApi.endpoints.getBcOnlineStatus.matchRejected(action) &&
	!baseApi.endpoints.getCurrentUser.matchRejected(action);
