import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import themeSlice from './theme/themeSlice';
import userSlice from './user/userSlice';
import { baseApi } from 'src/services/rtkQuery/baseApi';
import cartSlice from './cart/cartSlice';
import { rtkQueryErrorLogger } from 'src/middleware/rtkQueryErrorLogger';

const combinedReducer = combineReducers({
	theme: themeSlice,
	user: userSlice,
	cart: cartSlice,
	[baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === 'user/resetUser') {
		// Reset the Redux store
		state = undefined;
	}
	return combinedReducer(state, action);
};

const store = configureStore({
	devTools: process.env.REACT_APP_DEPLOYMENT_ENV !== 'production',
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat(baseApi.middleware)
			.concat(rtkQueryErrorLogger),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
