import { UilExclamationTriangle, UilSave } from '@iconscout/react-unicons';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { Button, CustomModal, CustomBackdrop } from 'features';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BoomArableSprayForm from 'src/components/organisms/BoomArableSprayForm/BoomArableSprayForm';
import {
	IBoomSprayCalcData,
	boomFormValues,
} from 'src/components/organisms/BoomArableSprayForm/helpers/sprayCalculation';
import { SprayCalculationFarm } from 'src/components/organisms/CreateSprayCalcModal/CreateSprayCalcModal';
import {
	getNozzleDelivery,
	getNozzleName,
} from 'src/components/organisms/NozzleSelectorModal/Helpers/NozzleLookupTable';
import OrchardSprayForm from 'src/components/organisms/OrchardSprayForm/OrchardSprayForm';
import {
	INozzleConfiguration,
	ISprayCalculation,
	NozzleColour,
	NozzleSide,
	orchardFormValues,
} from 'src/components/organisms/OrchardSprayForm/helpers/sprayCalculation';
import WeedSprayForm from 'src/components/organisms/WeedSprayForm/WeedSprayForm';
import {
	IWeedSprayCalcData,
	weedFormValues,
} from 'src/components/organisms/WeedSprayForm/helpers/sprayCalculation';
import { SprayCalculationTypes } from 'src/helpers/typeHelpers';
import pages from 'src/router/routes';
import {
	useCreateSprayCalculationMutation,
	useUpdateSprayCalculationMutation,
	useGetSingleSprayCalculationQuery,
} from 'src/services/rtkQuery/myFarmApi';
import {
	Nozzle,
	SprayCalcNozzleConfig,
	SprayCalculation,
	SprayCalculationValues,
} from 'src/services/rtkQuery/types/sprayCalculation.types';

const SprayCalculationsDetail = () => {
	const { state } = useLocation();
	const { id, sprayCalculationType } = state as {
		id?: string;
		sprayCalculationType?: SprayCalculationFarm;
	};

	const [indemnityModalOpen, setIdemnityModalOpen] = useState<boolean>(true);

	const [createSprayCalculation, { isLoading: createIsLoading }] =
		useCreateSprayCalculationMutation();
	const [updateSprayCalculation, { isLoading: updateIsLoading }] =
		useUpdateSprayCalculationMutation();
	const { data, isLoading, isFetching } = useGetSingleSprayCalculationQuery(
		{ id: id ?? '' },
		{
			skip: id === null || id === undefined,
		}
	);

	const navigate = useNavigate();

	const saveSprayCalculation = async (sprayCalculation?: SprayCalculation) => {
		try {
			if (sprayCalculation) {
				if (sprayCalculation.id)
					await updateSprayCalculation(sprayCalculation).unwrap();
				else await createSprayCalculation(sprayCalculation).unwrap();

				toast.success('Successfully saved spray calculation');
				navigate(pages.myFarm.sprayCalcs.path);
			}
		} catch {
			toast.error('An error occured while saving spray calculation');
		}
	};

	const SprayCalculationForm = () => {
		const blockSize = id
			? data?.data?.farmBlock?.blockSize
			: sprayCalculationType?.farmBlock?.blockSize;
		switch (sprayCalculationType?.sprayType ?? data?.data?.type) {
			case SprayCalculationTypes.BOOMARABLE: {
				return (
					<BoomArableSprayForm
						formSubmitting={createIsLoading || updateIsLoading}
						blockSize={blockSize ?? 0}
					/>
				);
			}
			case SprayCalculationTypes.ORCHARD: {
				return (
					<OrchardSprayForm
						formSubmitting={createIsLoading || updateIsLoading}
						blockSize={blockSize ?? 0}
					/>
				);
			}
			case SprayCalculationTypes.WEED: {
				return (
					<WeedSprayForm
						formSubmitting={createIsLoading || updateIsLoading}
						blockSize={blockSize ?? 0}
					/>
				);
			}
			default: {
				return <></>;
			}
		}
	};

	const constuctFormValues = ():
		| IBoomSprayCalcData
		| IWeedSprayCalcData
		| ISprayCalculation => {
		const values = data?.data?.sprayCalculationValues as SprayCalculationValues;
		switch (sprayCalculationType?.sprayType ?? data?.data?.type) {
			case SprayCalculationTypes.BOOMARABLE: {
				const form: IBoomSprayCalcData =
					id && data?.data
						? {
								...(data?.data as IBoomSprayCalcData),
								...values,
						  }
						: boomFormValues;
				return form;
			}
			case SprayCalculationTypes.ORCHARD: {
				const form: ISprayCalculation =
					id && data?.data
						? {
								...(data?.data as ISprayCalculation),
								...values,
								leftSideNozzleConfiguration: mapNozzlesForSide(
									NozzleSide.Left,
									data.data.sprayCalcNozzleConfigs
								),
								rightSideNozzleConfiguration: mapNozzlesForSide(
									NozzleSide.Right,
									data.data.sprayCalcNozzleConfigs
								),
						  }
						: orchardFormValues;
				return form;
			}
			case SprayCalculationTypes.WEED: {
				const values = data?.data?.sprayCalculationValues;
				const form: IWeedSprayCalcData =
					id && data?.data
						? {
								...(data?.data as IWeedSprayCalcData),
								...values,
						  }
						: weedFormValues;
				return form;
			}
			default:
				return {};
		}
	};

	const constructSprayCalculation = (
		values: IBoomSprayCalcData | IWeedSprayCalcData | ISprayCalculation
	) => {
		const type = id
			? ((data?.data?.type ?? 0) as SprayCalculationTypes)
			: sprayCalculationType?.sprayType ?? SprayCalculationTypes.BOOMARABLE;

		const orchardValues =
			type === SprayCalculationTypes.ORCHARD
				? {
						sprayCalcNozzleConfigs: [
							...mapNozzles(values as ISprayCalculation),
						],
				  }
				: {};

		return {
			...orchardValues,
			id,
			farmBlockId: id
				? values.farmBlockId ?? ''
				: sprayCalculationType?.farmBlockId ?? '',
			products: values.products,
			sprayCalculationValues: { ...values },
			type,
		};
	};

	const initFormValues = constuctFormValues();

	return (
		<>
			<Helmet>
				<title>My Farm - Spray Calculations</title>
			</Helmet>
			{id && isLoading && isFetching ? (
				<CustomBackdrop loading={createIsLoading || updateIsLoading} />
			) : (
				<Formik
					initialValues={initFormValues}
					enableReinitialize
					onSubmit={(values) => {
						saveSprayCalculation(constructSprayCalculation(values));
					}}
				>
					{() => (
						<Form>
							<SprayCalculationForm />
						</Form>
					)}
				</Formik>
			)}
			<CustomModal
				open={indemnityModalOpen}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<>
					<UilExclamationTriangle size={60} color={'#FFC107'} />
					<Typography textAlign={'center'}>
						Please note this is an estimate. <br />
						Please consult a professional on the outputs.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={() => setIdemnityModalOpen(false)}
					>
						I Understand
					</Button>
				</>
			</CustomModal>
		</>
	);
};

export { SprayCalculationsDetail };

const mapNozzlesForSide = (
	side: NozzleSide,
	nozzles?: SprayCalcNozzleConfig[]
) => {
	if (!nozzles) return;
	const mappedNozzles = nozzles
		.filter((n) => n.side === side.toString())
		.map((n, i): INozzleConfiguration => {
			const nozzleColour = Object.values(NozzleColour).indexOf(
				n.nozzle.colour
			) as NozzleColour;
			return {
				deliveryLitrePerMin: getNozzleDelivery(nozzleColour, n.pressure) ?? 0,
				nozzleColour: n.nozzle.colour,
				nozzlePressure: n.pressure,
				nozzleSide: n.side as NozzleSide,
				nozzleName: getNozzleName(nozzleColour, n.pressure),
				nozzlePort: i + 1,
				id: n.nozzleId ?? '',
			};
		});
	return mappedNozzles;
};

const mapNozzles = (values: ISprayCalculation) => {
	const nozzleConfig: SprayCalcNozzleConfig[] = [];
	values.leftSideNozzleConfiguration?.map((n) =>
		nozzleConfig.push({
			side: NozzleSide[n.nozzleSide].toString(),
			pressure: n.nozzlePressure,
			sprayCalcId: values.id,
			nozzleId: n.id,
			nozzle: {
				nozzleId: n.id,
				colour: n.nozzleColour,
				description: n.nozzleName,
			} as Nozzle,
		})
	);
	values.rightSideNozzleConfiguration?.map((n) =>
		nozzleConfig.push({
			side: NozzleSide[n.nozzleSide].toString(),
			pressure: n.nozzlePressure,
			sprayCalcId: values.id,
			nozzleId: n.id,
			nozzle: {
				nozzleId: n.id,
				colour: n.nozzleColour,
				description: n.nozzleName,
			} as Nozzle,
		})
	);
	return nozzleConfig;
};

export const SprayCalculatorScreenActions = ({
	loading,
}: {
	loading: boolean;
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<LoadingButton
				variant="contained"
				loading={loading}
				color="primary"
				endIcon={<UilSave />}
				size="small"
				type="submit"
				sx={{
					ml: 1,
				}}
			>
				Save
			</LoadingButton>
		</Box>
	);
};
