import { Block, Farm, FarmBlockCropInfo } from './farms.types';
import { Contact, UserProfile } from './users.types';

export enum ProductSelection {
	BlockSelection,
	CropSelection,
}

export enum ProductApplicationType {
	Hectare = '/ha',
	Per100L = '/100L',
	MeterSquared = '/m2',
	PlantOrTrees = 'plant/trees',
}

export interface Recommendation {
	id: string;
	humanFriendlyId: string;
	name: string;
	deadline?: Date;
	clientId: string;
	clientName: string;
	dateSubmitted: Date;
	dateSigned: Date;
	status: string;
	createdDate: Date;
	recipeId: string;
	farm: Farm;
	contacts?: Contact[];
	includeSignature: boolean;
	sendApplicationInstructions: boolean;
	acknowledged?: boolean;
}

export interface Product {
	id?: string;
	productId: string;
	product?: BcProduct;
	registeredDosage?: number;
	unitOfMeasure: string;
	productApplicationType: ProductApplicationType;
	sprayVolume?: number;
	area?: number;
	plantAmount?: number;
	preharvestInterval: string;
	purpose: string;
	comments: string;
	totalProduct: number;
}

export interface TimeOfApplication {
	id: string;
	name: string;
}

export interface RecommendationProduct {
	id?: string;
	recommendationId: string;
	productSelection: ProductSelection;
	farmBlockCropInfoId?: string;
	farmBlockCropInfo?: FarmBlockCropInfo;
	product: Product;
	timesOfApplication?: TimeOfApplication[];
	order: number;
}

export interface ProductTotal {
	productName: string;
	total: number;
	unitOfMeasure: string;
	labels: Label[];
}

export interface Label {
	id: string;
	fileName: string;
	tag: string;
}

export interface BcProduct {
	no: string;
	description: string;
	salesUnitOfMeasure: string;
	vlcProdReg: string;
	vlcCompany: string;
	activeIngredient: string;
	labelId?: string;
}

export interface GroupedRecommendationProduct {
	farmBlockNames: string;
	blockSize: number;
	crops: string;
	recommendationProducts: RecommendationProductFlatened[];
}

export interface RecommendationProductFlatened {
	productId: string;
	registeredDosage: number;
	sprayVolume: number;
	purpose?: string;
	comments?: string;
	preharvestInterval?: string;
	totalProduct: string;
	totalProductHa: string;
	order: number;
	timesOfApplication?: string;
}

export interface RecommendationExtended {
	recommendation: Recommendation;
	recommendationProducts: GroupedRecommendationProduct[];
	products: BcProduct[];
	productTotals: ProductTotal[];
	cropAdvisor?: UserProfile;
}

export interface ApplicationInstruction {
	recommendation: Recommendation;
	recommendationProducts: GroupedRecommendationProduct[];
	products: BcProduct[];
	cropAdvisor: UserProfile;
}

export interface ProductTotalCost {
	totalPrice: number;
	units: number;
	pricePerUnit: number;
	packaging: string;
}

export interface QuoteProductTotal extends ProductTotal {
	costTotal: ProductTotalCost;
	purpose: string;
	comments: string;
}

export interface GroupedRecommendationQuoteProduct {
	farmBlockNames: string;
	blockSize: number;
	crops: string;
	recommendationProducts: RecommendationQuoteProductFlatened[];
}

export interface RecommendationQuoteProductFlatened
	extends RecommendationProductFlatened {
	pricePerUnit: number;
	pricePerHa: number;
}

export interface RecommendationQuoteExtended {
	recommendation: Recommendation;
	recommendationProducts: GroupedRecommendationQuoteProduct[];
	products: BcProduct[];
	productTotals: QuoteProductTotal[];
	cropAdvisor?: UserProfile;
	totalProductCost: number;
}
