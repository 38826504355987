import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
	ScreenLayout,
	StyledCard,
} from 'src/components/templates/ScreenLayout/ScreenLayout';
import { headerConfig, mobileHeaderConfig } from './Helpers/sprayCalculations';
import { useState } from 'react';
import {
	getSprayTypeString,
	SprayCalculationTypes,
} from 'src/helpers/typeHelpers';
import CreateSprayCalcModal from 'src/components/organisms/CreateSprayCalcModal/CreateSprayCalcModal';
import { useDebounce } from 'use-debounce';
import pages from 'src/router/routes';
import { useNavigate } from 'react-router-dom';
import {
	useDeleteSprayCalculationMutation,
	useGetSprayCalculationsQuery,
} from 'src/services/rtkQuery/myFarmApi';
import {
	Button,
	Checkbox,
	DataGrid,
	SearchBar,
	ConfirmationPopup,
} from 'features';
import { Helmet } from 'react-helmet-async';
import { SprayCalculation } from 'src/services/rtkQuery/types/sprayCalculation.types';

const SprayCalculations = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [searchValue, setSearchValue] = useState<string>('');
	const [debouncedValue] = useDebounce(searchValue, 500);
	const [selectValues, setSelectValues] = useState<SprayCalculationTypes[]>([]);
	const [modalState, setModalState] = useState<boolean>(false);
	const [deleteModalState, setDeleteModalState] = useState<boolean>(false);
	const [selectedSprayCalcId, setSelectedSprayCalcId] = useState();

	const { data, isLoading, isFetching } = useGetSprayCalculationsQuery({
		skip: paginationModel.page * paginationModel.pageSize,
		take: paginationModel.pageSize,
		search: debouncedValue,
		type: selectValues.length
			? selectValues.toString()
			: [
					SprayCalculationTypes.BOOMARABLE,
					SprayCalculationTypes.ORCHARD,
					SprayCalculationTypes.WEED,
			  ].toString(),
	});

	const [deleteSprayCalculation] = useDeleteSprayCalculationMutation();

	const AddCalculationsButton = () => (
		<Button
			variant="contained"
			color="primary"
			endIcon={'UilPlus'}
			size="small"
			onClick={() => setModalState(true)}
		>
			Add new spray calculation
		</Button>
	);

	const handleSearchChange = (e: any) => {
		setSearchValue(e.target.value);
	};

	const checkboxChanged = (checked: boolean, type: SprayCalculationTypes) => {
		const values = [...selectValues];
		if (checked) {
			values.push(type);
		} else {
			const index = values.indexOf(type);
			values.splice(index, 1);
		}
		setSelectValues(values);
	};

	const onDeleteClick = (params: any) => {
		setSelectedSprayCalcId(params.id);
		setDeleteModalState(true);
	};

	const navigate = useNavigate();
	const onEditClick = (params: any) => {
		const { id } = params;
		navigate(pages.myFarm.sprayCalcDetails.path, {
			state: { id },
		});
	};

	const dataGridColumns = headerConfig(onDeleteClick, onEditClick);
	const mobileColumns = mobileHeaderConfig(onDeleteClick, onEditClick);

	const onDeleteSprayCalculation = () => {
		deleteSprayCalculation({ id: selectedSprayCalcId });
		setDeleteModalState(false);
	};

	return (
		<>
			<Helmet>
				<title>My Farm - Spray Calculations</title>
			</Helmet>
			<ScreenLayout
				title="Spray Calculations"
				icon="UilRaindrops"
				screenActionsEnd={<AddCalculationsButton />}
			>
				<StyledCard>
					<Stack gap={2} width="100%">
						<SearchBar
							searchTitle="Search for a spray calculation"
							searchPlaceholder="Search by type, title, etc."
							searchValue={searchValue}
							searchOnchangeEvent={handleSearchChange}
							clearText={() => setSearchValue('')}
							selectTitle="Type"
							selectPlaceholder={`${selectValues?.map((v) =>
								getSprayTypeString(v, true)
							)}`}
							selectChildren={
								<Stack gap={2} width="100%">
									<Checkbox
										label={getSprayTypeString(
											SprayCalculationTypes.BOOMARABLE,
											true
										)}
										onChange={(_, checked) =>
											checkboxChanged(checked, SprayCalculationTypes.BOOMARABLE)
										}
										checked={selectValues?.some(
											(v) => v === SprayCalculationTypes.BOOMARABLE
										)}
									/>
									<Checkbox
										label={getSprayTypeString(
											SprayCalculationTypes.ORCHARD,
											true
										)}
										onChange={(_, checked) =>
											checkboxChanged(checked, SprayCalculationTypes.ORCHARD)
										}
										checked={selectValues?.some(
											(v) => v === SprayCalculationTypes.ORCHARD
										)}
									/>
									<Checkbox
										label={getSprayTypeString(SprayCalculationTypes.WEED, true)}
										onChange={(_, checked) =>
											checkboxChanged(checked, SprayCalculationTypes.WEED)
										}
										checked={selectValues?.some(
											(v) => v === SprayCalculationTypes.WEED
										)}
									/>
								</Stack>
							}
						/>
						<DataGrid
							columns={isMobile ? mobileColumns : dataGridColumns}
							rows={data?.data ?? []}
							rowCount={data?.totalRows}
							paginationModel={paginationModel}
							onPaginationModelChange={setPaginationModel}
							getRowId={(row: SprayCalculation) => row.id ?? ''}
							loading={isLoading || isFetching}
						/>
					</Stack>
				</StyledCard>
			</ScreenLayout>
			<CreateSprayCalcModal
				modalOpenState={modalState}
				openModal={() => setModalState(true)}
				closeModal={() => setModalState(false)}
			/>
			<ConfirmationPopup
				title="Are You Sure"
				bodyText="Once you delete the spray calculation, you will have to re-add it."
				modalState={deleteModalState}
				modalSubmit={onDeleteSprayCalculation}
				closeModal={() => setDeleteModalState(false)}
				openModal={() => setDeleteModalState(true)}
				buttonText="Delete Item"
			/>
		</>
	);
};

export { SprayCalculations };
