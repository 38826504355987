import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import dayjs from 'dayjs';
import {
	PdfTable,
	PdfTableCell,
	PdfTableHead,
	PdfTableHeadCell,
	PdfTableRow,
	PdfTableText,
	Table,
	TableRowSection,
	TableSection,
} from 'features/src/organisms/PdfDocument/PdfTable';
import {
	PdfContent,
	PdfHeader,
	PdfStack,
	PdfRow,
	styles,
	RecommendationQuoteExtended,
	UserProfile,
	currencyFormat,
	InteliGroLogo,
} from 'features';

// Displays a row within a PdfStack with a title and value
// e.g. Crop Advisor: Wessel Combrinck
const InfoHeader = ({
	text,
	textStyle,
	header,
	style,
}: {
	text: string;
	textStyle?: Style[];
	header: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<Text style={[styles.boldText, styles.font10, styles.w30]}>
				{header}:
			</Text>
			<Text style={[styles.font10, styles.w70, ...(textStyle ?? [])]}>
				{text}
			</Text>
		</PdfRow>
	);
};

// Displays a row within a PdfStack with a single string
// And text aligned to the right
// To display the Inteligro details under their logo
const InfoWithoutHeader = ({
	text,
	style,
}: {
	text: string;
	style?: Style[];
}) => {
	return (
		<PdfRow style={[...(style ?? [])]}>
			<View
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
				}}
			>
				<Text style={[styles.font8]}>{text}</Text>
			</View>
		</PdfRow>
	);
};

type FieldType = {
	name: string;
	width: string | number;
};

export const QuotePdf = (props: {
	recommendation?: RecommendationQuoteExtended;
	user: UserProfile | null;
	signatureUrl?: string | null;
	apiUrl: string;
}) => {
	const {
		recommendation,
		recommendationProducts,
		products,
		productTotals,
		cropAdvisor,
		totalProductCost,
	} = props.recommendation ?? {
		recommendation: {},
		recommendationProducts: [],
		products: [],
		productTotals: [],
		cropAdvisor: {},
		totalProductCost: 0,
	};

	const fields: Record<string, FieldType> = {
		farmArea: { name: 'Farm Area', width: '20vw' },
		product: { name: 'Product', width: '10vw' },
		toa: { name: 'TOA', width: '8vw' },
		regDosage: { name: 'Reg Dosage', width: '7vw' },
		sprayVolumeHa: { name: 'Spray Volume /ha', width: '7vw' },
		totalProductHa: { name: 'Total Product /ha', width: '7vw' },
		totalProduct: { name: 'Total Product', width: '7vw' },
		phi: { name: 'PHI', width: '7vw' },
		purpose: { name: 'Purpose', width: '10vw' },
		comments: { name: 'Comments', width: '13vw' },
		pricePerUnit: { name: 'Price/unit', width: '10vw' },
		pricePerHa: { name: 'Price/Ha', width: '10vw' },
	};

	const depot = cropAdvisor?.depots ? cropAdvisor.depots[0] : undefined;

	return (
		<Document title={recommendation.name} author="InteliGro">
			<Page
				style={styles.page}
				orientation="landscape"
				wrap
				break
				size={'A4'}
				fixed
			>
				<PdfContent>
					<View>
						<PdfHeader>
							<PdfStack style={[{ width: '75%' }]}>
								<Text style={[styles.boldText, { fontSize: '16pt' }]}>
									QUOTE
								</Text>
								<Text style={[styles.boldText, { fontSize: '14pt' }]}>
									FOR APPLICATIONS OF AGRICULTURAL CHEMICALS ON CROPS
								</Text>
								<PdfRow style={[{ marginTop: '10pt' }]}>
									<PdfStack style={[styles.w50, { gap: '2pt' }]}>
										<InfoHeader
											header="Name"
											text={recommendation.name ?? ''}
										/>
										<InfoHeader
											header="Reference No"
											text={recommendation.humanFriendlyId ?? ''}
											textStyle={[{ color: 'red' }]}
										/>
										<InfoHeader
											header="Report Date"
											text={dayjs().format('DD/MM/YYYY').toString()}
										/>
										<InfoHeader
											header="Producer"
											text={recommendation?.clientName ?? ''}
										/>
										<InfoHeader
											header="Farm"
											text={recommendation.farm?.name ?? ''}
										/>
									</PdfStack>
									<PdfStack style={[styles.w50, { gap: '2pt' }]}>
										<InfoHeader
											header="Crop Advisor"
											text={cropAdvisor?.name ?? ''}
										/>
										<InfoHeader
											header="AVCASA"
											text={cropAdvisor?.cropLifeSaId ?? ''}
										/>
										<InfoHeader
											header="BASOS"
											text={cropAdvisor?.basosId ?? ''}
										/>
										<InfoHeader
											header="Cell"
											text={cropAdvisor?.cellphone ?? ''}
										/>
										<InfoHeader header="Email" text={props.user?.email ?? ''} />
										<InfoHeader
											header="Deadline Date"
											text={
												recommendation?.deadline
													? dayjs(recommendation.deadline)
															.format('DD/MM/YYYY')
															.toString()
													: ''
											}
											textStyle={[{ color: 'red' }]}
										/>
										<PdfRow>
											<Text
												style={[styles.boldText, styles.font10, styles.w30]}
											>
												Address:
											</Text>
											{depot && (
												<PdfStack>
													{depot.address && (
														<Text style={[styles.font10, styles.w70]}>
															{depot.address}
														</Text>
													)}
													{depot.city && (
														<Text style={[styles.font10, styles.w70]}>
															{depot.city}
														</Text>
													)}
													{depot.province && (
														<Text style={[styles.font10, styles.w70]}>
															{depot.province}
														</Text>
													)}
													{depot.country && (
														<Text style={[styles.font10, styles.w70]}>
															{depot.country}
														</Text>
													)}
													{depot.postalCode && (
														<Text style={[styles.font10, styles.w70]}>
															{depot.postalCode}
														</Text>
													)}
												</PdfStack>
											)}
										</PdfRow>
									</PdfStack>
								</PdfRow>
							</PdfStack>

							<PdfStack
								style={[{ width: '25%', gap: '2pt', alignItems: 'flex-end' }]}
							>
								<Image src={InteliGroLogo} source={'Powered by InteliGro'} />
								<InfoWithoutHeader
									style={[{ marginTop: '5pt' }]}
									text={'www.inteligro.co.za | info@inteligro.co.za'}
								/>
								<InfoWithoutHeader
									text={'Wellington: 021 873 6177 | Viljoenskroon: 056 343 344'}
								/>
							</PdfStack>
						</PdfHeader>

						{/* Products Table */}
						<View style={styles.section} wrap>
							<Table>
								<PdfTableHead>
									{Object.entries(fields).map(([key, value], columnIndex) => (
										<PdfTableHeadCell
											key={`header-${key}`}
											width={value.width}
											index={columnIndex}
										>
											<Text style={styles.tableHeader} wrap={false}>
												{value.name}
											</Text>
										</PdfTableHeadCell>
									))}
								</PdfTableHead>
								{recommendationProducts &&
									recommendationProducts.map((block, blockIndex) => {
										const recommendationProducts =
											block.recommendationProducts ?? [];

										const evenRow = blockIndex % 2 === 0;

										return (
											<PdfRow key={`row-${blockIndex}`} wrap={false}>
												<PdfTableCell
													style={[
														styles.column,
														styles.font10,
														{
															backgroundColor: evenRow ? '#808080' : '#FFFFFF',
															borderLeft: '1pt',
														},
													]}
													width={fields['farmArea'].width}
													index={blockIndex}
												>
													<PdfTableText
														style={[
															{
																paddingRight: 8,
																width: '100%',
																color: evenRow ? '#FFFFFF' : '#000000',
															},
														]}
													>
														{block.farmBlockNames}
													</PdfTableText>
													<PdfTableText
														style={[
															{
																color: evenRow ? '#FFFFFF' : '#000000',
															},
														]}
													>
														({block.blockSize}ha)
													</PdfTableText>
													<PdfTableText
														style={[
															{
																color: evenRow ? '#FFFFFF' : '#000000',
															},
														]}
													>
														{block.crops ?? ''}
													</PdfTableText>
												</PdfTableCell>
												<TableSection
													containsStack={recommendationProducts.length > 1}
													wrap={true}
												>
													{recommendationProducts &&
														recommendationProducts.map(
															(recommendationProduct, index) => (
																<TableRowSection
																	key={`product-${index}`}
																	containsRow={
																		recommendationProducts.length > 1
																	}
																>
																	<PdfTableCell
																		width={fields['product'].width}
																		index={1}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{products.find(
																				(x) =>
																					x.no ==
																					recommendationProduct.productId
																			)?.description ?? ''}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['toa'].width}
																		index={2}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{recommendationProduct.timesOfApplication &&
																				recommendationProduct.timesOfApplication}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['regDosage'].width}
																		index={3}
																	>
																		<PdfTableText>
																			{recommendationProduct.registeredDosage}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['sprayVolumeHa'].width}
																		index={4}
																	>
																		<PdfTableText>
																			{recommendationProduct.sprayVolume}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['totalProductHa'].width}
																		index={5}
																	>
																		<PdfTableText>
																			{recommendationProduct.totalProductHa}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['totalProduct'].width}
																		index={6}
																	>
																		<PdfTableText>
																			{recommendationProduct.totalProduct}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['phi'].width}
																		index={7}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{recommendationProduct.preharvestInterval}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['purpose'].width}
																		index={8}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{recommendationProduct.purpose}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['comments'].width}
																		index={9}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{recommendationProduct.comments}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['pricePerUnit'].width}
																		index={10}
																	>
																		<PdfTableText style={[{ paddingRight: 8 }]}>
																			{currencyFormat(
																				parseFloat(
																					recommendationProduct.pricePerUnit.toString()
																				),
																				'R'
																			)}
																		</PdfTableText>
																	</PdfTableCell>
																	<PdfTableCell
																		width={fields['pricePerHa'].width}
																		index={11}
																	>
																		<PdfTableText
																			style={[{ paddingRight: 20 }]}
																		>
																			{currencyFormat(
																				parseFloat(
																					recommendationProduct.pricePerHa.toString()
																				),
																				'R'
																			)}
																		</PdfTableText>
																	</PdfTableCell>
																</TableRowSection>
															)
														)}
												</TableSection>
											</PdfRow>
										);
									})}
							</Table>
						</View>

						{/* Product Totals Table */}
						<View style={styles.section} wrap>
							<PdfTable
								fields={[
									{
										field: 'productName',
										name: 'Product Name',
										width: '20%',
									},
									{
										field: 'labels',
										name: 'Labels',
										width: '15%',
										customContent: true,
									},
									{
										field: 'total',
										name: 'Total Product',
										width: '15%',
									},
									{
										field: 'packaging',
										name: 'Packaging',
										width: '10%',
									},
									{
										field: 'pricePerUnit',
										name: 'Price/unit',
										width: '15%',
									},
									{
										field: 'units',
										name: 'Units',
										width: '10%',
									},
									{
										field: 'totalPrice',
										name: 'Total Price',
										width: '15%',
									},
								]}
								data={productTotals.map((productTotal) => {
									return {
										productName: productTotal.productName,
										total: `${productTotal.total} ${productTotal.unitOfMeasure}`,
										labels: (
											<View>
												{productTotal.labels &&
													productTotal.labels.map((l) => (
														<Link
															key={`label-${l.fileName}`}
															src={`${props.apiUrl}LibraryData/DownloadFile/${l.id}`}
															style={[
																styles.font10,
																{ color: '#000000', textDecoration: 'none' },
															]}
														>
															{l.fileName}
														</Link>
													))}
											</View>
										),
										packaging: productTotal.costTotal.packaging,
										pricePerUnit: currencyFormat(
											parseFloat(
												productTotal.costTotal.pricePerUnit.toString() ?? 0
											),
											'R'
										),
										units: productTotal.costTotal.units,
										totalPrice: currencyFormat(
											parseFloat(
												productTotal.costTotal.totalPrice.toString() ?? 0
											),
											'R'
										),
									};
								})}
							/>
							<PdfTableRow>
								<PdfTableCell
									width={'85%'}
									index={0}
									style={[
										{
											backgroundColor: '#84bb6d',
										},
									]}
								>
									<PdfTableText>Total:</PdfTableText>
								</PdfTableCell>
								<PdfTableCell
									width={'15%'}
									index={1}
									style={[
										{
											backgroundColor: '#84bb6d',
										},
									]}
								>
									<PdfTableText>
										{currencyFormat(
											parseFloat(totalProductCost.toString()),
											'R'
										)}
									</PdfTableText>
								</PdfTableCell>
							</PdfTableRow>
						</View>
					</View>

					<View>
						<PdfRow style={[styles.section, { height: '50pt' }]}>
							<View style={{ width: '15%' }}>
								{props.signatureUrl && (
									<Image
										style={{ width: '75%' }}
										src={props.signatureUrl}
										source={'Signature'}
									/>
								)}
								{props.recommendation?.cropAdvisor?.name && (
									<Text style={styles.signatureSubText}>
										{props.recommendation?.cropAdvisor?.name}
									</Text>
								)}
								{props.recommendation?.cropAdvisor?.title && (
									<Text style={styles.signatureSubText}>
										{props.recommendation?.cropAdvisor?.title}
									</Text>
								)}
							</View>
							<PdfStack style={[{ width: '85%', gap: '4pt' }]}>
								<Text style={styles.footerParagraph} wrap={false}>
									All involved parties hereby agree that they have familiarised
									themselves with the usage instructions (including crop
									registration, pest registration and registered dose rates),
									warnings and withholding periods as it relates to the above
									recommended product labels.
								</Text>
								<Text style={styles.footerParagraph} wrap={false}>
									Alle betrokke partye stem hiermee saam dat hulle hulself
									vergewis het van die gebruiksinstruksies (insluitend
									gewasregistrasie, plaagregistrasie en geregistreerde
									dosishoeveelhede), waarskuwings en onthoudingsperiodes soos
									dit met bogenoemde aanbevole produketikette verband hou.
								</Text>
							</PdfStack>
						</PdfRow>
					</View>
				</PdfContent>
			</Page>
		</Document>
	);
};
