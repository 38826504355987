import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { CropCategory } from './types/crop.types';

const cropApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCrops: builder.query<ApiResponse<CropCategory[]>, void>({
      query: () => {
        return {
          url: 'Crop',
          cache: 'no-cache'
        };
      }
    })
  })
});

export const { useGetAllCropsQuery } = cropApi;
