import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { FlagsProvider } from './contexts/FlagsContext';
import 'nprogress/nprogress.css';
import { AuthProvider } from './features/authentication';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<AuthProvider>
				<FlagsProvider
					defaults={{
						quoteRequestDisabled: true,
						myFarmDisabled: false,
						headerNotificationDisabled: true,
						requestQuoteButtonDisabled: true,
						recommendationsDisabled: true,
						technicalDataDisabled: true,
						powerBIiframeDisabled: false,
						statementsDisabled: true,
					}}>
					<HelmetProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</HelmetProvider>
				</FlagsProvider>
			</AuthProvider>
		</Provider>
	</React.StrictMode>
);

console.log('Running Version 1.0.0')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
