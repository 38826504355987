const SVG_WIDTH = 989;
const NOZZLE_OUTER_WIDTH = 20;
const NOZZLE_OUTER_BORDER = 4;
const TRACTOR_WIDTH = 304;

interface BoomArableSpraySvgProps {
  nozzleAmount: number;
}

const Nozzle = ({ nozzleX } : { nozzleX: number }) => {
  return (
    <>
      <rect x={nozzleX} y={220} width={NOZZLE_OUTER_WIDTH} height={20} rx={10} fill="black" />
      <rect 
        x={nozzleX + NOZZLE_OUTER_BORDER} 
        y={223.75} 
        width={12} 
        height={12} 
        rx={6} 
        fill={'#0066B4'}
        />
    </>
  )
}

const BoomArableSpraySvg = ({nozzleAmount}: BoomArableSpraySvgProps) => {
  const boomWidth = (SVG_WIDTH - (TRACTOR_WIDTH + (NOZZLE_OUTER_WIDTH * 2))) / 2;
  const nozzleGap = boomWidth / (nozzleAmount / 2);

  const nozzlesLeft: JSX.Element[]  = [];
  const nozzlesRight: JSX.Element[] = [];

  for (let i = 0; i < (nozzleAmount / 2); i++) {
    const leftX = (i * nozzleGap) + NOZZLE_OUTER_WIDTH;
    nozzlesLeft.push(<Nozzle nozzleX={leftX}/>);

    const rightX = SVG_WIDTH - ((i * nozzleGap) + (NOZZLE_OUTER_WIDTH * 2));
    nozzlesRight.push(<Nozzle nozzleX={rightX}/>);
  }

  return (
        <svg
        viewBox={`0 0 ${SVG_WIDTH} 350`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        {nozzlesLeft.length && nozzlesLeft.map((n) => (n))}
        {nozzlesRight.length && nozzlesRight.map((n) => (n))}
        <path
          d="M287.869 220.005L369.451 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M3 198.606V220.674H368.114V165.171H119.355L3 198.606Z"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M47.1328 185.9L47.1328 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M127.379 164.502L127.379 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M207.625 164.502L207.625 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M287.869 165.839L287.869 222.011"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M47.1328 220.005L128.715 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M128.719 220.005L210.301 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M207.625 220.005L289.207 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M701.131 220.005L619.549 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M986 198.606V220.674H620.886V165.171H869.645L986 198.606Z"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M941.867 185.9L941.867 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M861.621 164.502L861.621 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M781.375 164.502L781.375 220.673"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M701.131 165.839L701.131 222.011"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M941.867 220.005L860.285 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M860.281 220.005L778.699 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <path
          d="M781.375 220.005L699.793 165.171"
          stroke="black"
          strokeWidth={5.34529}
        />
        <circle cx={495.838} cy={91.6129} r={91.6129} fill="#284F17" />
        <path
          d="M443.009 13.374L420.273 143.772H568.727L547.997 13.374H443.009Z"
          fill="#509E2F"
        />
        <path
          d="M544.584 100.978H444.498L459.609 32.7641H530.806L544.584 100.978Z"
          fill="#F2FFED"
          stroke="#284F17"
          strokeWidth={6.68162}
        />
        <rect
          x={440.336}
          y={104.318}
          width={111.005}
          height={100.306}
          fill="#509E2F"
        />
        <g clipPath="url(#clip0_6350_26759)">
          <rect
            x={352.066}
            y={151.127}
            width={54.7892}
            height={146.996}
            rx={16.0359}
            fill="#262729"
          />
          <rect
            x={352.244}
            y={148.455}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 148.455)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={132.419}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 132.419)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={164.491}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 164.491)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={180.526}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 180.526)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={196.562}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 196.562)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={212.598}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 212.598)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={228.634}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 228.634)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={244.67}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 244.67)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={260.706}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 260.706)"
            fill="#4B4F54"
          />
          <rect
            x={352.244}
            y={276.742}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 352.244 276.742)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 148.455)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 132.419)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 164.491)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 180.526)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 196.562)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 212.598)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 228.634)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 244.67)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 260.706)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 405.803 276.742)"
            fill="#4B4F54"
          />
        </g>
        <ellipse cx={494.5} cy={242.741} rx={46.1408} ry={14.0429} fill="#1E1E1C" />
        <ellipse
          cx={427.184}
          cy={179.736}
          rx={18.0551}
          ry={30.7605}
          transform="rotate(-22.1159 427.184 179.736)"
          fill="#509E2F"
        />
        <ellipse
          cx={18.0551}
          cy={30.7605}
          rx={18.0551}
          ry={30.7605}
          transform="matrix(-0.926424 -0.376481 -0.376481 0.926424 589.23 160.188)"
          fill="#509E2F"
        />
        <g clipPath="url(#clip1_6350_26759)">
          <rect
            x={580.764}
            y={151.128}
            width={54.7892}
            height={146.996}
            rx={16.0359}
            fill="#262729"
          />
          <rect
            x={580.939}
            y={148.456}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 148.456)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={132.419}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 132.419)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={164.491}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 164.491)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={180.527}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 180.527)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={196.563}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 196.563)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={212.599}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 212.599)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={228.635}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 228.635)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={244.671}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 244.671)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={260.707}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 260.707)"
            fill="#4B4F54"
          />
          <rect
            x={580.939}
            y={276.742}
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="rotate(36.1735 580.939 276.742)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 148.456)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 132.419)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 164.491)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 180.527)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 196.563)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 212.599)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 228.635)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 244.671)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 260.707)"
            fill="#4B4F54"
          />
          <rect
            width={33.4571}
            height={9.35426}
            rx={4.67713}
            transform="matrix(-0.807233 0.590232 0.590232 0.807233 634.5 276.742)"
            fill="#4B4F54"
          />
        </g>
        <rect
          x={398.877}
          y={212.649}
          width={191.25}
          height={24.0735}
          rx={2.67265}
          fill="#509E2F"
        />
        <rect
          x={418.938}
          width={151.128}
          height={13.3741}
          rx={2.67265}
          fill="#509E2F"
        />
        <rect
          x={437.662}
          y={13.374}
          width={115.018}
          height={8.02449}
          fill="#284F17"
        />
        <rect
          x={369.451}
          y={139.091}
          width={250.097}
          height={33.4354}
          rx={2.67265}
          fill="#509E2F"
        />
        <rect
          x={369.451}
          y={156.478}
          width={250.097}
          height={16.049}
          rx={2.67265}
          fill="#284F17"
        />
        <rect
          x={390.852}
          y={204.625}
          width={207.299}
          height={8.02449}
          rx={4.01225}
          fill="#284F17"
        />
        <rect
          x={390.852}
          y={236.722}
          width={207.299}
          height={8.02449}
          rx={4.01225}
          fill="#284F17"
        />
        <circle cx={428.967} cy={209.306} r={14.0429} fill="#284F17" />
        <circle cx={428.968} cy={209.306} r={10.0306} fill="#F2FFED" />
        <circle cx={558.695} cy={209.306} r={14.0429} fill="#284F17" />
        <circle cx={558.697} cy={209.306} r={10.0306} fill="#F2FFED" />
        <rect
          x={448.359}
          y={112.343}
          width={94.9565}
          height={78.9075}
          rx={5.34529}
          fill="#4B4F54"
        />
        <rect
          x={459.061}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={470.078}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={481.092}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={492.105}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={503.125}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={514.143}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <rect
          x={525.158}
          y={119.03}
          width={6.12037}
          height={63.6519}
          rx={3.06019}
          fill="#262729"
        />
        <path
          d="M438.996 30.7603H400.88L388.174 41.4596V53.4963"
          stroke="#284F17"
          strokeWidth={2.67265}
        />
        <rect
          width={20.0612}
          height={32.098}
          rx={5.34529}
          transform="matrix(-1 0 0 1 398.873 53.4966)"
          fill="#509E2F"
        />
        <path
          d="M552.676 30.7607H590.792L603.498 41.4601V53.4968"
          stroke="#284F17"
          strokeWidth={2.67265}
        />
        <rect
          x={592.799}
          y={53.4966}
          width={20.0612}
          height={32.098}
          rx={5.34529}
          fill="#509E2F"
        />
        <defs>
          <clipPath id="clip0_6350_26759">
            <rect
              x={352.066}
              y={151.127}
              width={54.834}
              height={147.116}
              rx={16.0359}
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_6350_26759">
            <rect
              x={580.764}
              y={151.128}
              width={54.834}
              height={147.116}
              rx={16.0359}
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
}

export default BoomArableSpraySvg;