import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import {
	PdfEmptyTableCell,
	PdfTableCell,
	PdfTableHead,
	PdfTableHeadCell,
	PdfTableInfoCell,
	PdfTableRow,
	PdfTableText,
	Table,
	TableRowSection,
	TableSection,
} from 'features/src/organisms/PdfDocument/PdfTable';
import {
	PdfContent,
	PdfHeader,
	PdfStack,
	PdfRow,
	styles,
	InfoHeader,
	ImageTickbox,
	CertifiedCropAdvisorPng,
	BcProduct,
	Recommendation,
	UserProfile,
	GroupedRecommendationProduct,
	HazmatPng,
	BootsPng,
	GlovesPng,
	FaceShieldPng,
	FaceMaskPng,
	FullFaceMaskPng,
	InteliGroLogo,
} from 'features';

type FieldType = {
	name: string;
	width: string | number;
};

export interface ApplicationInstructionPdfProps {
	recommendationProducts: GroupedRecommendationProduct[];
	user?: UserProfile;
	recommendation?: Recommendation;
	signatureUrl: string | null;
	products: BcProduct[];
}

export const ApplicationInstructionPdf = ({
	recommendationProducts,
	user,
	recommendation,
	signatureUrl,
	products,
}: ApplicationInstructionPdfProps) => {
	const fields: Record<string, FieldType> = {
		farmArea: { name: 'Farm Area', width: '10%' },

		//Application Information
		name: { name: 'Name', width: '10%' },
		activeIngredient: { name: 'Active Ingredient', width: '15%' },
		phi: { name: 'PHI', width: '5%' },
		regDosage: { name: 'Reg Dosage', width: '10%' },
		purpose: { name: 'Purpose', width: '10%' },

		//Confirmation Application
		amount: { name: 'Amount / Tank', width: '10%' },
		timeIn: { name: 'In', width: '10%' },
		timeOut: { name: 'Out', width: '10%' },
		volumeApplied: { name: 'Volume Applied', width: '10%' },
	};

	let rowIndex = 0;

	const getActiveIngredientText = (productId: string) => {
		const product = products.find((product) => product.no == productId);
		const activeIngredient = product?.activeIngredient ?? '';

		return activeIngredient;
	};

	const footerWidth = user?.isCertified
		? signatureUrl
			? '80%'
			: '90%'
		: signatureUrl
		? '90%'
		: '100%';

	return (
		<Document title={'Application Instructions'} author="InteliGro">
			<Page
				style={styles.page}
				orientation="landscape"
				wrap
				break
				size={'A4'}
				fixed
			>
				<PdfContent>
					<View>
						{/* Header */}
						<PdfHeader>
							<PdfStack style={[{ width: '30%' }]}>
								<Text style={[styles.boldText, { fontSize: '16pt' }]}>
									APPLICATION INSTRUCTION
								</Text>
								<PdfRow style={[{ marginTop: '10pt' }]}>
									<PdfStack style={[{ gap: '2pt' }]}>
										<InfoHeader
											header="Producer"
											text={recommendation?.farm?.producerUniqueCode ?? ''}
										/>
										<InfoHeader
											header="Farm"
											text={recommendation?.farm?.name ?? ''}
										/>
										<InfoHeader
											header="Report Date"
											text={dayjs().format('DD/MM/YYYY')}
										/>
									</PdfStack>
								</PdfRow>
							</PdfStack>
							<PdfStack style={[{ width: '50%', padding: '10pt' }]}>
								<View
									style={[
										{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										},
									]}
								>
									<Text style={[styles.boldText, styles.font10]}>
										APPLICATION INSTRUCTION: ONE OPERATOR PER FIELD/BLOCK
									</Text>
									<Text style={[styles.boldText, styles.font10]}>
										(sufficient for TESCO NURTURE and GLOBALGAP Ver.5)
									</Text>
								</View>
							</PdfStack>
							<PdfStack
								style={[{ width: '20%', gap: '2pt', alignItems: 'flex-end' }]}
							>
								<Image src={InteliGroLogo} source={'Powered by InteliGro'} />
							</PdfStack>
						</PdfHeader>

						{/* Info Table */}
						<Table style={[{ marginTop: '2pt' }]}>
							<PdfTableHead>
								<PdfTableHeadCell width={'100%'} index={0}>
									<Text style={styles.tableHeader} wrap={false}>
										APPLICATION INSTRUCTION - PEST AND DISEASE CONTROL, FOLIAR
										FEEDING AND GROWTH REGULATORS
									</Text>
								</PdfTableHeadCell>
							</PdfTableHead>
							<PdfTableRow>
								<PdfTableInfoCell
									index={0}
									header={
										<PdfStack>
											<Text style={[styles.font9]}>NUMBER:</Text>
											<Text style={[styles.font9]}>AB REFERENCE NO:</Text>
										</PdfStack>
									}
									value={
										<Text style={[styles.font10, { color: 'red' }]}>
											{recommendation?.humanFriendlyId ?? ''}
										</Text>
									}
								/>
								<PdfTableInfoCell index={1} header={'APPLICATOR:'} />
								<PdfTableInfoCell index={2} header={'TRACTOR + SPRAYER NO:'} />
								<PdfTableInfoCell index={3} header={'BLOWER SPEED:'} />
							</PdfTableRow>

							<PdfTableRow>
								<PdfTableInfoCell width={'12.5vw'} index={0} header={'DATE:'} />
								<PdfTableInfoCell index={1} header={'METHOD OF APPLICATION:'} />
								<PdfTableInfoCell index={2} header={'REVS/MIN:'} />
								<PdfTableInfoCell index={3} header={'PUMP PRESSURE (BAR):'} />
							</PdfTableRow>

							<PdfTableRow>
								<PdfTableInfoCell
									index={0}
									header={'RECOMMENDED BY:'}
									value={
										<Text style={[styles.font10]}>{user?.name ?? ''}</Text>
									}
								/>
								<PdfTableInfoCell index={1} header={'PRODUCT PREPARED BY:'} />
								<PdfTableInfoCell index={2} header={'GEAR:'} />
								<PdfTableInfoCell index={3} header={'TANK SIZE (LITER):'} />
							</PdfTableRow>

							<PdfTableRow>
								<PdfTableInfoCell
									index={0}
									header={'SIGNATURE:'}
									value={
										<View
											style={{
												height: '100%',
												padding: '5%',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
											}}
										>
											{signatureUrl && (
												<Image src={signatureUrl} source={'Signature'} />
											)}
										</View>
									}
								/>
								<PdfTableInfoCell index={1} header={'SIGNATURE:'} />
								<PdfTableInfoCell index={2} header={''} />
								<PdfTableInfoCell index={3} header={''} />
							</PdfTableRow>
						</Table>

						{/* Cautious */}
						<PdfRow
							style={[
								{
									border: '1pt',
									borderTop: '0pt',
									paddingTop: '5pt',
									paddingBottom: '5pt',
								},
							]}
						>
							<PdfRow style={[{ width: '50%', alignItems: 'center' }]}>
								<Text style={[styles.boldText, styles.font8]} wrap={false}>
									CONCENTRATE HANDLING
								</Text>
								<CautionRow />
							</PdfRow>
							<PdfRow style={[{ width: '50%', alignItems: 'center' }]}>
								<Text
									style={[styles.boldText, styles.font8, { width: '15%' }]}
									wrap={false}
								>
									APPLICATION
								</Text>
								<CautionRow />
							</PdfRow>
						</PdfRow>

						{/* Products Table */}
						{/* Row 1 */}
						<PdfRow wrap={false}>
							<PdfTableCell width={'10%'} index={0} />
							<PdfTableCell
								width={'50%'}
								index={1}
								style={[
									{ display: 'flex', alignItems: 'center', padding: '5pt' },
								]}
							>
								<Text style={styles.font12} wrap={false}>
									APPLICATION INFORMATION
								</Text>
							</PdfTableCell>
							<PdfTableCell
								width={'40%'}
								index={2}
								style={[
									{ display: 'flex', alignItems: 'center', padding: '5pt' },
								]}
							>
								<Text style={styles.font12} wrap={false}>
									CONFIRMATION APPLICATION (BY APPLICATOR)
								</Text>
							</PdfTableCell>
						</PdfRow>

						{/* Row 2 */}
						<PdfRow>
							<PdfTableCell
								width={'10%'}
								index={0}
								style={[
									{
										borderTop: '0pt',
										borderBottom: '0pt',
									},
								]}
							/>
							<PdfRow style={[{ width: '50%' }]}>
								<PdfTableCell
									width={'80%'}
									index={1}
									style={[
										{
											display: 'flex',
											alignItems: 'center',
											padding: '5pt',
											borderBottom: '0pt',
										},
									]}
								>
									<Text style={styles.font12} wrap={false}>
										PRODUCT
									</Text>
								</PdfTableCell>
								<PdfTableCell
									width={'20%'}
									style={[{ borderBottom: '0pt' }]}
									index={2}
								/>
							</PdfRow>
							<PdfRow style={[{ width: '40%' }]}>
								<PdfTableCell
									width={'25%'}
									style={[{ borderBottom: '0pt' }]}
									index={3}
								/>
								<PdfTableCell
									width={'50%'}
									index={4}
									style={[
										{
											display: 'flex',
											alignItems: 'center',
											padding: '5pt',
											borderBottom: '0pt',
										},
									]}
								>
									<Text style={styles.font12} wrap={false}>
										TIME
									</Text>
								</PdfTableCell>
								<PdfTableCell
									width={'25%'}
									style={[{ borderBottom: '0pt' }]}
									index={5}
								/>
							</PdfRow>
						</PdfRow>

						{/* Products Table */}
						<PdfRow wrap={false}>
							{Object.entries(fields).map(([key, value], columnIndex) => (
								<PdfTableHeadCell
									key={`header-${key}`}
									width={value.width}
									index={columnIndex}
									style={[
										{
											backgroundColor: '#509E2F',
											borderTop: '1px solid black',
										},
									]}
								>
									<Text
										style={[styles.font9, styles.boldText, { color: 'white' }]}
										wrap={false}
									>
										{value.name}
									</Text>
								</PdfTableHeadCell>
							))}
						</PdfRow>
						{recommendationProducts &&
							recommendationProducts.map((block, blockIndex) => {
								const recommendationProducts =
									block.recommendationProducts ?? [];

								const evenRow = rowIndex % 2 === 0;
								rowIndex++;

								const textColor = {
									color: evenRow ? '#FFFFFF' : '#000000',
								};

								const multipleProducts = recommendationProducts.length > 1;

								const rowStyle = !multipleProducts ? { width: '90%' } : {};

								return (
									<PdfTableRow wrap={true} key={`row-${blockIndex}`}>
										<PdfTableCell
											style={[
												styles.column,
												styles.font10,
												{
													backgroundColor: evenRow ? '#808080' : '#FFFFFF',
													borderLeft: '1pt',
												},
											]}
											width={'10%'}
											index={blockIndex}
										>
											<PdfTableText style={[textColor, styles.font8]}>
												{block.farmBlockNames}
											</PdfTableText>
											<PdfTableText style={[textColor, styles.font8]}>
												({block.blockSize}ha)
											</PdfTableText>
											<PdfTableText style={[textColor, styles.font8]}>
												{block.crops}
											</PdfTableText>
										</PdfTableCell>

										<TableSection
											containsStack={multipleProducts}
											style={[{ width: '90%' }]}
										>
											{recommendationProducts &&
												recommendationProducts.map((p, i) => (
													<TableRowSection
														key={`product-${i}`}
														containsRow={multipleProducts}
														style={[rowStyle]}
													>
														{/* Application Information */}
														<PdfRow
															wrap={false}
															style={[{ width: `${(100 / 9) * 5}%` }]}
														>
															<PdfTableCell width={'20%'} index={1}>
																<PdfTableText style={[styles.font8]}>
																	{products.find((x) => x.no == p.productId)
																		?.description ?? ''}
																</PdfTableText>
															</PdfTableCell>

															<PdfTableCell width={'30%'} index={2}>
																<PdfTableText style={[styles.font8]}>
																	{getActiveIngredientText(p.productId)}
																</PdfTableText>
															</PdfTableCell>

															<PdfTableCell width={'10%'} index={3}>
																<PdfTableText style={[styles.font8]}>
																	{p.preharvestInterval}
																</PdfTableText>
															</PdfTableCell>

															<PdfTableCell width={'20%'} index={4}>
																<PdfTableText style={[styles.font8]}>
																	{p.registeredDosage}
																</PdfTableText>
															</PdfTableCell>

															<PdfTableCell width={'20%'} index={5}>
																<PdfTableText style={[styles.font8]}>
																	{p.purpose}
																</PdfTableText>
															</PdfTableCell>
														</PdfRow>

														{/* Confirmation Application */}
														<PdfRow
															wrap={false}
															style={[{ width: `${(100 / 9) * 4}%` }]}
														>
															<PdfEmptyTableCell index={6} width={'25%'} />
															<PdfEmptyTableCell index={7} width={'25%'} />
															<PdfEmptyTableCell index={8} width={'25%'} />
															<PdfEmptyTableCell index={9} width={'25%'} />
														</PdfRow>
													</TableRowSection>
												))}
										</TableSection>
									</PdfTableRow>
								);
							})}
					</View>

					<View>
						<PdfRow
							style={[
								styles.w100,
								{
									gap: '10pt',
									marginTop: '10pt',
									alignItems: 'center',
									justifyContent: 'flex-start',
								},
							]}
						>
							{signatureUrl && (
								<PdfStack style={[styles.center, { width: '10%', gap: '1pt' }]}>
									<Image
										src={signatureUrl}
										source={'Signature'}
										style={{ maxWidth: '100%', maxHeight: '35pt' }}
									/>
									<Text style={styles.footerParagraph}>{user?.name}</Text>
									{user?.title && (
										<Text style={styles.footerParagraph}>{user?.title}</Text>
									)}
								</PdfStack>
							)}
							{user?.isCertified && (
								<Image
									src={CertifiedCropAdvisorPng}
									style={{ width: '60pt', height: '60pt' }}
								/>
							)}
							<PdfStack
								style={[{ width: footerWidth, justifyContent: 'flex-end' }]}
							>
								<Text style={styles.footerParagraph} wrap={false}>
									All involved parties hereby agree that they have familiarised
									themselves with the usage instructions (including crop
									registration, pest registration and registered dose rates),
									warnings and withholding periods as it relates to the above
									recommended product labels.
								</Text>
								<Text
									style={[styles.footerParagraph, { marginTop: '5pt' }]}
									wrap={false}
								>
									Alle betrokke partye stem hiermee saam dat hulle hulself
									vergewis het van die gebruiksinstruksies (insluitend
									gewasregistrasie, plaagregistrasie en geregistreerde
									dosishoeveelhede), waarskuwings en onthoudingsperiodes soos
									dit met bogenoemde aanbevole produketikette verband hou.
								</Text>
							</PdfStack>
						</PdfRow>
					</View>
				</PdfContent>
			</Page>
		</Document>
	);
};

const CautionRow = () => {
	return (
		<PdfRow style={[{ width: 'auto' }]}>
			<ImageTickbox src={HazmatPng} source={'Hazmat'} />
			<ImageTickbox src={BootsPng} source={'Boots'} />
			<ImageTickbox src={GlovesPng} source={'Gloves'} />
			<ImageTickbox src={FaceShieldPng} source={'FaceShield'} />
			<ImageTickbox src={FaceMaskPng} source={'FaceMask'} />
			<ImageTickbox
				dimensions="25pt"
				src={FullFaceMaskPng}
				source={'FullFaceMask'}
			/>
		</PdfRow>
	);
};
