import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store/store';

// Use throughout app instead of plain `useDispatch` and `useSelector`

// call useDispatch to dispatch actions to the Redux store from a component
export const useAppDispatch = () => useDispatch<AppDispatch>();

// call useSelector to let your React components read data from the Redux store
// In some instances this hook does not work due to possible circular dependency,
// in that case use store.getState() instead
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
