import { baseApi } from './baseApi';
import { ApiResponse } from './types/apiResponse.types';
import { PurchaseOrderRequest } from './types/purchaseOrderRequest.types';
import { OrderRequestParams, QueryParams } from './types/queryParams.types';
import { QuoteRequest } from './types/quoteRequest.types';

export const myAccountRequestApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		createQuoteRequest: builder.mutation<
			ApiResponse<QuoteRequest>,
			Partial<QuoteRequest>
		>({
			query(body) {
				return {
					url: 'QuoteRequest',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['QuoteRequests'],
		}),

		createPurchaseOrderRequest: builder.mutation<
			ApiResponse<PurchaseOrderRequest>,
			Partial<PurchaseOrderRequest>
		>({
			query(body) {
				return {
					url: 'PurchaseOrderRequest',
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['PurchaseOrderRequests'],
		}),

		getPurchaseOrderRequest: builder.query<
			ApiResponse<PurchaseOrderRequest>,
			string
		>({
			query: (id) => `PurchaseOrderRequest/${id}`,
		}),

		getQuoteRequest: builder.query<
			ApiResponse<QuoteRequest>,
			OrderRequestParams
		>({
			query: (params) => {
				return {
					url: `QuoteRequest/${params.id}`,
					params: params,
				};
			},
		}),

		getPurchaseOrderRequests: builder.query<
			ApiResponse<PurchaseOrderRequest[]>,
			QueryParams
		>({
			query: (args) => {
				return {
					url: 'PurchaseOrderRequest',
					params: args,
				};
			},
			providesTags: ['PurchaseOrderRequests'],
		}),

		getQuoteRequests: builder.query<ApiResponse<QuoteRequest[]>, QueryParams>({
			query: (args) => {
				return {
					url: 'QuoteRequest',
					params: args,
				};
			},
			providesTags: ['QuoteRequests'],
		}),

		deletePurchaseOrderRequest: builder.mutation<
			ApiResponse<PurchaseOrderRequest>,
			Partial<PurchaseOrderRequest>
		>({
			query(body) {
				return {
					url: `PurchaseOrderRequest/${body.purchaseOrderRequestId}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['PurchaseOrderRequests'],
		}),

		deleteQuoteRequest: builder.mutation<ApiResponse<QuoteRequest>, string>({
			query(body) {
				return {
					url: `QuoteRequest/${body}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['QuoteRequests'],
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreatePurchaseOrderRequestMutation,
	useCreateQuoteRequestMutation,
	useGetPurchaseOrderRequestsQuery,
	useGetQuoteRequestsQuery,
	useDeletePurchaseOrderRequestMutation,
	useDeleteQuoteRequestMutation,
	useGetPurchaseOrderRequestQuery,
	useGetQuoteRequestQuery,
} = myAccountRequestApi;
