import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	createApi,
	fetchBaseQuery,
	retry,
} from '@reduxjs/toolkit/query/react';
import { ApiStatus } from 'src/services/rtkQuery/types/apiStatus.types';
import { User } from './types/user.types';
import { ApiResponse } from './types/apiResponse.types';
import store from 'src/store/store';
import { toast } from 'react-toastify';
import { Mutex } from 'async-mutex';
import { logout, refreshToken } from 'src/features/authentication';

const mutex = new Mutex();

function globalHeaders(headers: any): void {
	const user = store.getState().user;

	if (user.accessToken) {
		headers.set('Authorization', `Bearer ${user.accessToken}`);
	}

	if (user.tenant) {
		headers.set('x-bc-user-id', user.tenant?.code);
	}

	return headers;
}

const baseQuery = fetchBaseQuery({
	baseUrl:
		process.env.REACT_APP_BASE_API_URL !== ''
			? process.env.REACT_APP_BASE_API_URL
			: 'https://cep-api-dev.azurewebsites.net/api/1.0/',
	prepareHeaders: globalHeaders,
});

const staggeredBaseQuery = retry(baseQuery, {
	maxRetries: 2,
});

const baseQueryWithTokenExpirationCheck: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	// Wait until the mutex is available without locking it.
	await mutex.waitForUnlock();

	let result = await staggeredBaseQuery(args, api, extraOptions);

	if (result.error && result.error.status === 401) {
		// Checking whether the mutex is locked.
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				// Get a new access token.
				const refreshResult = await refreshToken();

				if (
					refreshResult &&
					refreshResult.accessToken &&
					refreshResult.refreshToken
				) {
					// Retry the initial query.
					result = await staggeredBaseQuery(args, api, extraOptions);
				} else {
					throw new Error('Refresh Token Error');
				}
			} catch {
				toast.dismiss();
				toast.error('Session expired. Please log in again.');
				setTimeout(() => {
					logout();
				}, 2000);

				// We can bail out of retries if we know it is going to be redundant - not authenticated at all.
				retry.fail(result.error);
			} finally {
				// Release must be called once the mutex should be released again.
				release();
			}
		} else {
			// Wait until the mutex is available without locking it.
			await mutex.waitForUnlock();
			result = await staggeredBaseQuery(args, api, extraOptions);
		}
	}
	if (result.error && result.error.status === 401) {
		//The result is still 401 after a retry - logging out
		logout();
	}

	return result;
};

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: baseQueryWithTokenExpirationCheck,
	tagTypes: [
		'User',
		'Users',
		'UserCrops',
		'SprayCalculations',
		'SprayCalculation',
		'PurchaseOrderRequests',
		'QuoteRequests',
		'Questions',
		'TechnicalData',
		'Recommendations',
		'Recommendation',
		'ApplicationInstructions',
		'RecommendationQuote',
	],
	refetchOnMountOrArgChange: 60,

	endpoints: (builder) => ({
		getApiStatus: builder.query<ApiResponse<ApiStatus>, void>({
			query: () => 'SystemStatus',
		}),

		getBcOnlineStatus: builder.query<boolean, null>({
			query: () => ({
				url: 'SystemStatus/GetBcStatus',
				cache: 'no-cache',
			}),
			transformResponse: (response) => {
				if (response !== 200) {
					console.error('Business Central Service is offline.');
					toast.error(
						'4U is currently unavailable. If the issue persists, please contact your system administrator.'
					);
				}
				return response === 200;
			},
		}),

		getCurrentUser: builder.query<ApiResponse<User>, string>({
			query(token) {
				return {
					url: 'User/CurrentUser',
					method: 'Get',
					headers: { Authorization: `Bearer ${token}` },
					cache: 'no-cache',
				};
			},
		}),

		verifyToken: builder.query<string, string>({
			query(token) {
				return {
					url: `User/VerifyToken/${token}`,
					cache: 'no-cache',
				};
			},
			transformResponse: (response: { data: string }) => response.data,
		}),

		getPowerBiEmbededToken: builder.query<ApiResponse<string>, void>({
			query() {
				return {
					url: 'Overview/PowerBiToken',
				};
			},
		}),
	}),
});

export const {
	useGetApiStatusQuery,
	useGetCurrentUserQuery,
	useGetBcOnlineStatusQuery,
	useVerifyTokenQuery,
	useGetPowerBiEmbededTokenQuery,
} = baseApi;
