import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Divider,
	Stack,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { Icon, IconProps } from 'features';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface ScreenLayoutProps {
	title: string;
	icon: IconProps['icon'];
	children: JSX.Element | ReactNode;
	screenActionsEnd?: JSX.Element;
	screenActionsStart?: JSX.Element;
	titleChip?: JSX.Element;
}

export const ScreenLayout = ({
	title,
	icon,
	children,
	screenActionsEnd,
	screenActionsStart,
	titleChip,
}: ScreenLayoutProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
				padding: '0px 44px',
				[theme.breakpoints.down('md')]: {
					px: 2,
				},
			}}
		>
			<Box
				sx={ isMobile ? {
					display: 'flex',
					flexDirection: 'column',
					...(screenActionsEnd && { mb: 2})
				}
				: {
					display: 'flex',
					flexDirection: 'row',
					flex: 1,
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={isMobile ? {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start'
					}
					: {
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						alignItems: 'center',
					}}
				>
					{screenActionsStart}
					<Stack direction={isMobile ? 'column' : 'row'} gap={1}>
						<CardHeader
							titleTypographyProps={{
								variant: 'h5Bold',
							}}
							title={title}
							avatar={<Icon icon={icon} />}
						/>
						{isMobile ? null : titleChip}
					</Stack>
				</Box>
				{screenActionsEnd}
			</Box>

			{children || <Outlet />}
		</Box>
	);
};

interface StyledCardProps {
	children: JSX.Element | ReactNode;
	title?: string;
	icon?: IconProps['icon'];
	cardActions?: JSX.Element;
	padding?: string | number;
}

export const StyledCard = ({
	children,
	title,
	icon,
	cardActions,
	padding,
}: StyledCardProps) => {
	
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	return (
		<Card
			sx={{
				boxSizing: 'border-box',
				display: 'flex',
				flexDirection: 'column',
				padding: padding ?? isMobile ? '6%' : '4%',
				justifyContent: 'center',
			}}
		>
			{title && (
				<>
					<CardHeader
						titleTypographyProps={{
							variant: 'h6Bold',
						}}
						title={title}
						avatar={icon ? <Icon icon={icon} /> : null}
						action={cardActions}
					/>
					<Divider />
				</>
			)}
			<CardContent
				sx={{
					px: 0,
				}}
			>
				{children || <Outlet />}
			</CardContent>
		</Card>
	);
};
