import { SprayCalculationProduct } from 'src/services/rtkQuery/types/sprayCalculation.types';

export interface IBoomSprayCalcData {
	id?: string;
	farmBlockId?: string;
	title?: string;
	tractorSpeed?: number;
	tankSize?: number;
	nozzleSpacing?: number;
	sprayVolume?: number;
	deliveryPerUnitRequired?: number;
	nozzleAmount?: number;
	products?: SprayCalculationProduct[];
}

export const updateBoomSprayCalc = (
	data: Partial<IBoomSprayCalcData>
): IBoomSprayCalcData => {
	const deliveryPerMinReq =
		((data?.nozzleSpacing ?? 0) *
			(data?.sprayVolume ?? 0) *
			(data?.tractorSpeed ?? 0)) /
		600;

	return { deliveryPerUnitRequired: deliveryPerMinReq };
};

export const boomFormValues: IBoomSprayCalcData = {
	title: '',
	tractorSpeed: 0,
	nozzleSpacing: 0,
	sprayVolume: 0,
	deliveryPerUnitRequired: 0,
	nozzleAmount: 10,
	products: [],
};
