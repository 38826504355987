import { Box, Chip, Grid, Stack, styled } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {
	ScreenLayout,
	StyledCard,
} from 'src/components/templates/ScreenLayout/ScreenLayout';
import {
	SprayCalculationTypes,
	getSprayTypeString,
} from 'src/helpers/typeHelpers';
import {
	IBoomSprayCalcData,
	updateBoomSprayCalc,
} from './helpers/sprayCalculation';
import { SprayCalculatorScreenActions } from 'src/components/Pages/MyFarm/SprayCalculationsDetail';
import BoomArableSpraySvg from '../BoomArableSpraySvg/BoomArableSpraySvg';
import { CustomTextInput, CustomFormError } from 'features';
import { SprayCalculationProducts } from '../SprayCalculationProducts';
import { useEffect } from 'react';

const CustomChip = styled(Chip)(
	({ theme }) => `
    font-size: 14px;
    background-color: ${theme.colors.secondary.main};
    margin: auto;
`
);

type BoomArableSprayFormProps = {
	formSubmitting: boolean;
	blockSize: number;
};

const BoomArableSprayForm = ({
	formSubmitting,
	blockSize,
}: BoomArableSprayFormProps) => {
	const { values, errors, touched, setValues } =
		useFormikContext<IBoomSprayCalcData>();

	useEffect(() => {
		const calculatedValues = updateBoomSprayCalc(values);
		setValues({ ...values, ...calculatedValues });
	}, [values.nozzleSpacing, values.sprayVolume, values.tractorSpeed]);

	return (
		<>
			<ScreenLayout
				title={`Spray Calculations${values.title ? ` - ${values.title}` : ''}`}
				icon="UilRaindrops"
				screenActionsEnd={
					<SprayCalculatorScreenActions loading={formSubmitting} />
				}
				titleChip={
					<CustomChip
						label={getSprayTypeString(SprayCalculationTypes.BOOMARABLE)}
						size="small"
					/>
				}
			>
				<Stack gap={2}>
					<StyledCard
						title="Boom/Arable Spray Details"
						icon="UilBorderAlt"
					>
						<Grid sx={{ mt: { xs: 0, md: 1 } }}container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12}>
								<Field
									name="title"
									as={CustomTextInput}
									label="Title"
									placeholder="Enter a title for the spray"
									fullwidth
									value={values.title}
								/>
								{touched.title && errors.title ? (
									<CustomFormError errorMessage={errors.title} />
								) : null}
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="sprayVolume"
									type="number"
									as={CustomTextInput}
									label="Desired Spray Volume (L/ha)"
									placeholder="Enter the desired spray volume"
									fullwidth
									value={values.sprayVolume}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tankSize"
									type="number"
									as={CustomTextInput}
									label="Tank size (L)"
									placeholder="Enter the tank size"
									fullwidth
									value={values.tankSize}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<CustomTextInput
									label="Hectare per Tank"
									disabled={true}
									value={(values.tankSize ?? 0) / (values.sprayVolume ?? 0)}
								/>
							</Grid>

							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="tractorSpeed"
									type="number"
									as={CustomTextInput}
									label="Tractor Speed (km/h)"
									placeholder="Enter the tractor speed (km/H)"
									fullwidth
									value={values.tractorSpeed}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="nozzleSpacing"
									type="number"
									as={CustomTextInput}
									label="Boom Nozzle Spacing (m)"
									placeholder="Enter the boom nozzle spacing (m)"
									fullwidth
									value={values.nozzleSpacing}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="nozzleAmount"
									type="number"
									InputProps={{
										step: '1',
										notched: false
									}}
									as={CustomTextInput}
									label="Number of Nozzles"
									placeholder="Enter the number of nozzles"
									fullwidth
									value={values.nozzleAmount}
								/>
								{touched.nozzleAmount && errors.nozzleAmount ? (
									<CustomFormError errorMessage={errors.nozzleAmount} />
								) : null}
							</Grid>
						</Grid>
					</StyledCard>

					<SprayCalculationProducts blockSize={blockSize} />

					<StyledCard 
					title="Delivery" 
					icon="UilRaindrops" 
					>
						<Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
							<Grid item xs={12}>
								<BoomArableSpraySvg nozzleAmount={values.nozzleAmount ?? 0} />
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Field
									name="deliveryPerUnitRequired"
									component={CustomTextInput}
									label="Delivery (L/min per nozzle)"
									placeholder="N/A"
									value={values.deliveryPerUnitRequired}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</StyledCard>
				</Stack>
			</ScreenLayout>
		</>
	);
};

export default BoomArableSprayForm;
