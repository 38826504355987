import { UilExclamationCircle } from '@iconscout/react-unicons';
import { Box, FormHelperText, useTheme } from '@mui/material';

export const CustomFormError = ({ errorMessage }: { errorMessage: string }) => {
	const theme = useTheme();
	return (
		<FormHelperText>
			<Box
				alignItems={'center'}
				display={'flex'}
				gap={1}
				color={theme.colors.error.dark}
			>
				<UilExclamationCircle />
				{errorMessage}
			</Box>
		</FormHelperText>
	);
};
