import * as Sentry from '@sentry/react';
import React from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Sentry configuration object for error tracking and monitoring.
 */
export const sentryConfig = {
	dsn: process.env.REACT_APP_SENTRY_DSN,

	// Redux state context depth for normalization
	normalizeDepth: 5,

	// Integrations to enhance error tracking and monitoring
	integrations: [
		// Replay integration for error replay
		Sentry.replayIntegration(),
		// React Router v6 routing instrumentation
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],

	// URLs for which distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		process.env.REACT_APP_APP_BASE_URL ?? '',
	],

	// Environment label for your application
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,

	// Performance Monitoring
	tracesSampleRate:
		process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' ? 0.5 : 1.0, // Capture % of the transactions

	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

//RELEASES
// # Install the cli
// curl -sL https://sentry.io/get-cli/ | SENTRY_CLI_VERSION="2.2.0" bash

// # Setup configuration values
// SENTRY_AUTH_TOKEN=<>
// SENTRY_ORG=<>
// SENTRY_PROJECT=<>
// VERSION=`sentry-cli releases propose-version`

// # Workflow to create releases
// sentry-cli releases new "$VERSION"
// sentry-cli releases set-commits "$VERSION" --auto
// sentry-cli releases finalize "$VERSION"
