import {
	Button,
	CustomFormError,
	CustomTextInput,
	DataGrid,
	Icon,
	LoadingButton,
	VirtualizedAutoComplete,

} from 'features';
import { Field, Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { StyledCard } from 'src/components/templates/ScreenLayout/ScreenLayout';
import { SprayCalculationProduct } from 'src/services/rtkQuery/types/sprayCalculation.types';
import { desktopHeaderConfig, mobileHeaderConfig } from './helpers/dataGridHeaderConfig';
import { useGetAllProductsQuery } from 'src/services/rtkQuery/myProductsApi';
import { Product } from 'src/services/rtkQuery/types/product.types';
import { GridCellParams, GridRowParams } from '@mui/x-data-grid';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormLabel, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import * as Yup from 'yup';

export const SprayCalculationProducts = ({
	blockSize,
}: {
	blockSize: number;
}) => {
	const { values: sprayCalcValues, setFieldValue } = useFormikContext<{
		products?: SprayCalculationProduct[];
		tankSize?: number;
		sprayVolume?: number;
	}>();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	const { data, isLoading, isFetching } = useGetAllProductsQuery({});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = () => {
		setIsModalOpen(false);
	}

	const openModal = () => {
		setIsModalOpen(true);
	}

	const initialValues: FormikValues = {
		product: null,
		productName: '',
		dosage: null,
	}

	const validationSchema = Yup.object().shape({
        product: Yup.string().required('Please select a Product'),
        dosage: Yup.number().required('Please enter dosage'),
    });

	const onSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<FormikValues>) => {
		setFieldValue('products', [
			...(sprayCalcValues.products ?? []),
			{ 
				index: (sprayCalcValues.products?.length ?? 0) + 1,
				productCode: values?.product,
				productName: values?.productName,
				dosage: values?.dosage
			},
		]);

        setSubmitting(false);
        closeModal();
    }

	const BoldFormLabel = styled(FormLabel)(({ theme }) => ({
		fontWeight: 'bold',
		color: theme.colors.alpha.black[100],
		paddingBottom: 5.5,
	}));

	const renderProductDropDown = (params: GridCellParams) => {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<VirtualizedAutoComplete
					value={data?.data?.find((x) => x.no === params.row.productCode)}
					disabled={isLoading || isFetching}
					options={data?.data ?? []}
					getOptionLabel={(option: Product) => option.description}
					onChange={(_, product) =>
						setFieldValue(`products[${params.id}].productCode`, product?.no)
					}
					onClick={() => { }}
					size="small"
				/>
			</Box>
		);
	};

	const renderDosageInput = (params: GridCellParams) => {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<CustomTextInput
					value={params.row.dosage}
					type="number"
					sx={{ py: 1 }}
					size="small"
					inputProps={{ step: '0.1' }}
					disabled={isLoading || isFetching}
					onChange={(
						e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
					) =>
						setFieldValue(
							`products[${params.id}].dosage`,
							e.currentTarget.value
						)
					}
				/>
			</Box>
		);
	};

	const addProduct = () => {
		setFieldValue('products', [
			...(sprayCalcValues.products ?? []),
			{ 
				index: (sprayCalcValues.products?.length ?? 0) + 1 

			},
		]);
	}

	const onDeleteClick = (params: GridRowParams) => {
		const products = sprayCalcValues.products;
		products?.splice(Number(params.id), 1);

		setFieldValue('products', products);
	};

	const calculateLiterPerTank = (params: GridCellParams) => {
		const value =
			params.row.dosage * ((sprayCalcValues.sprayVolume ?? 0) / (sprayCalcValues.tankSize ?? 0));
		return isNaN(value) ? 0 : value;
	};

	const calculateLitersNeeded = (params: GridCellParams) => {
		const value = params.row.dosage * blockSize;
		return isNaN(value) ? 0 : value;
	};

	const desktopDataGridColumns = desktopHeaderConfig(
		renderProductDropDown,
		renderDosageInput,
		onDeleteClick,
		calculateLiterPerTank,
		calculateLitersNeeded
	);

	const mobileDataGridColumns = mobileHeaderConfig(calculateLiterPerTank, calculateLitersNeeded, onDeleteClick);

	return (
		<>
			<StyledCard
				title="Calculation"
				icon="UilRaindrops"
				cardActions={
					<Button variant="contained" size="small" onClick={openModal}>
						Add Product
					</Button>
				}
			>
				<DataGrid
					columns={isMobile ? mobileDataGridColumns : desktopDataGridColumns}
					ignoreMobile
					rows={
						sprayCalcValues.products?.map((x, i) => {
							return { ...x, index: i };
						}) ?? []
					}
					getRowId={(params) => params.index}
				/>
			</StyledCard>

			<Dialog fullWidth maxWidth={'md'} open={isModalOpen}>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{
						({ values, setFieldValue, handleSubmit, isSubmitting, touched, errors }) => {
							console.log('errors', errors)
							return (<Box sx={{ padding: 2 }}>
								<Form onSubmit={handleSubmit}>
									<DialogTitle>
										<Typography
											variant="h5Bold"
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											Add Product
										</Typography>
									</DialogTitle>
									<Divider />
									<DialogContent>
										<Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'}>
											<Grid item xs={12} md={6}>
												<BoldFormLabel id="product">
													Product *
												</BoldFormLabel>
												<Field
													name='product'
													as={VirtualizedAutoComplete}
													placeholder='Select product'
													select={true}
													value={data?.data?.find((x) => x.no === values.product)}
													disabled={isLoading && isFetching}
													options={data?.data ?? []}
													getOptionLabel={(option: Product) => option.description}
													onChange={(event: any, product: Product ) => {
														setFieldValue('product', product?.no)
														setFieldValue('productName', product?.description)
													}}
													onClick={() => { }}
													error={errors.product}
													helperText={errors.product}
												/>
												{
													errors.product && (
														// @ts-ignore
														<CustomFormError errorMessage={errors.product} />
													)}
											</Grid>
											<Divider />
											<Grid item xs={12} md={6}>
												<Field
													name='dosage'
													as={CustomTextInput}
													label='Dosage *'
													placeholder='Enter dosage'
													type='number'
													inputProps={{ step: '0.1' }}
													error={errors.dosage}
													helperText={errors.dosage}
												/>
											</Grid>
										</Grid>
									</DialogContent>
									<DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
										<LoadingButton loading={isSubmitting} disabled={isSubmitting} variant='contained' type='submit' sx={{ width: '183px' }}> 
											Add
										</LoadingButton>
										<Button onClick={closeModal} variant='outlined' color='secondary' sx={{ width: '183px' }}>
											Cancel
										</Button>
									</DialogActions>
								</Form>
							</Box>)
						}
					}
				</Formik>
			</Dialog>
		</>
	);
};
