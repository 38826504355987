import { CommonProps } from '@mui/material/OverridableComponent';
import { SxProps, Theme } from '@mui/material';

type StyleProps = CommonProps['style'] | SxProps<any>

const textFieldStyles: CommonProps['style']  = {
    width: '100%',
    alignSelf: 'center',
};

const cardStyles: StyleProps = {
    p: 0,
    margin: 5,
    alignItems: 'center',
    borderRadius: 1,
    maxWidth: 550,
    minHeight: 850, 
    float: 'right'
};

export const globalStyles = {
    textField: {
        ...textFieldStyles,
    },
    card: {
        ...cardStyles
    }
};