import React from 'react';
import { Box, useTheme } from '@mui/material';

interface ILogoInterface {
  height?: number | string;
  width?: number | string;
  variant?: 'color' | 'white';
}

// allow for variants of different lofo svg files

export const LogoSign = (props: ILogoInterface) => {
  const { height = 'auto', width = 'auto', variant = 'color' } = props;

  const theme = useTheme();

  return (
    <Box>
      <img
        src={
          theme.palette.mode === 'dark'
            ? variant === 'color'
              ? '/static/images/brand/IG_logo_colour.svg'
              : '/static/images/brand/logo_white.svg'
            : variant === 'color'
            ? '/static/images/brand/IG_logo_colour.svg'
            : '/static/images/brand/logo_white.svg'
        }
        alt="Logo"
        height={height}
        width={width}
      />
    </Box>
  );
}

